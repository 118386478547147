import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, Link, useParams, Prompt } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { composeRoute } from '../../routing/routing';
import { FormProps } from '../../utils/formProps';
import { api } from '../../api';
import { useYupResolver } from '../../utils/useYupResolver';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../api/useDefaultApiHandler';
import {
  companyFormSchema,
  CompanyFormData,
  CompanyData,
  toCompanyBody,
  toCompanyFormData,
} from './company.misc';

const {
  createCompany,
  readCompany,
  updateCompany,
  readCompanyInUse,
  deleteCompany,
} = api;

const Form = ({
  defaultValues,
  onSubmit,
  onSuccess,
}: FormProps<CompanyFormData>) => {
  const {
    register,
    handleSubmit: submit,
    formState,
    reset,
    getValues,
  } = useForm<CompanyFormData>({
    defaultValues,
    resolver: useYupResolver(companyFormSchema),
  });

  const { isDirty, errors } = formState;

  const showSuccessNotification = useDefaultSuccessHandler(
    'Данные организации успешно сохранены',
  );

  const { mutate, isLoading } = useMutation(
    (data: CompanyFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: (response) => {
        reset(getValues());
        showSuccessNotification();

        onSuccess && onSuccess(response);
      },
      onError: useDefaultHandler(),
    },
  );

  const handleSubmit = (data: CompanyFormData) => {
    mutate(data);
  };

  return (
    <>
      <Prompt
        when={isDirty}
        message="В форме есть несохраненные изменения, вы точно хотите уйти?"
      />
      <div className="box">
        <form onSubmit={submit(handleSubmit)}>
          <div className="field">
            <label className="label">Наименование</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder='ООО "Монолит"'
                {...register('name')}
              />
            </div>
            {!!errors.name && (
              <p className="help is-danger">{errors.name.message}</p>
            )}
          </div>

          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">ИНН</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="8739440012"
                    {...register('tin')}
                  />
                </div>
                {!!errors.tin && (
                  <p className="help is-danger">{errors.tin.message}</p>
                )}
              </div>
            </div>

            <div className="column">
              <div className="field">
                <label className="label">ОГРН</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="8392048501233"
                    {...register('psrn')}
                  />
                </div>
                {!!errors.psrn && (
                  <p className="help is-danger">{errors.psrn.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">Юридический адрес</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="г. Санкт-Петербург ул. Ломоносова 1"
                    {...register('address')}
                  />
                </div>
                {!!errors.address && (
                  <p className="help is-danger">{errors.address.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">Телефон</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="+7 (999) 123-23-23"
                    {...register('phone')}
                  />
                </div>
                {!!errors.phone && (
                  <p className="help is-danger">{errors.phone.message}</p>
                )}
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label">Факс</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="+7 (999) 123-23-23"
                    {...register('fax')}
                  />
                </div>
                {!!errors.fax && (
                  <p className="help is-danger">{errors.fax.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">СРО</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Ассоциация «СРОC ОЛ»"
                    {...register('sro')}
                  />
                </div>
                {!!errors.sro && (
                  <p className="help is-danger">{errors.sro.message}</p>
                )}
              </div>
            </div>

            <div className="column">
              <div className="field">
                <label className="label">ИНН СРО</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="8739342212"
                    {...register('sroTin')}
                  />
                </div>
                {!!errors.sroTin && (
                  <p className="help is-danger">{errors.sroTin.message}</p>
                )}
              </div>
            </div>

            <div className="column">
              <div className="field">
                <label className="label">ОГРН СРО</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="8392048501233"
                    {...register('sroPsrn')}
                  />
                </div>
                {!!errors.sroPsrn && (
                  <p className="help is-danger">{errors.sroPsrn.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button
                type="submit"
                disabled={isLoading}
                className="button is-link"
              >
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const DeleteButton = ({ id }: { id: number }) => {
  const history = useHistory();
  const { data: inUse } = useQuery([readCompanyInUse.id, id], () => {
    return readCompanyInUse.request(Number(id));
  });
  const showSuccessNotification = useDefaultSuccessHandler(
    'Организация успешно удалена',
  );
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const { mutate: suicide, isLoading: isDeleting } = useMutation(
    () => {
      return deleteCompany.request(String(id));
    },
    {
      onSuccess: () => {
        showSuccessNotification();
        history.push(composeRoute('/companies'));
      },
    },
  );
  const isInUse = inUse && inUse.buildings.length > 0;

  return (
    <>
      {isClicked && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setIsClicked(false)}>
            <button
              className="modal-close is-large"
              aria-label="close"
            ></button>
          </div>
          <div className="modal-content">
            <div className="box">
              {isInUse ? (
                <div className="content">
                  <p>
                    Удалить организацию невозможно, поскольку она используется в
                    следующих объектах и разделах:
                  </p>
                  <ul>
                    {inUse.buildings.map((building) => (
                      <li>
                        <Link
                          to={composeRoute('/buildings/:buildingId', {
                            buildingId: building.id,
                          })}
                          target="_blank"
                        >
                          {building.name}
                        </Link>
                        {building.pieces.length > 0 && (
                          <ul>
                            {building.pieces.map((piece) => {
                              return (
                                <li>
                                  <Link
                                    to={composeRoute(
                                      '/buildings/:buildingId/pieces/:pieceId',
                                      {
                                        pieceId: piece.id,
                                        buildingId: building.id,
                                      },
                                    )}
                                    target="_blank"
                                  >
                                    {piece.name}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="content">
                  <p>Вы точно хотите удалить организацию?</p>
                  <div className="is-clearfix">
                    <div className="buttons is-pulled-right">
                      <button
                        type="button"
                        className="button is-outlined"
                        onClick={() => setIsClicked(false)}
                      >
                        Отмена
                      </button>
                      <button
                        className="button is-danger is-outlined"
                        disabled={isDeleting}
                        onClick={() => suicide()}
                        type="submit"
                      >
                        Удалить
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <button
        type="button"
        onClick={() => setIsClicked(true)}
        className="button is-danger is-outlined"
      >
        Удалить организацию
      </button>
    </>
  );
};

export const SingleCompanyPage = () => {
  const history = useHistory();
  const { companyId } = useParams<{ companyId: string }>();
  const isNew = companyId === '-1';
  const { data } = useQuery([readCompany.id, companyId], () => {
    return isNew
      ? Promise.resolve<CompanyData>({
          name: '',
          id: -1,
          address: null,
          fax: null,
          phone: null,
          psrn: null,
          sro: null,
          sroPsrn: null,
          sroTin: null,
          tin: null,
        })
      : readCompany.request(Number(companyId));
  });
  const { name } = data ?? { name: '' };

  const request = (data: CompanyFormData) => {
    const body = toCompanyBody(data) as ExludeNullValues<CompanyData>;

    return isNew
      ? createCompany.request(body)
      : updateCompany.request(body, companyId);
  };

  const handleFormSuccess = isNew
    ? (response: { id: number }) => {
        const { id } = response;

        history.push({
          pathname: composeRoute('/companies/:companyId', {
            companyId: id,
          }),
        });
      }
    : undefined;

  return (
    <section className="section">
      <div className="container">
        <nav className="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to={composeRoute('/companies')}>Организации</Link>
            </li>
            <li className="is-active">
              <a href="#" aria-current="page">
                {name}
              </a>
            </li>
          </ul>
        </nav>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">
                {isNew ? 'Новая организация' : name || <span>&nbsp;</span>}
              </h1>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="buttons">
                {!isNew && <DeleteButton id={Number(companyId)} />}
              </div>
            </div>
          </div>
        </div>

        {data ? (
          <Form
            onSuccess={handleFormSuccess}
            defaultValues={toCompanyFormData(data)}
            onSubmit={request}
          />
        ) : null}
        <div className="section is-medium"></div>
      </div>
    </section>
  );
};
