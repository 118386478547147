// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I5mbBefENHbwoNwabeCs{height:200px;overflow:auto;display:block}.BMJN4IN2gx3OW3v2Yww1{list-style:none !important}.zV1iFQqz7dLD2yOFJT0Z{font-weight:bold;font-size:18px}.VrOfLpWWUCDr27TH4mrS{display:flex;align-items:center;height:2.5em}", "",{"version":3,"sources":["webpack://./src/pages/Work/WorkDocs/WorkDocs.sass"],"names":[],"mappings":"AAAA,sBAAM,YAAY,CAAC,aAAa,CAAC,aAAa,CAAC,sBAAM,0BAA0B,CAAC,sBAAU,gBAAgB,CAAC,cAAc,CAAC,sBAAiB,YAAY,CAAC,kBAAkB,CAAC,YAAY","sourcesContent":[".used{height:200px;overflow:auto;display:block}.list{list-style:none !important}.material{font-weight:bold;font-size:18px}.switchContainer{display:flex;align-items:center;height:2.5em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"used": "I5mbBefENHbwoNwabeCs",
	"list": "BMJN4IN2gx3OW3v2Yww1",
	"material": "zV1iFQqz7dLD2yOFJT0Z",
	"switchContainer": "VrOfLpWWUCDr27TH4mrS"
};
export default ___CSS_LOADER_EXPORT___;
