// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zoKXq2oG4ZgJS24wjN53:hover{background-color:#fafafa}", "",{"version":3,"sources":["webpack://./src/pages/Companies/CompaniesPage.sass"],"names":[],"mappings":"AAAA,4BAAe,wBAAwB","sourcesContent":[".company:hover{background-color:#fafafa}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"company": "zoKXq2oG4ZgJS24wjN53"
};
export default ___CSS_LOADER_EXPORT___;
