import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, Switch, useHistory, useParams } from 'react-router-dom';
import { api } from '../../api';
import { composeRoute, getRoute, Redirect, Route } from '../../routing/routing';
import { WorkLabs } from './WorkLabs/WorkLabs';
import { WorkSchemas } from './WorkSchemas/WorkSchemas';
import { BuildingBreadcrumb } from '../../components/BuildingBreadcrumb/BuildingBreadcrumb';
import { compact, mapValues } from 'lodash-es';
import { millenium } from '../../vars/millenium';
import {
  toWorkDTO,
  toWorkFormData,
  WorkBody,
  WorkData,
  WorkFormData,
} from './WorkForm/workForm.misc';
import { WorkForm } from './WorkForm/WorkForm';
import { NavTabs } from '../../components/NavTabs/NavTabs';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../api/useDefaultApiHandler';
import { useNewUniqueId } from '../../utils/useUniqueId';
import { WorkDocs } from './WorkDocs/WorkDocs';
import { Help } from '../../components/Help/Help';
import { useHelp } from '../../components/Help/useHelp';
import classNames from 'classnames';

const {
  readWork,
  updateWork,
  createWork,
  readBuildingPiece,
  readLabs,
  deleteWork,
} = api;

const DeleteButton = ({
  id,
  pieceId,
  buildingId,
}: {
  id: number;
  pieceId: number;
  buildingId: number;
}) => {
  const history = useHistory();
  const showSuccessNotification = useDefaultSuccessHandler(
    'Данные о работе успешно удалены',
  );
  const { mutate: suicide } = useMutation(
    () => {
      return deleteWork.request(id);
    },
    {
      onSuccess: () => {
        showSuccessNotification();
        history.push(
          composeRoute('/buildings/:buildingId/pieces/:pieceId', {
            buildingId,
            pieceId,
          }),
        );
      },
      onError: useDefaultHandler(),
    },
  );

  const handleClick = () => {
    const isConfirmed = window.confirm(
      'Удалить работу? Вместе с ним каскадно удалятся все принадлежащие ему лабораторные работы и исполнительные схемы.',
    );

    if (isConfirmed) {
      suicide();
    }
  };

  return (
    <button
      className="button is-danger is-outlined"
      type="button"
      onClick={handleClick}
    >
      Удалить работу
    </button>
  );
};

export const SingleWorkPage = () => {
  const queryClient = useQueryClient();
  const { pieceId, workId } = mapValues(
    useParams<{ pieceId: string; workId: string }>(),
    (value: string) => {
      return Number(value);
    },
  );
  const isNew = workId === -1;
  const newUniqueId = useNewUniqueId();
  const history = useHistory();
  const { data: piece } = useQuery([readBuildingPiece.id, pieceId], () => {
    return readBuildingPiece.request(pieceId);
  });
  const { data: work } = useQuery(
    [readWork.id, isNew ? newUniqueId : workId],
    () => {
      return isNew
        ? Promise.resolve<WorkData>({
            id: -1,
            buildingPieceId: pieceId,
            name: '',
            land: '',
            next: '',
            index: '',
            startDate: '',
            finishDate: '',
            docIds: [],
            standard: piece!.standard,
            projectDoc: piece!.cipher,
            reportDate: '',
            comment: '',
            tags: [],
          })
        : readWork.request(workId);
    },
    {
      enabled: piece !== undefined,
    },
  );
  const { data: labs } = useQuery([readLabs.id, Number(workId)], () => {
    return readLabs.request(workId);
  });

  const request = (data: WorkFormData) => {
    const payload: WorkBody = toWorkDTO(data);

    return isNew
      ? createWork.request(payload as ExludeNullValues<WorkBody>, pieceId)
      : updateWork.request(
          payload as ExludeNullValues<WorkBody>,
          Number(workId),
        );
  };

  const firstLabDate =
    labs?.reduce((last, lab) => {
      const date = new Date(lab.date);

      return last.valueOf() - date.valueOf() <= 0 ? last : date;
    }, new Date(millenium)) ?? new Date(millenium);

  const lastLabDate =
    labs?.reduce((last, lab) => {
      const date = new Date(lab.date);

      return last.valueOf() - date.valueOf() >= 0 ? last : date;
    }, new Date(-millenium)) ?? new Date(-millenium);

  const localRouteParams = { pieceId, workId };

  const handleFormSuccess = isNew
    ? (response: { id: number }) => {
        const { id } = response;

        history.push(
          composeRoute('/pieces/:pieceId/works/:workId', {
            pieceId: pieceId,
            workId: id,
          }),
        );
      }
    : () => {
        queryClient.invalidateQueries([readWork.id, workId]);
      };

  const tabs = compact([
    {
      content: 'Информация',
      path: getRoute('/pieces/:pieceId/works/:workId/information'),
      to: composeRoute(
        '/pieces/:pieceId/works/:workId/information',
        localRouteParams,
      ),
    },
    !isNew && {
      content: 'Исполнительные схемы',
      path: getRoute('/pieces/:pieceId/works/:workId/schemas'),
      to: composeRoute(
        '/pieces/:pieceId/works/:workId/schemas',
        localRouteParams,
      ),
    },
    !isNew && {
      content: 'Материалы',
      path: getRoute('/pieces/:pieceId/works/:workId/materials'),
      to: composeRoute(
        '/pieces/:pieceId/works/:workId/materials',
        localRouteParams,
      ),
    },
    !isNew && {
      content: 'Лабораторные работы',
      path: getRoute('/pieces/:pieceId/works/:workId/labs'),
      to: composeRoute('/pieces/:pieceId/works/:workId/labs', localRouteParams),
    },
  ]);

  const {
    isVisible: showHelp,
    toggle: toggleHelp,
    close: closeHelp,
  } = useHelp('work-info');

  return (
    <section className="section">
      <div className="container">
        <nav className="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>{piece ? <BuildingBreadcrumb id={piece.parentId} /> : null}</li>
            <li>
              {piece ? (
                <Link
                  to={composeRoute('/buildings/:buildingId/pieces/:pieceId', {
                    buildingId: piece.parentId,
                    pieceId: pieceId,
                  })}
                >
                  <i className="fas fa-building mr-2"></i> {piece.name}
                </Link>
              ) : null}
            </li>
            <li className="is-active">
              <a href="#" aria-current="page">
                <i className="fas fa-paint-roller mr-2"></i>
                {work?.name || 'Новая работа'}
              </a>
            </li>
          </ul>
        </nav>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">
                {isNew ? 'Новый объект' : work?.name || <span>&nbsp;</span>}
              </h1>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="buttons">
                {!isNew && !!piece && (
                  <DeleteButton
                    id={workId}
                    pieceId={piece.id}
                    buildingId={piece.parentId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <NavTabs tabs={tabs} />
        <Switch>
          <Redirect
            from="/pieces/:pieceId/works/:workId"
            to={composeRoute(
              '/pieces/:pieceId/works/:workId/information',
              localRouteParams,
            )}
            exact
          />

          <Route path="/pieces/:pieceId/works/:workId/information">
            {/* TODO: <WorkInfo /> */}
            <div className="level">
              <div className="level-left">
                <div className="level-item"></div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <div className="buttons">
                    <button
                      className={classNames(
                        showHelp ? 'has-text-info' : 'has-text-grey',
                        'button is-white',
                      )}
                      onClick={toggleHelp}
                    >
                      <i className="fas fa-question-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {showHelp && (
              <Help close={closeHelp}>
                Каждая работа соответствует акту освидетельствования скрытых
                работ с приложениями. Все поля, кроме поля "метки", используются
                в соответствующих местах в АОСР. "Метки" используются для
                группировки работ, например, по дате выполнения.
              </Help>
            )}
            {work && (
              <div className="box">
                <WorkForm
                  onSubmit={request}
                  defaultValues={toWorkFormData(work)}
                  firstLabDate={firstLabDate}
                  lastLabDate={lastLabDate}
                  onSuccess={handleFormSuccess}
                />
              </div>
            )}
          </Route>

          <Route path="/pieces/:pieceId/works/:workId/labs">
            <WorkLabs workId={Number(workId)} />
          </Route>

          <Route path="/pieces/:pieceId/works/:workId/schemas">
            <div className="box">
              <WorkSchemas workId={Number(workId)} />
            </div>
          </Route>

          <Route path="/pieces/:pieceId/works/:workId/materials">
            <WorkDocs id={Number(workId)} />
          </Route>
        </Switch>
      </div>
    </section>
  );
};
