import * as yup from 'yup';
import { ReadBuildingDTO, BuildingDTO } from '../../../api/_out';

export type BuildingData = ReadBuildingDTO;
export type BuildingBody = BuildingDTO;

export const buildingFormSchema = yup.object({
  name: yup.string().required('Наименование не может быть пустым'),
});

export type BuildingFormData = ExludeNullValues<
  yup.InferType<typeof buildingFormSchema>
>;

export const toBuildingFormData = (
  building: BuildingData,
): BuildingFormData => {
  const { name } = building;

  return {
    name,
  };
};

export const toBuildingDTO = (data: BuildingFormData): BuildingBody => {
  const { name } = data;

  return {
    name,
  };
};
