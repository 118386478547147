import { defaultsDeep } from 'lodash-es';
import mapValues from 'lodash-es/mapValues';
import { Configuration } from './_out';
import { DefaultApiFp, UserApiFp } from './_out/api';

// https://stackoverflow.com/a/24103596/5810100
const readCookie = (name: string) => {
  const nameEq = name + '=';
  const cookieArray = document.cookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let c = cookieArray[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEq) == 0) return c.substring(nameEq.length, c.length);
  }

  return '';
};

const createKeys = <T extends { [key: string]: (...args: any) => () => any }>(
  api: T,
): {
  [key in keyof T]: {
    id: string;
    request: (...args: Parameters<T[key]>) => ReturnType<ReturnType<T[key]>>;
  };
} => {
  return mapValues(api, (origin: any, key) => {
    return {
      id: key,
      request: (...args: any) => {
        const expects = origin.length as number;
        const got = args.length;
        const areOptionsPassed = expects === got;
        const authToken = localStorage.getItem('token');
        const defaultOptions = authToken
          ? {
              headers: {
                Authorization: 'Bearer ' + authToken,
              },
            }
          : {};
        const options = areOptionsPassed
          ? defaultsDeep({}, args[args.length - 1], defaultOptions)
          : defaultOptions;
        const finalArgs = areOptionsPassed
          ? [...args.slice(0, args.length - 1), options]
          : [...args, options];

        return origin(...finalArgs)(fetch, process.env.API);
      },
    };
  });
};

const configuration = new Configuration({
  apiKey: () => {
    return readCookie('uid');
  },
});

export const api = createKeys(UserApiFp(configuration));
export const defaultApi = createKeys(DefaultApiFp(configuration));
