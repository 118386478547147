// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yyYE0LtSRKe60l1RfHgq{position:fixed;right:1rem;bottom:1rem}.t9QAGHDlOgtyVcRhIPmc{max-width:400px}.t9QAGHDlOgtyVcRhIPmc+.t9QAGHDlOgtyVcRhIPmc{margin-top:1rem}", "",{"version":3,"sources":["webpack://./src/modules/Notifier/Notifier.sass"],"names":[],"mappings":"AAAA,sBAAW,cAAc,CAAC,UAAU,CAAC,WAAW,CAAC,sBAAS,eAAe,CAAC,4CAAkB,eAAe","sourcesContent":[".container{position:fixed;right:1rem;bottom:1rem}.message{max-width:400px}.message+.message{margin-top:1rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "yyYE0LtSRKe60l1RfHgq",
	"message": "t9QAGHDlOgtyVcRhIPmc"
};
export default ___CSS_LOADER_EXPORT___;
