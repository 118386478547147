import classNames from 'classnames';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PartnershipOrderGroupDTO } from '../../../../api/_out/api';
import { api } from '../../../../api';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../../api/useDefaultApiHandler';
import { OrderGroupPieces } from './OrderGroupPieces';
import { Order } from '../Order/Order';
import { NewOrder } from '../Order/NewOrder';
import styles from './OrderGroup.sass';

const {
  readPartnership,
  reattachPieceInOrderGroups,
  attachPieceToOrderGroup,
  deleteOrderGroup,
} = api;

type TransferData = {
  pieceId: number;
  orderGroupId: number | null;
};

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const shouldRenderPersonIdField = (partnershipAlias: string) => {
  return ['developer', 'controller'].some((alias) => {
    return partnershipAlias.match(alias);
  });
};

export const OrderGroup = ({ data }: { data: PartnershipOrderGroupDTO }) => {
  const queryClient = useQueryClient();
  const { color, id, label, pieces, orders, partnershipId } = data;
  const { r, g, b } = hexToRgb(color) ?? { r: 0, g: 0, b: 0 };

  const { data: partnership } = useQuery(
    [readPartnership.id, partnershipId],
    () => {
      return readPartnership.request(partnershipId);
    },
  );

  const { mutate } = useMutation(
    (transferData: TransferData) => {
      const { orderGroupId: sourceOrderGroupId, pieceId } = transferData;
      const { id: targetOrderGroupId } = data;

      if (sourceOrderGroupId)
        return reattachPieceInOrderGroups.request({
          prevOrderGroupId: sourceOrderGroupId,
          nextOrderGroupId: targetOrderGroupId,
          pieceId,
        });

      return attachPieceToOrderGroup.request({ pieceId }, targetOrderGroupId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([readPartnership.id, partnershipId]);
      },
    },
  );

  const handleDrop = (event: React.DragEvent) => {
    const transferData = event.dataTransfer.getData('text');
    const parsed = JSON.parse(transferData) as TransferData;

    mutate(parsed);
  };

  const allowDrop = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const background = `rgb(${r} ${g} ${b} / 40%)`;
  const shadow1 = `rgb(${r} ${g} ${b} / 40%)`;
  const shadow2 = `rgb(${r} ${g} ${b} / 10%)`;

  const boxShadow = `0 0.5em 1em -0.125em ${shadow1}, 0 0px 0 1px ${shadow2}`;

  const showSuicideSuccess = useDefaultSuccessHandler(
    'Группа приказов удалена',
  );

  const { mutate: suicide } = useMutation(
    () => {
      const confirmed = window.confirm('Удалить группу приказов?');

      if (confirmed) {
        return deleteOrderGroup.request(id);
      }

      return Promise.reject();
    },
    {
      onSuccess: () => {
        showSuicideSuccess();
        queryClient.invalidateQueries([readPartnership.id, partnershipId]);
      },
      onError: useDefaultHandler(),
    },
  );

  const isWithPersonId = !!(
    partnership && shouldRenderPersonIdField(partnership.alias)
  );

  return (
    <div
      className={classNames(
        'is-flex is-flex-direction-column box is-revealing',
        styles.container,
      )}
      onDrop={handleDrop}
      onDragOver={allowDrop}
      style={{
        backgroundColor: `${background}`,
        boxShadow,
      }}
    >
      {label.length > 0 && (
        <div className="title has-color-white is-5 mb-3">{label}</div>
      )}

      {pieces.length > 0 && (
        <div className="mb-3">
          <OrderGroupPieces pieces={pieces} orderGroupId={data.id} />
        </div>
      )}

      <div className={classNames(styles.orders, 'columns')}>
        <div className="column is-narrow">
          <NewOrder orderGroupId={data.id} hasPersonIdField={isWithPersonId} />
        </div>

        {orders.map((order) => (
          <div key={order.id} className="column is-narrow">
            <Order
              data={order}
              orderGroupId={data.id}
              hasPersonIdField={isWithPersonId}
            />
          </div>
        ))}
      </div>

      <div className="is-flex is-concealed mt-auto">
        <div className="ml-auto">
          <div className="buttons">
            <button
              className="button is-small is-danger is-outlined"
              onClick={() => suicide()}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
