import { useCallback } from 'react';
import { useSort } from './useSort';

const getStorageKeyForOrderKey = (uniqueKey: string) =>
  `default-sort-key-for_${uniqueKey}`;
const getStorageKeyForOrderValue = (uniqueKey: string) =>
  `default-sort-value-for_${uniqueKey}`;

const isValidValue = (value: any): value is 'ASC' | 'DESC' => {
  return ['ASC', 'DESC'].includes(value);
};

export const usePersistentSort = <T extends string>(uniqueKey: string) => {
  const storageKeyForOrderKey = getStorageKeyForOrderKey(uniqueKey);
  const storageKeyForOrderValue = getStorageKeyForOrderValue(uniqueKey);

  return useCallback(
    (defaultKey: T, defaultValue?: 'ASC' | 'DESC') => {
      const keyInStorage = localStorage.getItem(storageKeyForOrderKey);
      const valueInStorage = localStorage.getItem(storageKeyForOrderValue);
      const { order, key, switchSort } = useSort<T>(
        (keyInStorage ?? defaultKey) as T,
        isValidValue(valueInStorage) ? valueInStorage : defaultValue,
      );

      return {
        order,
        key,
        switchSort: (nextValue: T) => {
          const { nextKey, nextOrder } = switchSort(nextValue);

          localStorage.setItem(storageKeyForOrderKey, nextKey);
          localStorage.setItem(storageKeyForOrderValue, nextOrder);

          return {
            nextKey,
            nextOrder,
          };
        },
      };
    },
    [storageKeyForOrderKey, storageKeyForOrderValue],
  );
};
