import React from 'react';
import { compact } from 'lodash-es';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Switch, useHistory } from 'react-router-dom';
import { api } from '../../api';
import { BuildingBreadcrumb } from '../../components/BuildingBreadcrumb/BuildingBreadcrumb';
import { NavTabs } from '../../components/NavTabs/NavTabs';
import { composeRoute, getRoute, Redirect, Route } from '../../routing/routing';
import { useNumericParams } from '../../utils/useNumericParams';
import { PieceForm } from './PieceForm/PieceForm';
import {
  toPieceBody,
  toPieceFormData,
  PieceFormData,
  PieceBody,
} from './PieceForm/pieceForm.misc';
import { PieceWorks } from './PieceWorks/PieceWorks';
import { Stamps } from './PieceStamps/PieceStamps';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../api/useDefaultApiHandler';
import { Help } from '../../components/Help/Help';
import { useHelp } from '../../components/Help/useHelp';
import classNames from 'classnames';

const {
  readBuildingPiece,
  readBuilding,
  createBuildingPiece,
  updateBuildingPiece,
  deleteBuildingPiece,
} = api;

const DeleteButton = ({
  id,
  buildingId,
}: {
  id: number;
  buildingId: number;
}) => {
  const history = useHistory();
  const showSuccessNotification = useDefaultSuccessHandler(
    'Раздел успешно удален',
  );
  const { mutate: suicide } = useMutation(
    () => {
      return deleteBuildingPiece.request(id);
    },
    {
      onSuccess: () => {
        showSuccessNotification();
        history.push(
          composeRoute('/buildings/:buildingId', {
            buildingId,
          }),
        );
      },
      onError: useDefaultHandler(),
    },
  );

  const handleClick = () => {
    const isConfirmed = window.confirm(
      'Удалить раздел? Вместе с ним удалятся его работы и штампы.',
    );

    if (isConfirmed) {
      suicide();
    }
  };

  return (
    <button
      className="button is-danger is-outlined"
      type="button"
      onClick={handleClick}
    >
      Удалить раздел
    </button>
  );
};

export const SinglePiecePage = () => {
  const history = useHistory();
  const { buildingId, pieceId } = useNumericParams<{
    buildingId: string;
    pieceId: string;
  }>();
  const queryClient = useQueryClient();
  const isNew = pieceId === -1;
  const queryKey = isNew
    ? [readBuildingPiece.id, buildingId, -1]
    : [readBuildingPiece.id, pieceId];
  const query = isNew
    ? () => {
        return readBuilding.request(buildingId).then((building) => {
          return {
            ...building,
            name: '',
            cipher: '',
            comment: '',
            standard: '',
            signatoryName: '',
            signatoryPosition: '',
            stamps: [] as any[],
            parentId: Number(buildingId),
          };
        });
      }
    : () => {
        return readBuildingPiece.request(pieceId);
      };
  const { data } = useQuery(queryKey, query);
  const request = (data: PieceFormData) => {
    const payload: PieceBody = toPieceBody(data);

    return isNew
      ? createBuildingPiece.request(
          payload as ExludeNullValues<PieceBody>,
          buildingId,
        )
      : updateBuildingPiece.request(
          payload as ExludeNullValues<PieceBody>,
          Number(pieceId),
        );
  };
  const localRouteParams = { buildingId, pieceId };

  const handleFormSuccess = isNew
    ? (response: { id: number }) => {
        const { id } = response;

        history.push(
          composeRoute('/buildings/:buildingId/pieces/:pieceId/information', {
            buildingId,
            pieceId: id,
          }),
        );
      }
    : () => {
        queryClient.invalidateQueries([readBuildingPiece.id, pieceId]);
      };

  const tabs = compact([
    !isNew && {
      content: 'Работы',
      path: getRoute('/buildings/:buildingId/pieces/:pieceId/works'),
      to: composeRoute(
        '/buildings/:buildingId/pieces/:pieceId/works',
        localRouteParams,
      ),
    },
    {
      content: 'Информация',
      path: getRoute('/buildings/:buildingId/pieces/:pieceId/information'),
      to: composeRoute(
        '/buildings/:buildingId/pieces/:pieceId/information',
        localRouteParams,
      ),
    },
    !isNew && {
      content: 'Штампы',
      path: getRoute('/buildings/:buildingId/pieces/:pieceId/stamps'),
      to: composeRoute(
        '/buildings/:buildingId/pieces/:pieceId/stamps',
        localRouteParams,
      ),
    },
  ]);

  const {
    isVisible: showHelp,
    toggle: toggleHelp,
    close: closeHelp,
  } = useHelp('piece-info');

  return (
    <section className="section">
      <div className="container">
        <nav className="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>{data ? <BuildingBreadcrumb id={data.parentId} /> : ''}</li>
            <li className="is-active">
              <a href="#" aria-current="page">
                <i className="fas fa-building mr-2"></i>
                {isNew ? 'Новый раздел' : data?.name || <span>&nbsp;</span>}
              </a>
            </li>
          </ul>
        </nav>

        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">
                {isNew ? 'Новый раздел' : data?.name || <span>&nbsp;</span>}
              </h1>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="buttons">
                {!isNew && (
                  <DeleteButton id={pieceId} buildingId={buildingId} />
                )}
              </div>
            </div>
          </div>
        </div>

        <NavTabs tabs={tabs} />

        <Switch>
          <Redirect
            from="/buildings/:buildingId/pieces/:pieceId"
            to={
              isNew
                ? composeRoute(
                    '/buildings/:buildingId/pieces/:pieceId/information',
                    localRouteParams,
                  )
                : composeRoute(
                    '/buildings/:buildingId/pieces/:pieceId/works',
                    localRouteParams,
                  )
            }
            exact
          />

          <Route path="/buildings/:buildingId/pieces/:pieceId/works">
            <PieceWorks pieceId={pieceId} />
          </Route>

          <Route path="/buildings/:buildingId/pieces/:pieceId/stamps">
            <Stamps pieceId={pieceId} />
          </Route>

          <Route path="/buildings/:buildingId/pieces/:pieceId/information">
            <div className="level">
              <div className="level-left">
                <div className="level-item"></div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <div className="buttons">
                    <button
                      className={classNames(
                        showHelp ? 'has-text-info' : 'has-text-grey',
                        'button is-white',
                      )}
                      onClick={toggleHelp}
                    >
                      <i className="fas fa-question-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {showHelp && (
              <Help close={closeHelp}>
                При добавлении новых разделов по умолчанию используются
                выбранные в объекте организации. Проектная и нормативная
                документации, указанные в разделе, используются по умолчанию при
                добавлении работ.
              </Help>
            )}
            {data && (
              <div className="box">
                <PieceForm
                  onSubmit={request}
                  defaultValues={toPieceFormData(data)}
                  onSuccess={handleFormSuccess}
                />
              </div>
            )}
          </Route>
        </Switch>
      </div>
    </section>
  );
};
