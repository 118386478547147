import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { api } from '../../../api';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../api/useDefaultApiHandler';
import { composeRoute } from '../../../routing/routing';
import { FormProps } from '../../../utils/formProps';
import { StampContext, StampFormData, toStampDTO } from './StampForm.misc';

const {
  readPieceStamps: readStamps,
  createStamp,
  updateStamp,
  deleteStamp,
  readStampInUse,
} = api;

const DeleteButton = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();
  const { pieceId } = useContext(StampContext);
  const { data: inUse } = useQuery([readStampInUse.id, id], () => {
    return readStampInUse.request(Number(id));
  });
  const showSuccessNotification = useDefaultSuccessHandler(
    'Штамп успешно удален',
  );
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const { mutate: suicide, isLoading: isDeleting } = useMutation(
    () => {
      return deleteStamp.request(id);
    },
    {
      onSuccess: () => {
        showSuccessNotification();
        queryClient.invalidateQueries([readStamps.id, pieceId]);
      },
    },
  );
  const isInUse = inUse && inUse.works.length > 0;

  return (
    <>
      {isClicked && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setIsClicked(false)}>
            <button
              className="modal-close is-large"
              aria-label="close"
            ></button>
          </div>
          <div className="modal-content">
            <div className="box">
              {isInUse ? (
                <div className="content">
                  <p>
                    Удалить штамп невозможно, поскольку он используется в
                    следующих работах:
                  </p>
                  <ul>
                    {inUse.works.map((work) => (
                      <li>
                        <Link
                          to={composeRoute('/pieces/:pieceId/works/:workId', {
                            workId: work.id,
                            pieceId,
                          })}
                          target="_blank"
                        >
                          {work.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="content">
                  <p>Вы точно хотите удалить штамп?</p>
                  <div className="is-clearfix">
                    <div className="buttons is-pulled-right">
                      <button
                        type="button"
                        className="button is-outlined"
                        onClick={() => setIsClicked(false)}
                      >
                        Отмена
                      </button>
                      <button
                        className="button is-danger is-outlined"
                        disabled={isDeleting}
                        onClick={() => suicide()}
                        type="submit"
                      >
                        Удалить
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <button
        className="button is-outlined is-danger"
        onClick={() => setIsClicked(true)}
        type="button"
      >
        <i className="fa fa-trash"></i>
      </button>
    </>
  );
};

export const StampForm = ({
  defaultValues,
  onSuccess,
  id,
}: FormProps<StampFormData> & { id: number }) => {
  const isNew = id === -1;
  const { pieceId } = useContext(StampContext);
  const { register, handleSubmit, reset, control, watch, getValues } =
    useForm<StampFormData>({
      defaultValues,
    });
  const {
    fields: personFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'people',
  });

  const showSuccessNotification = useDefaultSuccessHandler(
    'Штамп успешно сохранен',
  );

  const { mutate } = useMutation(
    (data: StampFormData) => {
      const body = toStampDTO(data);

      return isNew
        ? createStamp.request(body, pieceId)
        : updateStamp.request(body, id);
    },
    {
      onSuccess: (response) => {
        reset(isNew ? defaultValues : getValues());
        showSuccessNotification();

        onSuccess && onSuccess(response);
      },
      onError: useDefaultHandler(),
    },
  );

  const submit = (data: StampFormData) => {
    mutate(data);
  };

  const people = watch('people');

  useEffect(() => {
    const filled = people?.reduce((all, value, index) => {
      const { name, position } = value;

      return name && position ? [...all, index] : all;
    }, [] as number[]);
    const empty =
      people?.slice(0, people.length - 1)?.reduce((all, value, index) => {
        const { name, position } = value;

        return !name && !position ? [...all, index] : all;
      }, [] as number[]) ?? [];
    const allAreFilled = filled?.length === people?.length;

    if (allAreFilled && (people?.length ?? Infinity) <= 5) {
      append({ name: '', position: '' }, { shouldFocus: false });
    }

    if (empty.length > 0) {
      remove(empty);
    }
  }, [JSON.stringify(people)]);

  return (
    <>
      <form
        className={'is-revealing'}
        onSubmit={handleSubmit((data) => submit(data))}
      >
        {personFields.map((person, index: number) => {
          return (
            <div className="columns is-1" key={person.id}>
              <div className="column is-half pr-1 pl-1 p-1">
                <div className="field">
                  <div className="control">
                    {index === 0 && (
                      <label className="label is-small">Должность</label>
                    )}
                    <input
                      className="input"
                      type="text"
                      placeholder="Начальник участка"
                      {...register(`people.${index}.position`)}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-half pr-1 pl-1 p-1">
                <div className="field">
                  {index === 0 && (
                    <label className="label is-small">Фамилия И. О.</label>
                  )}
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Садовой И. И."
                      {...register(`people.${index}.name`)}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="field">
          <div className="control is-clearfix">
            <div className="buttons is-pulled-right is-concealed">
              {!isNew && <DeleteButton id={id} />}
              <button type="submit" className="button is-outlined is-info">
                <i className="fa fa-check"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
