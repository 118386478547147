import {
  MaterialData,
  MaterialFormData,
  toMaterialDTO,
  toMaterialFormData,
} from './MaterialForm/materialForm.misc';
import { api } from '../../api';
import classNames from 'classnames';
import { MaterialForm } from './MaterialForm/MaterialForm';
import { DocForm, MaterialDocFormData } from './DocForm/DocForm';
import {
  DocData,
  toDocDTO,
  DocBody,
  toDocFormData,
} from './DocForm/docForm.misc';
import { useNumericParams } from '../../utils/useNumericParams';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { composeRoute } from '../../routing/routing';

const {
  createMaterial,
  updateMaterial,
  deleteDocPdf,
  uploadDocPdf,
  readMaterial,
  readMaterials,
  createMaterialDoc: createDoc,
  updateDoc,
} = api;

const updatePdf = (id: number, pdfFileList: FileList | null | undefined) => {
  if (pdfFileList === null) {
    return deleteDocPdf.request(id);
  }

  const pdf = pdfFileList?.item(0);

  if (!pdf) {
    return;
  }

  const formData = new FormData();

  formData.append('file', pdf as File);

  return uploadDocPdf.request(id, { body: formData }).then(
    (response) => {
      return response;
    },
    (reason) => {
      return reason.json().then((json: any) => {
        throw json;
      });
    },
  );
};

const Doc = ({
  data: doc,
  materialId,
}: {
  data: DocData;
  materialId: number;
}) => {
  const queryClient = useQueryClient();
  const { id } = doc;
  const isNew = id < 0;
  const handleSubmit = (formData: MaterialDocFormData) => {
    const body = toDocDTO(formData) as ExludeNullValues<DocBody>;

    return isNew
      ? createDoc.request(body, materialId).then((response) => {
          const { id } = response as { id: number };

          return updatePdf(id, formData.pdf);
        })
      : updateDoc.request(body, doc.id).then(() => {
          return updatePdf(doc.id, formData.pdf);
        });
  };

  const handleFormSuccess = () => {
    queryClient.invalidateQueries(readMaterials.id);
    queryClient.invalidateQueries([readMaterial.id, materialId]);
  };

  return (
    <div className={classNames('box', isNew && 'has-background-success-light')}>
      <DocForm
        id={doc.id}
        defaultValues={toDocFormData(doc)}
        onSubmit={handleSubmit}
        pdfName={doc.pdf ?? ''}
        materialId={materialId}
        onSuccess={handleFormSuccess}
      />
    </div>
  );
};

const newDocData: DocData = {
  id: -1,
  name: '',
  index: '',
  from: new Date(Date.now()).toString(),
  to: '',
  pdf: null,
};

export const SingleMaterial = ({}: {}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { materialId: id } = useNumericParams<{ materialId: string }>();
  const isNew = id === -1;
  const { data } = useQuery([readMaterial.id, id], () => {
    return isNew
      ? Promise.resolve<MaterialData>({
          id: -1,
          name: '',
          manufacturer: '',
          docs: [],
        })
      : readMaterial.request(id);
  });

  const handleSubmit = isNew
    ? (formData: MaterialFormData) => {
        const body = toMaterialDTO(formData);

        return createMaterial.request(body);
      }
    : (formData: MaterialFormData) => {
        const body = toMaterialDTO(formData);

        return updateMaterial.request(body, id);
      };

  const handleFormSuccess = isNew
    ? (response: { id: number }) => {
        queryClient.invalidateQueries(readMaterials.id);

        history.replace(
          composeRoute('/materials/:materialId', {
            materialId: response.id,
          }),
        );
      }
    : () => {
        queryClient.invalidateQueries(readMaterials.id);
        queryClient.invalidateQueries([readMaterial.id, id]);
      };

  const onStop = () => {
    history.push(composeRoute('/materials'));
  };

  return (
    <div className={classNames('modal', 'is-active')}>
      <div className="modal-background" onClick={onStop}>
        <button className="modal-close is-large" aria-label="close"></button>
      </div>
      <div className="modal-content">
        <div className="box">
          {data && (
            <>
              <MaterialForm
                defaultValues={toMaterialFormData(data)}
                onSubmit={handleSubmit}
                onSuccess={handleFormSuccess}
              />
              <div className="divider">Документы</div>
              {isNew ? (
                <div className="message">
                  <div className="message-body">
                    После сохранения здесь можно будет прикреплять документы
                  </div>
                </div>
              ) : (
                <div>
                  <div className="columns is-multiline">
                    <div className="column is-half">
                      <Doc
                        data={newDocData}
                        materialId={id}
                        key={newDocData.id}
                      />
                    </div>
                    {data.docs.map((doc) => {
                      return (
                        <div className="column is-half">
                          <Doc key={doc.id} data={doc} materialId={id} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
