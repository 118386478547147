import * as yup from 'yup';
import { ReadMaterialDTO, MaterialDTO } from '../../../api/_out/api';

export type MaterialData = ReadMaterialDTO;
export type MaterialBody = MaterialDTO;

export const materialFormSchema = yup.object({
  name: yup.string().required('Наименование не может быть пустым'),
  manufacturer: yup.string().min(0),
});

export type MaterialFormData = yup.InferType<typeof materialFormSchema>;

export const toMaterialFormData = (
  material: MaterialData,
): MaterialFormData => {
  const { name, manufacturer } = material;

  return {
    name,
    manufacturer,
  };
};

export const toMaterialDTO = (data: MaterialFormData): MaterialBody => {
  const { name, manufacturer } = data;

  return {
    name,
    manufacturer: manufacturer ?? '',
  };
};
