// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tq8Rhi4PRC5TkQVrB3Rg{padding-bottom:80px !important}.fCSA_U2RnbsB4UQk5DvN{display:flex;align-items:center;justify-content:flex-end;position:absolute;left:0;right:0;bottom:0;height:80px;padding:0 1.5rem}", "",{"version":3,"sources":["webpack://./src/pages/Piece/PieceWorks/PieceWorks.sass"],"names":[],"mappings":"AAAA,sBAAW,8BAA8B,CAAC,sBAAQ,YAAY,CAAC,kBAAkB,CAAC,wBAAwB,CAAC,iBAAiB,CAAC,MAAM,CAAC,OAAO,CAAC,QAAQ,CAAC,WAAW,CAAC,gBAAgB","sourcesContent":[".container{padding-bottom:80px !important}.footer{display:flex;align-items:center;justify-content:flex-end;position:absolute;left:0;right:0;bottom:0;height:80px;padding:0 1.5rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "tq8Rhi4PRC5TkQVrB3Rg",
	"footer": "fCSA_U2RnbsB4UQk5DvN"
};
export default ___CSS_LOADER_EXPORT___;
