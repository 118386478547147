import { useCallback, useState } from 'react';

export const useDict = () => {
  const [dict, setDict] = useState<Record<string, boolean>>({});

  const drop = useCallback(
    (key: string) => {
      setDict((prev) => {
        const { [key]: _, ...rest } = prev;

        return rest;
      });
    },
    [setDict],
  );

  const add = useCallback(
    (key: string) => {
      setDict((prev) => {
        return {
          ...prev,
          [key]: true,
        };
      });
    },
    [setDict],
  );

  return {
    dict,
    add,
    drop,
  };
};
