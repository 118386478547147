import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { Prompt } from 'react-router-dom';
import { api } from '../../../../api';
import {
  useDefaultSuccessHandler,
  useDefaultHandler,
} from '../../../../api/useDefaultApiHandler';
import { FormProps } from '../../../../utils/formProps';
import { useYupResolver } from '../../../../utils/useYupResolver';
import {
  noPersonIdFormSchema,
  OrderFormData,
  orderFormSchema,
} from './order.misc';

const { readPartnership, readCompanies } = api;

type Props = FormProps<OrderFormData> & {
  hasPersonIdField: boolean;
  orderGroupId: number;
  id: number;
};

export const OrderFormModal = ({ onCancel, ...props }: Props) => {
  return ReactDOM.createPortal(
    <div className="modal is-active">
      <div className="modal-background" onClick={onCancel}></div>
      <div className="modal-content">
        <div className="box">
          <OrderForm {...props} />
        </div>
      </div>
    </div>,
    document.getElementById('modal') as HTMLDivElement,
  );
};

const OrderForm = ({
  defaultValues,
  onSubmit,
  onSuccess,
  hasPersonIdField,
}: Props) => {
  const queryClient = useQueryClient();
  const showSuccessNotification = useDefaultSuccessHandler(
    'Данные о приказе успешно сохранены',
  );

  const { data: companies } = useQuery(readCompanies.id, () => {
    return readCompanies.request();
  });

  const { register, handleSubmit, formState, reset, getValues } =
    useForm<OrderFormData>({
      defaultValues,
      resolver: useYupResolver(
        hasPersonIdField ? orderFormSchema : noPersonIdFormSchema,
      ),
    });

  const { isDirty, errors } = formState;

  const { mutate, isLoading } = useMutation(
    (formData: OrderFormData) => {
      return onSubmit(formData);
    },
    {
      onSuccess: (response) => {
        reset(getValues());
        showSuccessNotification();
        onSuccess && onSuccess(response);
        queryClient.invalidateQueries([readPartnership.id]);
      },
      onError: useDefaultHandler(),
    },
  );

  const submit = (data: OrderFormData) => mutate(data);

  const defaultOptions = [{ label: '', value: -1 }];
  const options = companies
    ? [
        ...defaultOptions,
        ...companies.map((company) => {
          return {
            label: company.name,
            value: String(company.id),
          };
        }),
      ]
    : defaultOptions;

  return (
    <>
      <Prompt
        when={isDirty}
        message="В форме есть несохраненные изменения, вы точно хотите уйти?"
      />
      <form onSubmit={handleSubmit(submit)}>
        <div className="field">
          <label className="label is-small">
            Наименование распорядительного документа
          </label>
          <div className="control">
            <input
              className="input is-small"
              type="text"
              placeholder=""
              {...register('alias')}
            />
          </div>
        </div>
        <div className="columns mb-0">
          <div className="column is-4 pr-1">
            <div className="field">
              <label className="label is-small">Номер</label>
              <div className="control">
                <input
                  className="input is-small"
                  type="text"
                  placeholder="12"
                  {...register('index')}
                />
              </div>
              {!!errors.index && (
                <p className="help is-danger">{errors.index.message}</p>
              )}
            </div>
          </div>

          <div className="column is-8 pl-1">
            <div className="field">
              <label className="label is-small">Дата</label>
              <div className="control">
                <input
                  className="input is-small"
                  type="date"
                  placeholder=""
                  {...register('date')}
                />
              </div>
              {!!errors.date && (
                <p className="help is-danger">{errors.date.message}</p>
              )}
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label is-small">Организация</label>
          <div className="control">
            <div className="select is-small is-expanded">
              <select {...register('companyId')}>
                {options.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {!!errors.companyId && (
            <p className="help is-danger">{errors.companyId.message}</p>
          )}
        </div>

        {hasPersonIdField && (
          <div className="field">
            <label className="label is-small">
              Номер в национальном реестре специалистов в области строительства
            </label>
            <div className="control">
              <input
                className="input is-small"
                type="text"
                placeholder="С-78-000001"
                {...register('personId')}
              />
            </div>
            {!!errors.personId && (
              <p className="help is-danger">{errors.personId.message}</p>
            )}
          </div>
        )}

        <div className="field">
          <label className="label is-small">Должность</label>
          <div className="control">
            <input
              className="input is-small"
              type="text"
              placeholder="Начальник участка"
              {...register('position')}
            />
          </div>
          {!!errors.position && (
            <p className="help is-danger">{errors.position.message}</p>
          )}
        </div>

        <div className="field">
          <label className="label is-small">Фамилия И. О.</label>
          <div className="control">
            <input
              className="input is-small"
              type="text"
              placeholder="Кравцов А. С."
              {...register('name')}
            />
          </div>
          {!!errors.name && (
            <p className="help is-danger">{errors.name.message}</p>
          )}
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              type="submit"
              disabled={isLoading}
              className="button is-small is-outlined is-info"
            >
              <i className="fa fa-check"></i>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
