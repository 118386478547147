import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  close: () => void;
}>;

export const Help = ({ close, children }: Props) => {
  return (
    <div className="notification is-info is-light">
      <button className="delete" onClick={close}></button>
      {children}
    </div>
  );
};
