import { createContext } from 'react';
import { noop } from 'lodash-es';

export type BaseMessage = {
  type: 'success' | 'error' | 'info';
  content: string;
};

type Message = BaseMessage & {
  id: string;
};

type ContextType = {
  messages: Message[];
  add: (message: BaseMessage) => void;
  remove: (id: string) => void;
};

export const NotifierContext = createContext<ContextType>({
  messages: [],
  add: noop,
  remove: noop,
});
