import * as yup from 'yup';
import {
  ChangePasswordDTO,
  AddUserDto,
  GroupMemberDTO,
} from '../../../api/_out';

export type MemberData = GroupMemberDTO;
export type MemberCreateBody = AddUserDto;
export type MemberUpdateBody = ChangePasswordDTO;

export const memberFormSchema = yup.object({
  username: yup.string().required(),
  password: yup.string().required().min(6),
});

export type MemberFormData = yup.InferType<typeof memberFormSchema>;

export const toMemberFormData = (member: MemberData): MemberFormData => {
  const { username } = member;

  return {
    username,
    password: '',
  };
};

export const toMemberCreateDTO = (data: MemberFormData) => {
  const { username, password } = data;

  return {
    username,
    password,
  };
};

export const toMemberUpdateBody = (data: MemberFormData) => {
  const { password } = data;

  return {
    password,
  };
};
