import classNames from 'classnames';
import Tippy, { useSingleton } from '@tippyjs/react';
import { compact } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../../api';
import { PartnershipOrders } from './PartnershipOrders/PartnershipOrders';

const { readBuildingPartnerships } = api;

const parseNumber = (a: string) => {
  const match = a.match(/\d+/);
  const number = match?.at(0);

  return number ?? null;
};

const getTitle = ({
  alias,
  desc,
}: {
  alias: string;
  desc: string;
}): { short: string; long?: string } => {
  if (alias === 'developer')
    return {
      short: 'Застройщик',
      long: 'Застройщик (технический заказчик, эксплуатирующая организация или региональный оператор)',
    };

  if (alias === 'builder') {
    return {
      short: 'Ген. подрядчик',
      long: 'Представитель лица, осуществляющего строительство',
    };
  }

  if (alias === 'controller') {
    return {
      short: 'Строительный контроль',
      long: 'Представитель лица, осуществляющего строительство, по вопросам строительного контроля (специалист по организации строительства)',
    };
  }

  if (alias === 'projecter') {
    return {
      short: 'Проектировщик',
      long: 'Представитель лица, осуществляющего подготовку проектной документации',
    };
  }

  if (alias === 'contractor') {
    return {
      short: 'Подрядчик',
      long: 'Представитель лица, осуществляющего строительство, выполнившего работы, подлежащие освидетельствованию',
    };
  }

  if (alias.match('other')) {
    return {
      short: compact(['Иное лицо', parseNumber(alias)]).join(' '),
      long: 'Представитель иного лица, участвующего в освидетельствовании',
    };
  }

  return {
    short: desc,
  };
};

export const BuildingOrders = ({ id }: { id: number }) => {
  const { data: partnerships } = useQuery(
    [readBuildingPartnerships.id, id],
    () => {
      return readBuildingPartnerships.request(id);
    },
    {
      onSuccess: (data) => {
        const { partnerships } = data;

        if (!activeId && partnerships.length > 0)
          setActiveId(partnerships[0].id);
      },
    },
  );

  const [activeId, setActiveId] = useState<number | null>(null);

  const activePartnership = useMemo(() => {
    return partnerships?.partnerships.find((item) => item.id === activeId);
  }, [activeId, partnerships]);

  const [singletonSource, singletonTarget] = useSingleton();

  return (
    <>
      <Tippy singleton={singletonSource} placement="bottom" delay={[1000, 0]} />

      {partnerships ? (
        <div>
          <div className="buttons">
            {partnerships.partnerships.map((item) => {
              const { alias, desc } = item;
              const { short, long } = getTitle({ alias, desc });

              return long ? (
                <Tippy
                  singleton={singletonTarget}
                  key={item.id}
                  content={
                    <div
                      className="box has-background-white-ter p-2 has-text-center"
                      style={{ lineHeight: 1.1, maxWidth: '320px' }}
                    >
                      <span className="is-size-7">{long}</span>
                    </div>
                  }
                >
                  <button
                    className={classNames(
                      activeId === item.id ? 'is-info is-light' : 'is-white',
                      'button is-small',
                    )}
                    onClick={() => setActiveId(item.id)}
                    key={item.id}
                  >
                    {short}
                  </button>
                </Tippy>
              ) : (
                <button
                  className={classNames(
                    activeId === item.id ? 'is-light' : 'is-white',
                    'button is-small',
                  )}
                  onClick={() => setActiveId(item.id)}
                  key={item.id}
                >
                  {short}
                </button>
              );
            })}
          </div>
          <div>
            {!!activePartnership && (
              <PartnershipOrders buildingId={id} id={activePartnership.id} />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};
