import { useNotifications } from '../modules/Notifier/useNotifications';

type ErrorResponse = {
  status: number;
  message: string;
  errors: { path: string; type: string; message: string }[];
};
type ShouldInterrupt = boolean;
type Output = (message: string) => void;
type Handler = (
  response: ErrorResponse,
  outputError: Output,
) => ShouldInterrupt;

const composeHandlers = (handlers: Handler[]) => {
  return (response: ErrorResponse, output: Output) => {
    let interrupted = false;

    for (let i = 0; i < handlers.length && !interrupted; i++) {
      const handler = handlers[i];

      interrupted = handler(response, output);
    }

    return interrupted;
  };
};

const createSimpleHandler = (code: number, message: string) => {
  return (response: ErrorResponse, output: Output) => {
    if (response.status === code) {
      output(response.message || message);

      return true;
    }

    return false;
  };
};

const handle400: Handler = createSimpleHandler(400, 'Неверный запрос');
const handle401: Handler = createSimpleHandler(401, 'Нужна авторизация');
const handle403: Handler = createSimpleHandler(
  403,
  'Нет активной подписки или превышено количество оплаченных участников',
);
const handle409: Handler = createSimpleHandler(409, 'Конфликт');
const handle413: Handler = createSimpleHandler(413, 'Слишком большой файл');
const handle500: Handler = createSimpleHandler(500, 'Ошибка на сервере');

const defaultHandler = composeHandlers([
  handle400,
  handle401,
  handle403,
  handle409,
  handle413,
  handle500,
]);

export const useDefaultHandler = () => {
  const addMessage = useNotifications();
  const show = (message: string) => {
    addMessage({
      content: message,
      type: 'error',
    });
  };

  return (response: ErrorResponse) => {
    defaultHandler(response, show);
  };
};

export const useDefaultSuccessHandler = (message = 'Успех') => {
  const addMessage = useNotifications();
  const show = (message: string) => {
    addMessage({
      content: message,
      type: 'success',
    });
  };

  return (_?: unknown) => {
    show(message);
  };
};
