// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZtsN4pSlGViAOjXn7eAh{display:flex;align-items:stretch}.QJLCjyb4JluDoZQkQ6pT{min-width:250px;min-height:250px}.Z4TgJEUW0qkGNMKW5Ebz{cursor:grab}", "",{"version":3,"sources":["webpack://./src/pages/Building/BuildingOrders/OrderGroup/OrderGroup.sass"],"names":[],"mappings":"AAAA,sBAAQ,YAAY,CAAC,mBAAmB,CAAC,sBAAW,eAAe,CAAC,gBAAgB,CAAC,sBAAO,WAAW","sourcesContent":[".orders{display:flex;align-items:stretch}.container{min-width:250px;min-height:250px}.piece{cursor:grab}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orders": "ZtsN4pSlGViAOjXn7eAh",
	"container": "QJLCjyb4JluDoZQkQ6pT",
	"piece": "Z4TgJEUW0qkGNMKW5Ebz"
};
export default ___CSS_LOADER_EXPORT___;
