import * as yup from 'yup';
import { ReadMaterialDocDTO, MaterialDocDTO } from '../../../api/_out/api';
import { getRequiredMessage } from '../../../utils/getRequiredMessage';
import { toDateInputValue } from '../../../utils/toDatetimeInputValue';

type NullableFields = 'to' | 'pdf';

export type DocData = WithNulls<ReadMaterialDocDTO, NullableFields>;
export type DocBody = WithNulls<MaterialDocDTO, 'to'>;

export const docFormSchema = yup.object({
  name: yup.string().required(getRequiredMessage('Наименование')),
  index: yup.string().required(getRequiredMessage('Номер')),
  from: yup
    .string()
    .required(getRequiredMessage('От'))
    .matches(/(\d{4})-(\d{2})-(\d{2})/, { excludeEmptyString: true }),
  to: yup
    .string()
    .matches(/(\d{4})-(\d{2})-(\d{2})/, { excludeEmptyString: true }),
  pdf: yup.mixed(),
});

export type DocFormData = yup.InferType<typeof docFormSchema>;

export const toDocFormData = (doc: DocData) => {
  const { name, index, from, to } = doc;

  return {
    name,
    index,
    from: toDateInputValue(new Date(from)),
    to: to ? toDateInputValue(new Date(to)) : '',
    pdf: undefined,
  };
};

export const toDocDTO = (data: DocFormData): DocBody => {
  const { name, index, from, to } = data;

  return {
    name,
    index,
    from: new Date(from).toISOString(),
    to: to ? new Date(to).toISOString() : null,
  };
};
