import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '../../../api';
import { useDefaultSuccessHandler } from '../../../api/useDefaultApiHandler';

const { deleteUser, readGroupMembers, whoami } = api;

export const DeleteMemberButton = ({ id }: { id: number }) => {
  if (id === -1) return null;

  const { data } = useQuery([whoami.id], whoami.request);
  const queryClient = useQueryClient();

  const showSuccessNotification = useDefaultSuccessHandler(
    'Участник успешно удален',
  );

  const { mutate: suicide, isLoading: isDeleting } = useMutation(
    () => {
      return deleteUser.request(id);
    },
    {
      onSuccess: () => {
        showSuccessNotification();
        queryClient.invalidateQueries([readGroupMembers.id]);
      },
    },
  );

  const showConfirmation = () => {
    const isConfirmed = window.confirm(
      'Удалить участника? Он мгновенно потеряет доступ ко всем данным вашей группы',
    );

    if (isConfirmed) {
      suicide();
    }
  };

  return data && data.id !== id ? (
    <div className="control is-concealed">
      <button
        className="button is-outlined is-danger"
        disabled={isDeleting}
        onClick={showConfirmation}
        type="button"
      >
        <i className="fa fa-trash"></i>
      </button>
    </div>
  ) : null;
};
