import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { intersection } from 'lodash-es';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { api } from '../../../api';
import { composeRoute } from '../../../routing/routing';
import { useDebouncedChangeHandler } from '../../../utils/getDefaultInputHandler';
import { PieceWorksForm } from './PieceWorksForm';
import {
  toPatchWorksDTO,
  toWorksFormData,
  WorkData,
  WorksFormData,
} from './pieceWorksForm.misc';
import { ReportButton } from './components/ReportButton';
import styles from './PieceWorks.sass';
import { usePersistentSort } from '../../../utils/usePersistentSort';
import { useDefaultSuccessHandler } from '../../../api/useDefaultApiHandler';

const { readPieceWorks, patchWorks, copyWork } = api;

type OrderBy = 'ASC' | 'DESC';
type KeyOfWork = keyof WorkData;

const SortIcon = <T extends string>({
  isFor,
  orderKey,
  orderValue,
  handleChange,
}: {
  isFor: T;
  orderKey: T;
  orderValue: OrderBy;
  handleChange: (nextKey: T) => void;
}) => {
  const handleClick = useCallback(() => {
    handleChange(isFor);
  }, [handleChange, isFor]);

  return orderKey === isFor ? (
    <span
      className="icon has-text-grey-darker is-clickable"
      onClick={handleClick}
    >
      <i
        className={classNames(
          'fas',
          orderValue === 'ASC' ? 'fa-sort-asc' : 'fa-sort-desc',
        )}
      ></i>
    </span>
  ) : (
    <span
      className="icon has-text-grey-lighter is-clickable"
      onClick={handleClick}
    >
      <i className={classNames('fas', 'fa-sort')}></i>
    </span>
  );
};

const renderDate = (date: string | null) => {
  return date ? new Date(date).toLocaleDateString() : '—';
};

const renderTags = (tags: string[]) => {
  return tags.join(', ');
};

export const PieceWorks = ({ pieceId }: { pieceId: number }) => {
  const queryClient = useQueryClient();
  const showCopySuccess = useDefaultSuccessHandler(
    'Работа успешно скопирована',
  );
  const {
    key: orderKey,
    order: orderValue,
    switchSort,
  } = usePersistentSort<keyof WorkData>('pieceWorks')('index');

  const { data: works } = useQuery(
    [readPieceWorks.id, pieceId, orderKey, orderValue],
    () => {
      return readPieceWorks.request(pieceId, orderKey, orderValue);
    },
    { keepPreviousData: true },
  );

  const { value: tagFilter, handleChange: handleTagsChange } =
    useDebouncedChangeHandler('');

  const withFilter = useMemo(() => {
    if (tagFilter.length === 0) return works ?? [];

    const separate = tagFilter
      .replaceAll(/(,[\s]+)/g, ',')
      .split(',')
      .filter((value) => !!value);

    return (
      works?.filter((work) => {
        const { tags } = work;
        const toLowerCase = (value: string) => value.toLowerCase();

        return (
          intersection(separate.map(toLowerCase), tags.map(toLowerCase))
            .length > 0
        );
      }) ?? []
    );
  }, [works, tagFilter]);

  const [isReadonlyMode, setIsReadonlyMode] = useState<boolean>(true);

  // TODO: use mutate
  const handleSubmit = (data: WorksFormData) => {
    const body = toPatchWorksDTO(data, works ?? []);

    return patchWorks.request(body, pieceId);
  };

  const { mutate: makeCopyRequest, isLoading: isCopying } = useMutation(
    (workId: number) => {
      return copyWork.request(workId);
    },
    {
      onSuccess: () => {
        showCopySuccess();
        queryClient.invalidateQueries(readPieceWorks.id);
      },
    },
  );

  const handleCopy = useCallback(
    (event: React.MouseEvent) => {
      const item = event.currentTarget.closest(
        '[data-work-id]',
      ) as HTMLDivElement;
      const id = Number(item.dataset.workId);

      makeCopyRequest(id);
    },
    [makeCopyRequest],
  );

  const handleCancel = () => {
    setIsReadonlyMode(true);
  };

  const handleSuccess = () => {
    setIsReadonlyMode(true);

    queryClient.invalidateQueries([readPieceWorks.id, pieceId]);
  };

  const workIds = useMemo(() => {
    return withFilter.map((work) => work.id);
  }, [withFilter]);

  return (
    <>
      {isReadonlyMode && (
        <>
          <div className="level">
            <div className="level-left"></div>
            <div className="level-right">
              <div className={classNames('level-item')}>
                <div className="buttons">
                  {(works?.length ?? 0) > 0 && (
                    <>
                      <button
                        className="button"
                        onClick={() => setIsReadonlyMode(false)}
                      >
                        Редактировать
                      </button>
                      <ReportButton id={pieceId} works={workIds} />
                    </>
                  )}

                  <Link
                    to={composeRoute('/pieces/:pieceId/works/:workId', {
                      workId: -1,
                      pieceId,
                    })}
                    type="button"
                    className="button is-primary"
                  >
                    Новая работа
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label is-small">
              Показывать только работы с метками
            </label>
            <p className="control has-icons-left">
              <input
                className="input"
                type="text"
                placeholder="06.21"
                defaultValue={tagFilter}
                onChange={handleTagsChange}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-search"></i>
              </span>
            </p>
          </div>
        </>
      )}
      <div
        className={classNames(
          'box is-relative',
          !isReadonlyMode && styles.container,
        )}
      >
        <div
          className="grid-table"
          style={{
            gridTemplateColumns: 'minmax(min-content, 50px) repeat(5, auto)',
          }}
        >
          <div className="head">
            <div className="cell">
              №
              <SortIcon<KeyOfWork>
                isFor="index"
                orderKey={orderKey}
                orderValue={orderValue}
                handleChange={switchSort}
              />
            </div>
            <div className="cell">Наименование</div>
            <div className="cell">
              Дата начала
              <SortIcon<KeyOfWork>
                isFor="startDate"
                orderKey={orderKey}
                orderValue={orderValue}
                handleChange={switchSort}
              />
            </div>
            <div className="cell">
              Дата окончания
              <SortIcon<KeyOfWork>
                isFor="finishDate"
                orderKey={orderKey}
                orderValue={orderValue}
                handleChange={switchSort}
              />
            </div>
            <div className="cell">
              Дата акта
              <SortIcon<KeyOfWork>
                isFor="reportDate"
                orderKey={orderKey}
                orderValue={orderValue}
                handleChange={switchSort}
              />
            </div>
            <div className="cell">Метки</div>
          </div>
          {withFilter ? (
            <>
              {isReadonlyMode ? (
                <div className="body">
                  {withFilter.map((work) => {
                    const { startDate, finishDate, reportDate, tags } = work;

                    return (
                      <div
                        className="item is-revealing"
                        data-work-id={work.id}
                        key={work.id}
                      >
                        <div className="cell">{work.index}</div>
                        <div className="cell">
                          <Link
                            to={composeRoute('/pieces/:pieceId/works/:workId', {
                              pieceId,
                              workId: work.id,
                            })}
                          >
                            <span>{work.name}</span>
                          </Link>
                          <button
                            className={classNames(
                              'button is-small is-white ml-2',
                              isCopying && 'is-loading',
                            )}
                            disabled={isCopying}
                            onClick={handleCopy}
                          >
                            <span
                              className="icon has-text-grey-dark is-concealed"
                              title="Копировать работу"
                            >
                              <i className="fas fa-copy"></i>
                            </span>
                          </button>
                        </div>
                        <div className="cell">
                          <span>{renderDate(startDate)}</span>
                        </div>
                        <div className="cell">
                          <span>{renderDate(finishDate)}</span>
                        </div>
                        <div className="cell">
                          <span>{renderDate(reportDate)}</span>
                        </div>
                        <div className="cell">
                          <span>{renderTags(tags)}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <PieceWorksForm
                  defaultValues={toWorksFormData(withFilter)}
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                  onSuccess={handleSuccess}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
