import React from 'react';
import classNames from 'classnames';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormProps } from '../../../utils/formProps';
import { useMutation } from 'react-query';
import { WorksFormData } from './pieceWorksForm.misc';
import styles from './PieceWorks.sass';
import { useDefaultSuccessHandler } from '../../../api/useDefaultApiHandler';

export const PieceWorksForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  onSuccess,
}: FormProps<WorksFormData>) => {
  const showSuccess = useDefaultSuccessHandler('Работы успешно сохранены');
  const { register, control, handleSubmit } = useForm<WorksFormData>({
    defaultValues,
  });
  const { fields } = useFieldArray({ control, name: 'works' });
  const { mutate } = useMutation(
    (data: WorksFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: (response) => {
        showSuccess();

        onSuccess && onSuccess(response);
      },
    },
  );
  const submit = (data: WorksFormData) => {
    return mutate(data);
  };

  return (
    <form className="body" onSubmit={handleSubmit(submit)}>
      {fields.map((field, index) => {
        return (
          <div key={field.id} className="item">
            <div className="cell">
              <div className="field">
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="12"
                    {...register(`works.${index}.index`)}
                  />
                </div>
              </div>
            </div>

            <div className="cell">
              <div className="field">
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Бетонирование фундамента"
                    {...register(`works.${index}.name`)}
                  />
                </div>
              </div>
            </div>

            <div className="cell">
              <div className="field">
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    {...register(`works.${index}.startDate`)}
                  />
                </div>
              </div>
            </div>

            <div className="cell">
              <div className="field">
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    {...register(`works.${index}.finishDate`)}
                  />
                </div>
              </div>
            </div>

            <div className="cell">
              <div className="field">
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    {...register(`works.${index}.reportDate`)}
                  />
                </div>
              </div>
            </div>

            <div className="cell">
              <div className="field">
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    {...register(`works.${index}.tags`)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className={styles.footer}>
        <div className="field">
          <div className="control">
            <div className="buttons">
              <button
                className="button is-danger is-outlined"
                onClick={onCancel}
              >
                Отменить
              </button>
              <button type="submit" className="button is-info">
                Применить
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
