import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { formatRelative } from 'date-fns';
import { ru } from 'date-fns/locale';
import { api } from '../../api';
import { composeRoute } from '../../routing/routing';

const { readBuildings } = api;

export const BuildingsPage = () => {
  const { data: buildings } = useQuery(readBuildings.id, () => {
    return readBuildings.request();
  });

  return (
    <div className="section">
      <div className="container">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">Объекты</h1>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="buttons">
                <Link
                  to={composeRoute('/buildings/:buildingId/information', {
                    buildingId: -1,
                  })}
                  type="button"
                  className="button is-primary"
                >
                  Новый объект
                </Link>
              </div>
            </div>
          </div>
        </div>
        {buildings ? (
          <div className="box">
            <div
              className="grid-table"
              style={{ gridTemplateColumns: 'repeat(2, auto)' }}
            >
              <div className="head">
                <div className="cell">Наименование</div>
                <div className="cell is-justify-content-end">
                  Дата изменения
                </div>
              </div>

              <div className="body">
                {buildings
                  .sort((a, b) => {
                    return (
                      new Date(b.updatedAt).valueOf() -
                      new Date(a.updatedAt).valueOf()
                    );
                  })
                  .map((building) => {
                    const { id, name, updatedAt } = building;
                    const asDate = new Date(updatedAt);

                    return (
                      <div key={id} className="item">
                        <div className="cell">
                          <Link
                            to={composeRoute('/buildings/:buildingId', {
                              buildingId: id,
                            })}
                          >
                            {name}
                          </Link>
                        </div>
                        <div className="cell is-justify-content-end">
                          {formatRelative(asDate, new Date(), { locale: ru })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
