import classNames from 'classnames';

const letters = 'abcdefghijklmnopqrstuvwxyz'.split('');
const variations = ['primary', 'link', 'info', 'success', 'warning', 'danger'];
const classes = variations.map((variation) => {
  return [
    `has-background-${variation}-light`,
    `has-text-${variation}-dark`,
  ] as [string, string];
});
const map = letters.reduce((map, letter, index) => {
  const targetIndex = index % variations.length;
  const set = classes[targetIndex];

  return {
    ...map,
    [letter]: set,
  };
}, {} as Record<string, [string, string]>);

const getClassNamesByUsername = (username: string) => {
  const charAt0 = username.charAt(0);

  return map[charAt0] ?? ['has-background-white', 'has-text-dark'];
};

export const UsernameCircle = ({ username }: { username: string }) => {
  return (
    <div className={classNames('circle', ...getClassNamesByUsername(username))}>
      {username.charAt(0) ?? '.'}
    </div>
  );
};
