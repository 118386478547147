import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { FormProps } from '../../../utils/formProps';
import { useYupResolver } from '../../../utils/useYupResolver';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../api/useDefaultApiHandler';
import { PieceFormData, pieceFormSchema } from './pieceForm.misc';
import { Prompt } from 'react-router-dom';

export const PieceForm = ({
  defaultValues,
  onSubmit,
  onSuccess,
}: FormProps<PieceFormData>) => {
  const {
    register,
    handleSubmit: submit,
    formState,
    reset,
    getValues,
  } = useForm<PieceFormData>({
    defaultValues,
    resolver: useYupResolver(pieceFormSchema),
  });
  const { isDirty, errors } = formState;

  const showSuccessNotification = useDefaultSuccessHandler(
    'Данные о разделе успешно сохранены',
  );

  const { mutate, isLoading } = useMutation(
    (data: PieceFormData) => {
      return onSubmit(data);
    },
    {
      onError: useDefaultHandler(),
      onSuccess: (response) => {
        reset(getValues());
        showSuccessNotification();

        onSuccess && onSuccess(response);
      },
    },
  );

  const handleSubmit = (data: PieceFormData) => {
    mutate(data);
  };

  return (
    <>
      <Prompt
        when={isDirty}
        message="В форме есть несохраненные изменения, вы точно хотите уйти?"
      />
      <form onSubmit={submit(handleSubmit)}>
        <div className="field">
          <label className="label">Наименование</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Конструкции железнобетонные"
              {...register('name')}
            />
          </div>
          {!!errors.name && (
            <p className="help is-danger">{errors.name.message}</p>
          )}
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="label">Шифр</div>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="8283.КЖ"
                  {...register('cipher')}
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="field">
              <div className="label">Норматив по умолчанию</div>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="СП 435.1325800.2018.КОНСТРУКЦИИ БЕТОННЫЕ И ЖЕЛЕЗОБЕТОННЫЕ МОНОЛИТНЫЕ"
                  {...register('standard')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button disabled={isLoading} className="button is-link">
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
