import React, { useEffect, useState } from 'react';
import { useWhoami } from '../../modules/Whoami/useWhoami';
import { api } from '../../api';
import { useMutation } from 'react-query';
import Tippy from '@tippyjs/react';

const { resendConfirmationId } = api;

type Props = {
  sentAt: Date;
};

const tenMinutes = 10 * 60 * 1000;
const isResendAvailable = (lastSent: Date) => {
  return Date.now() - lastSent.valueOf() >= tenMinutes;
};

export const ConfirmationPage = ({ sentAt }: Props) => {
  const [shouldWait, setShouldWait] = useState<boolean>(
    !isResendAvailable(sentAt),
  );
  const { logout, refresh, data } = useWhoami();
  const { mutate: retry } = useMutation(
    () => {
      return resendConfirmationId.request();
    },
    {
      onError: (response: { status: number }) => {
        if (response.status === 409) {
          setShouldWait(true);
        }
      },
      onSuccess: () => {
        refresh();
      },
    },
  );

  const handleRetryClick = () => {
    retry();
  };

  useEffect(() => {
    setShouldWait(!isResendAvailable(sentAt));
  }, [sentAt]);

  return (
    <div className="fullscreen-overlay">
      <div>
        <article className="message is-info">
          <div className="message-body">
            <div className="content has-text-center">
              <p className=" has-text-center">
                <b>{sentAt.toLocaleString()}</b> на вашу почту{' '}
                <b>{data?.username ?? ''}</b> выслано подтверждение.
              </p>
              <p>Чтобы продолжить, нужно перейти по ссылке в письме.</p>
            </div>
          </div>
        </article>
        <div className="buttons is-right">
          <button
            type="button"
            className="button is-info is-inverted"
            onClick={logout}
          >
            Войти под другим пользователем
          </button>
          {shouldWait ? (
            <Tippy
              appendTo={() => document.getElementById('modal') as HTMLElement}
              content={
                <div className="box">
                  С момента последней отправки прошло меньше 10 минут
                </div>
              }
              placement="top"
            >
              <div>
                <button
                  type="button"
                  disabled
                  className="button is-info is-outlined"
                >
                  Выслать повторно
                </button>
              </div>
            </Tippy>
          ) : (
            <button
              type="button"
              className="button is-info is-outlined"
              onClick={handleRetryClick}
            >
              Выслать повторно
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
