import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { FormProps } from '../../../utils/formProps';
import { MaterialFormData, materialFormSchema } from './materialForm.misc';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../api/useDefaultApiHandler';
import { Prompt } from 'react-router-dom';
import { useYupResolver } from '../../../utils/useYupResolver';

export const MaterialForm = ({
  defaultValues,
  onSubmit,
  onSuccess,
}: FormProps<MaterialFormData>) => {
  const { register, handleSubmit, formState, reset, getValues } =
    useForm<MaterialFormData>({
      defaultValues,
      resolver: useYupResolver(materialFormSchema),
    });

  const { isDirty, errors } = formState;

  const showSuccessNotification = useDefaultSuccessHandler(
    'Материал успешно сохранен',
  );

  const { mutate, isLoading } = useMutation(
    (data: MaterialFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: (response) => {
        showSuccessNotification();
        reset(getValues());

        onSuccess && onSuccess(response);
      },
      onError: useDefaultHandler(),
    },
  );

  const submit = (data: MaterialFormData) => {
    mutate(data);
  };

  return (
    <>
      <Prompt
        when={isDirty}
        message="В форме материала остались несохраненные изменения. Вы точно хотите уйти?"
      />
      <form onSubmit={handleSubmit(submit)}>
        <div className="is-flex gap-4">
          <div className="field is-flex-1">
            <label className="label">Наименование</label>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Бетон"
                  {...register('name')}
                />
                {!!errors.name && (
                  <p className="help is-danger">{errors.name.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="field is-flex-1">
            <div className="control">
              <label className="label">Производитель</label>
              <div className="control" style={{ flex: 1 }}>
                <input
                  className="input"
                  type="text"
                  placeholder="Долерит"
                  {...register('manufacturer')}
                />
                {!!errors.manufacturer && (
                  <p className="help is-danger">
                    {errors.manufacturer.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              type="submit"
              disabled={isLoading || !isDirty}
              className={classNames(
                'button is-info',
                isLoading && 'is-loading',
              )}
            >
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
