// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DTbTpnEshVSmNBR2oonb{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/modules/Sidebar/Sidebar.sass"],"names":[],"mappings":"AAAA,sBAAM,kBAAkB,CAAC,eAAe,CAAC,sBAAsB","sourcesContent":[".name{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "DTbTpnEshVSmNBR2oonb"
};
export default ___CSS_LOADER_EXPORT___;
