import * as yup from 'yup';
import { CompanyDTO, ReadCompanyDTO } from '../../api/_out';

type NullableFields =
  | 'address'
  | 'fax'
  | 'phone'
  | 'psrn'
  | 'sro'
  | 'sroPsrn'
  | 'sroTin'
  | 'tin';

type AnyOneArgFn = (value: any) => any;
export type CompanyData = WithNulls<ReadCompanyDTO, NullableFields>;
export type CompanyBody = WithNulls<CompanyDTO, NullableFields>;

export const toEmpty = (arg: string | number | null) =>
  arg === null ? '' : String(arg);
export const toNull = <T extends AnyOneArgFn>(
  arg: string,
  ifNot: T,
): ReturnType<T> | null => (arg === '' ? null : ifNot(arg));

export const toCompanyFormData = (data: CompanyData): CompanyFormData => {
  const { address, fax, name, phone, psrn, sro, sroPsrn, sroTin, tin } = data;

  return {
    address: toEmpty(address),
    fax: toEmpty(fax),
    name,
    phone: toEmpty(phone),
    psrn: toEmpty(psrn),
    sro: toEmpty(sro),
    sroPsrn: toEmpty(sroPsrn),
    sroTin: toEmpty(sroTin),
    tin: toEmpty(tin),
  };
};

export const toCompanyBody = (data: CompanyFormData): CompanyBody => {
  const { address, fax, name, phone, psrn, sro, sroPsrn, sroTin, tin } = data;

  return {
    address: toNull(address, String),
    fax: toNull(fax, String),
    name,
    phone: toNull(phone, String),
    psrn: toNull(psrn, String),
    sro: toNull(sro, String),
    sroPsrn: toNull(sroPsrn, String),
    sroTin: toNull(sroTin, String),
    tin: toNull(tin, String),
  };
};

const isTIN = () =>
  yup.string().matches(/^\d{10}$/, {
    excludeEmptyString: true,
    message: 'В ИНН должно быть 10 цифр',
  });
const isPSRN = () =>
  yup.string().matches(/^\d{13}$/, {
    excludeEmptyString: true,
    message: 'В ОГРН должно быть 13 цифр',
  });

export const companyFormSchema = yup.object({
  name: yup.string(),
  tin: isTIN(),
  psrn: isPSRN(),
  address: yup.string(),
  phone: yup.string(),
  fax: yup.string(),
  sro: yup.string(),
  sroPsrn: isPSRN(),
  sroTin: isTIN(),
});

export type CompanyFormData = ExludeNullValues<
  yup.InferType<typeof companyFormSchema>
>;
