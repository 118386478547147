import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { api } from '../../api';
import { GroupForm } from './GroupForm/GroupForm';
import {
  GroupFormData,
  toGroupDTO,
  toGroupFormData,
} from './GroupForm/groupForm.misc';

const { readGroup, updateGroup } = api;

export const GroupName = () => {
  const queryClient = useQueryClient();
  const { data: group } = useQuery([readGroup.id], () => {
    return readGroup.request();
  });

  const handleSubmit = useCallback((data: GroupFormData) => {
    const body = toGroupDTO(data);

    return updateGroup.request(body);
  }, []);

  const handleSuccess = () => {
    queryClient.invalidateQueries([readGroup.id]);
  };

  return group ? (
    <>
      <div className="box">
        <GroupForm
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          defaultValues={toGroupFormData(group)}
        />
      </div>
    </>
  ) : null;
};
