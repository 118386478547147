import classNames from 'classnames';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, Route, useHistory } from 'react-router-dom';
import { api } from '../../../../api';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../../api/useDefaultApiHandler';
import { composeRoute } from '../../../../routing/routing';
import {
  OrderData,
  toOrderFormData,
  OrderFormData,
  toOrderDTO,
  OrderBody,
} from './order.misc';
import { OrderFormModal } from './OrderForm';
import { useNumericParams } from '../../../../utils/useNumericParams';

const { deleteOrder, updateOrder, readOrdersOfGroup, readCompanies } = api;

export const Order = ({
  data,
  orderGroupId,
  hasPersonIdField,
}: {
  data: OrderData;
  orderGroupId: number;
  hasPersonIdField: boolean;
}) => {
  const history = useHistory();
  const { buildingId } = useNumericParams<{ buildingId: string }>();
  const { id } = data;
  const queryClient = useQueryClient();
  const defaultFormValues = toOrderFormData(data);
  const { data: companies } = useQuery([readCompanies.id], () => {
    return readCompanies.request();
  });
  const { alias, index, date, position, companyId, name } = data;
  const onSubmit = (formData: OrderFormData) => {
    const body = toOrderDTO(formData) as ExludeNullValues<OrderBody>;

    return updateOrder.request(body, data.id);
  };

  const company = companies?.find((company) => {
    return company.id === companyId;
  });

  const showDeleteSuccessNotification = useDefaultSuccessHandler(
    'Приказ успешно удален',
  );

  const { mutate: suicide } = useMutation(
    () => {
      const confirmed = window.confirm('Удалить приказ?');

      if (confirmed) {
        return deleteOrder.request(id);
      }

      return Promise.reject();
    },
    {
      onSuccess: () => {
        showDeleteSuccessNotification();
        queryClient.invalidateQueries([readOrdersOfGroup.id, orderGroupId]);
      },
      onError: useDefaultHandler(),
    },
  );

  const goToAllOrders = useCallback(() => {
    history.push(
      composeRoute('/buildings/:buildingId/orders', {
        buildingId,
      }),
    );
  }, [history]);

  return (
    <>
      <div className={classNames('box is-shadowless is-revealing-1 is-size-7')}>
        <div className="mb-3">
          <div className="">
            <span>{alias} </span> <span>№{index}</span>{' '}
            <span className="ml-auto">
              от {new Date(date).toLocaleDateString()}
            </span>
          </div>
          <div className="">
            <span>{position}</span>{' '}
            {company ? <span>{company.name}</span> : null}
          </div>
          <div className="is-flex">
            <span>{name}</span>
          </div>
        </div>

        <div className="field is-grouped is-grouped-right is-concealed-1">
          <div className="control">
            <Link
              className="button is-small is-outlined"
              to={composeRoute(
                '/buildings/:buildingId/orders/:orderGroupId/:orderId',
                {
                  buildingId,
                  orderGroupId,
                  orderId: id,
                },
              )}
            >
              <i className="fas fa-pencil"></i>
            </Link>
          </div>
          <div className="control">
            <button
              className="button is-small is-outlined is-danger"
              onClick={() => suicide()}
              type="button"
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <Route
        path={composeRoute(
          '/buildings/:buildingId/orders/:orderGroupId/:orderId',
          {
            buildingId,
            orderGroupId,
            orderId: id,
          },
        )}
      >
        <OrderFormModal
          defaultValues={defaultFormValues}
          hasPersonIdField={hasPersonIdField}
          onSubmit={onSubmit}
          orderGroupId={orderGroupId}
          onSuccess={goToAllOrders}
          onCancel={goToAllOrders}
          id={data.id}
        />
      </Route>
    </>
  );
};
