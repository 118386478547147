import React from 'react';
import { FormProps } from '../../../utils/formProps';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Prompt } from 'react-router-dom';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../api/useDefaultApiHandler';
import { useYupResolver } from '../../../utils/useYupResolver';
import {
  BuildingFormData,
  buildingFormSchema,
} from './buildingInformationForm.misc';

export const BuildingInformationForm = ({
  defaultValues,
  onSubmit,
  onSuccess,
}: FormProps<BuildingFormData>) => {
  const { register, handleSubmit, formState, reset, getValues } =
    useForm<BuildingFormData>({
      defaultValues,
      resolver: useYupResolver(buildingFormSchema),
    });
  const { isDirty, errors } = formState;

  const showSuccessNotification = useDefaultSuccessHandler(
    'Информация об объекте успешно сохранена',
  );

  const { mutate, isLoading } = useMutation(
    (data: BuildingFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: (response) => {
        reset(getValues());
        showSuccessNotification();

        onSuccess && onSuccess(response);
      },
      onError: useDefaultHandler(),
    },
  );

  const submit = (data: BuildingFormData) => {
    mutate(data);
  };

  return (
    <>
      <Prompt
        message="В форме есть несохраненные изменения, вы точно хотите уйти?"
        when={isDirty}
      />
      <form onSubmit={handleSubmit(submit)}>
        <div className="field">
          <label className="label">Наименование</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Исаакиевский собор"
              {...register('name')}
            />
          </div>
          {!!errors.name && (
            <p className="help is-danger">{errors.name.message}</p>
          )}
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button disabled={isLoading} className="button is-link">
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
