import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { noop } from 'lodash-es';
import { api } from '../../api';
import { WhoamiDTO } from '../../api/_out/api';
import { useQuery, useQueryClient } from 'react-query';

const { whoami: readWhoami } = api;

type ContextType = {
  data: WhoamiDTO | undefined;
  login: (token: string) => void;
  logout: () => void;
  refresh: () => void;
  isLoggedIn: boolean;
  awaitingConfirmation: Date | null | undefined;
};

export const WhoamiContext = createContext<ContextType>({
  data: undefined,
  login: noop,
  logout: noop,
  refresh: noop,
  isLoggedIn: false,
  awaitingConfirmation: undefined,
});

const getAuthToken = () => {
  return localStorage.getItem('token');
};

const TOKEN_KEY = 'token';

export const Whoami: FC<PropsWithChildren<{}>> = ({ children }) => {
  const queryClient = useQueryClient();
  const [authToken, setAuthToken] = useState<string | null | undefined>(
    getAuthToken(),
  );

  const login = useCallback(
    (token: string) => {
      localStorage.setItem(TOKEN_KEY, token);
      setAuthToken(() => token);
    },
    [setAuthToken],
  );

  const logout = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    setAuthToken(null);
  }, [setAuthToken]);

  const isLoggedIn = Boolean(authToken);

  const { data: whoami } = useQuery(
    [readWhoami.id, authToken],
    () => {
      return readWhoami.request();
    },
    {
      enabled: isLoggedIn,
      onError: (response: { status: number }) => {
        if (response.status === 401) {
          logout();
        }
      },
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const refresh = useCallback(() => {
    queryClient.invalidateQueries([readWhoami.id, authToken]);
  }, [authToken, queryClient]);

  const contextValue: ContextType = useMemo(() => {
    const { awaitingConfirmation } = whoami ?? {
      hasSubscription: false,
      awaitingConfirmation: undefined,
    };

    return {
      data: whoami,
      login,
      logout,
      isLoggedIn,
      refresh,
      awaitingConfirmation:
        typeof awaitingConfirmation === 'string'
          ? new Date(awaitingConfirmation)
          : awaitingConfirmation,
    };
  }, [login, logout, whoami, isLoggedIn]);

  return (
    <WhoamiContext.Provider value={contextValue}>
      {children}
    </WhoamiContext.Provider>
  );
};
