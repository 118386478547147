import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, NavLink } from 'react-router-dom';
import { api } from '../../api';
import { Help } from '../../components/Help/Help';
import { useHelp } from '../../components/Help/useHelp';
import { composeRoute } from '../../routing/routing';
import { useDebouncedChangeHandler } from '../../utils/getDefaultInputHandler';
import styles from './CompaniesPage.sass';

// TODO: do not use id, just pass function
const { readCompanies } = api;

type Company = Awaited<ReturnType<typeof readCompanies.request>>[0];

export const CompaniesPage = () => {
  const { data: companies } = useQuery(readCompanies.id, () => {
    return readCompanies.request();
  });
  const [filtered, setFiltered] = useState<Company[]>([]);
  const { value: filter, handleChange: handleFilterChange } =
    useDebouncedChangeHandler('');

  useEffect(() => {
    setFiltered(() => {
      const nextValue =
        companies?.filter(({ name }) => {
          return name.toLowerCase().match(filter.toLowerCase());
        }) ?? [];

      return nextValue.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    });
  }, [companies, filter]);

  const {
    isVisible: showHelp,
    toggle: toggleHelp,
    close: closeHelp,
  } = useHelp('companies');

  return (
    <>
      <div className="section">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Организации</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <div className="buttons">
                  <Link
                    to={composeRoute('/companies/:companyId', {
                      companyId: -1,
                    })}
                    type="button"
                    className="button is-primary"
                  >
                    Новая организация
                  </Link>
                  <button
                    className={classNames(
                      showHelp ? 'has-text-info' : 'has-text-grey',
                      'button is-white',
                    )}
                    onClick={toggleHelp}
                  >
                    <i className="fas fa-question-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showHelp && (
            <Help close={closeHelp}>
              Организации используются в объектах и разделах, например в
              качестве застройщика или лица, осуществляющего подготовку
              проектной документации. Одна и та же организация может быть
              использована в разных объектах и разделах на одной или нескольких
              ролях. При формировании документации по разделу данные об
              организации подставляются в соответствующие поля АОСР.
            </Help>
          )}
          {companies ? (
            <>
              <div className="field">
                <p className="control has-icons-left">
                  <input
                    className="input"
                    type="text"
                    placeholder="Поиск"
                    defaultValue={filter}
                    onChange={handleFilterChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-search"></i>
                  </span>
                </p>
              </div>
              <div className="columns is-multiline">
                {filtered.map((company) => {
                  const { id, name, tin } = company;

                  return (
                    <div key={id} className="column is-3">
                      <NavLink
                        to={composeRoute('/companies/:companyId', {
                          companyId: id,
                        })}
                      >
                        <div
                          className={classNames(
                            'box is-clickable',
                            styles.company,
                          )}
                        >
                          <div className="title is-6 mb-3">{name}</div>

                          <div className="tags">
                            {!!tin && (
                              <div className="tag is-rounded">ИНН: {tin}</div>
                            )}
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
