import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import { api } from '../../../../api';
import { download } from '../../../../utils/download';
import { ReportForm } from './ReportForm';

const { readBuildingPiece, readReport, deleteReport, downloadReport } = api;

export const ReportButton = ({
  id,
  works,
}: {
  id: number;
  works: number[];
}) => {
  const queryClient = useQueryClient();
  const { data: piece } = useQuery([readBuildingPiece.id, id], () => {
    return readBuildingPiece.request(id);
  });

  const { data: report } = useQuery(
    [readReport.id, id],
    () => {
      return readReport.request(id);
    },
    {
      onError: (response: { status: number }) => {
        if (response.status === 404) {
          queryClient.setQueryData([readReport.id, id], undefined);
        }
      },
      refetchInterval: (data) => (data?.state === 'pending' ? 5000 : false),
      retry: false,
    },
  );

  const { mutate: cancelRequest } = useMutation(
    () => {
      return deleteReport.request(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([readReport.id, id]);
      },
    },
  );

  const getAct =
    report && report.state === 'ready'
      ? () => {
          downloadReport
            .request(report.id)
            .then((data) => data.blob())
            .then((blob) => {
              download(blob, `${piece?.name ?? 'docs'}.zip`);
            })
            .then(() => {
              queryClient.resetQueries([readReport.id, id]);
            });
        }
      : undefined;

  const isPending = report?.state === 'pending';
  const isReady = report?.state === 'ready';
  const isNotCreated = !report;

  return piece ? (
    <>
      {isReady && (
        <button className="button is-outlined is-info" onClick={getAct}>
          Скачать ИД
        </button>
      )}
      {isPending && (
        <button
          className={classNames('button is-outlined is-danger')}
          type="button"
          onClick={() => cancelRequest()}
        >
          <span className="icon-text">
            <span className="icon">
              <i className="fas fa-spinner fa-spin"></i>
            </span>
            <span>Отменить запрос</span>
          </span>
        </button>
      )}
      {isNotCreated && (
        <Tippy
          appendTo={() => document.getElementById('modal') as HTMLElement}
          content={
            <div className="box">
              <ReportForm
                buildingId={piece.parentId}
                pieceId={id}
                works={works}
              />
            </div>
          }
          interactive
          placement="bottom"
          trigger="click"
        >
          <button className="button is-outlined is-info">Подготовить ИД</button>
        </Tippy>
      )}
    </>
  ) : null;
};
