import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { api } from '../../api';
import { GroupMemberDTO } from '../../api/_out';
import { Help } from '../../components/Help/Help';
import { useHelp } from '../../components/Help/useHelp';
import { attrs, useTable } from '../../utils/useTable';
import { GroupName } from './GroupName';
import { DeleteMemberButton } from './MemberForm/DeleteMemberButton';
import { MemberForm } from './MemberForm/MemberForm';
import {
  MemberFormData,
  toMemberCreateDTO,
  toMemberFormData,
  toMemberUpdateBody,
} from './MemberForm/memberForm.misc';
import { UsernameCircle } from './UsernameCircle';

const { createGroupUser, readGroup, readGroupMembers, updateGroupUser } = api;

const Editable = ({
  data,
  domain,
}: {
  data: GroupMemberDTO;
  domain: string;
}) => {
  const { id } = data;
  const defaultValues = toMemberFormData(data);
  const handleSubmit = useCallback((data: MemberFormData) => {
    const body = toMemberUpdateBody(data);

    return updateGroupUser.request(body, id);
  }, []);

  return (
    <MemberForm
      id={id}
      domain={domain}
      readonly={{ username: true }}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    />
  );
};

const Readonly = ({ data }: { data: GroupMemberDTO }) => {
  const { id } = data;

  return (
    <div className="item is-revealing" {...attrs.idAttribute(String(id))}>
      <div className="cell">
        <UsernameCircle username={data.username} />
      </div>
      <div className="cell">{data.username}</div>
      <div className="cell">******</div>
      <div className="cell">
        <div className="field is-w-100 is-grouped is-grouped-right">
          <div className="control is-concealed">
            <button
              type="button"
              className="button is-info is-outlined "
              {...attrs.editAttr}
            >
              <i className="fa fa-edit"></i>
            </button>
          </div>
          <DeleteMemberButton id={id} />
        </div>
      </div>
    </div>
  );
};

const AddMember = ({ id, domain }: { id: number; domain: string }) => {
  const queryClient = useQueryClient();
  const defaultValues = { username: '', password: '' };
  const handleSubmit = useCallback((data: MemberFormData) => {
    const body = toMemberCreateDTO(data);

    return createGroupUser.request(body);
  }, []);
  const handleSuccess = () => {
    queryClient.invalidateQueries([readGroupMembers.id]);
  };

  return (
    <MemberForm
      id={id}
      domain={domain}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      onSuccess={handleSuccess}
    />
  );
};

const Members = () => {
  const { data: group } = useQuery([readGroup.id], () => {
    return readGroup.request();
  });

  const { data: members } = useQuery([readGroupMembers.id], () => {
    return readGroupMembers.request();
  });

  const { added, edited, handleClickCapture } = useTable();

  const {
    isVisible: showHelp,
    toggle: toggleHelp,
    close: closeHelp,
  } = useHelp('accountMembers');

  const domain = group?.domain ?? '';

  return (
    <div onClickCapture={handleClickCapture}>
      <div className="level">
        <div className="level-left">
          <div className="level-item"></div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div className="buttons">
              <button className="button is-primary" {...attrs.addAttr}>
                <span className="icon-text">
                  <span className="icon">
                    <i className="fas fa-plus"></i>
                  </span>
                  <span>Новый участник</span>
                </span>
              </button>
              <button
                className={classNames(
                  showHelp ? 'has-text-info' : 'has-text-grey',
                  'button is-white',
                )}
                onClick={toggleHelp}
              >
                <i className="fas fa-question-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      {showHelp && (
        <Help close={closeHelp}>
          <div className="content">
            <p>
              Все участники вашей команды имеют равный доступ к информации
              команды.
            </p>
            <p>
              Количество участников ограничено текущей подпиской. Если
              количество участников команды превышает оплаченное, то доступ к
              приложению ограничивается до тех пор, пока количество не вернется
              к норме.
            </p>
            <p>
              Чтобы восстановить доступ, удалите участников или оплатите
              подписку на нужное количество человек.
            </p>
          </div>
        </Help>
      )}

      <div className="box">
        <div
          className="grid-table"
          style={{ gridTemplateColumns: 'repeat(4, auto)' }}
        >
          <div className="head">
            <div className="cell"></div>
            <div className="cell">Логин</div>
            <div className="cell">Пароль</div>
            <div className="cell"></div>
          </div>
          <div className="body">
            {added.map((id) => {
              return (
                <AddMember key={Number(id)} id={Number(id)} domain={domain} />
              );
            })}

            {members?.members.map((member) => {
              return edited[String(member.id)] ? (
                <Editable data={member} key={member.id} domain={domain} />
              ) : (
                <Readonly data={member} key={member.id} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Team = () => {
  return (
    <>
      <GroupName />
      <div className="title is-4 mt-6">Участники</div>
      <Members />
    </>
  );
};
