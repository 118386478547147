import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '../../../api';
import { download } from '../../../utils/download';
import { FormProps } from '../../../utils/formProps';
import { attrs, useTable } from '../../../utils/useTable';
import { useYupResolver } from '../../../utils/useYupResolver';
import styles from '../WorkTable.sass';
import {
  SchemaBody,
  SchemaContext,
  SchemaData,
  SchemaFormData,
  schemaFormSchema,
  toSchemaDTO,
  toSchemaFormData,
} from './workSchemas.misc';
import { StampData } from '../../Piece/PieceStamps/StampForm.misc';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../api/useDefaultApiHandler';
import { Prompt } from 'react-router-dom';

const {
  createSchema,
  deleteSchema,
  deleteSchemaPdf,
  readSchemaPdf,
  readSchemas,
  readAvailableStamps,
  updateSchema,
  uploadSchemaPdf,
} = api;

const getDefaultFormValues = (defaultStampId: string): SchemaFormData => ({
  id: '-1',
  index: '',
  name: 'Исполнительная схема',
  schemaName: '',
  stampId: defaultStampId,
  pdf: undefined,
});

const upadtePdf = (id: number, pdfFileList: FileList | null | undefined) => {
  if (pdfFileList === null) {
    return deleteSchemaPdf.request(id);
  }

  const pdf = pdfFileList?.item(0);

  if (!pdf) {
    return;
  }

  const formData = new FormData();

  formData.append('file', pdf as File);

  return uploadSchemaPdf.request(id, { body: formData });
};

const Delete = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();
  const { workId } = useContext(SchemaContext);

  const showSuccess = useDefaultSuccessHandler('Схема успешно удалена');

  const { mutate: suicide } = useMutation(
    () => {
      return deleteSchema.request(id);
    },
    {
      onSuccess: () => {
        showSuccess();
        queryClient.invalidateQueries([readSchemas.id, workId]);
      },
    },
  );

  const handleDelete = () => {
    const shouldDo = window.confirm(
      'Вы действительно хотите удалить эту исполнительную схему?',
    );

    if (shouldDo) {
      suicide();
    }
  };

  return (
    <button
      type="button"
      className="button is-danger is-outlined is-concealed"
      onClick={handleDelete}
    >
      <i className="fa fa-trash"></i>
    </button>
  );
};

const Editable = ({
  form: { onSubmit, defaultValues, onSuccess },
  pdfName,
  stamps,
}: {
  form: FormProps<SchemaFormData>;
  pdfName: string | null;
  stamps: StampData[] | null;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
    getValues,
    reset,
  } = useForm<SchemaFormData>({
    defaultValues,
    resolver: useYupResolver(schemaFormSchema),
  });

  const { errors, isDirty } = formState;
  const showSuccess = useDefaultSuccessHandler('Схема успешно сохранена');

  const { mutate } = useMutation(
    (data: SchemaFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: (response) => {
        reset(getValues());
        showSuccess();

        onSuccess && onSuccess(response);
      },
      onError: useDefaultHandler(),
    },
  );

  const submit = (data: SchemaFormData) => {
    mutate(data);
  };

  const canBeDeleted = pdfName || (watch('pdf')?.length ?? 0) > 0;
  const pdfLabel =
    watch('pdf') === null ? (
      pdfName ? (
        <s>{pdfName}</s>
      ) : (
        '...'
      )
    ) : (
      <span>{watch('pdf')?.item(0)?.name || pdfName || '...'}</span>
    );
  const clearFile = pdfName
    ? () => setValue('pdf', null)
    : () => setValue('pdf', undefined);

  return (
    <>
      <Prompt
        when={isDirty}
        message="В одной или нескольких исполнительных схемах остались несохраненные данные. Вы точно хотите уйти?"
      />
      <form
        className={classNames(styles.item, 'is-revealing')}
        onSubmit={handleSubmit(submit)}
        {...attrs.idAttribute(defaultValues?.id as string)}
      >
        <div className={styles.cell}>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Исполнительная схема"
                {...register('name')}
              />
            </div>
            {!!errors.name && (
              <p className="help is-danger">{errors.name.message}</p>
            )}
          </div>
        </div>

        <div className={styles.cell}>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="1212"
                {...register('index')}
              />
            </div>
            <p className="help">
              Оставьте пустым, чтобы проставить автоматически
            </p>
          </div>
        </div>

        <div className={styles.cell}>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Бетонирование фундамента"
                {...register('schemaName')}
              />
            </div>
            <p className="help">
              Оставьте пустым, чтобы проставить автоматически
            </p>
          </div>
        </div>

        <div className={styles.cell}>
          {stamps && (
            <div className="field">
              <div className="control">
                <div className="select is-expanded">
                  <select {...register('stampId')}>
                    <option value="">&nbsp;</option>
                    {stamps.map(({ id, people }) => (
                      <option value={String(id)}>
                        {people.map(({ name }) => name).join(', ')}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <p className="help">Оставьте пустым, чтобы не ставить штамп </p>
            </div>
          )}
        </div>

        <div className={styles.cell}>
          <div className="file has-name">
            <label className="file-label">
              <input
                className="file-input"
                accept=".pdf"
                type="file"
                {...register('pdf')}
              />
              <span className="file-cta">
                <span className="file-icon mr-0">
                  <i className="fas fa-file-pdf"></i>
                </span>
              </span>
              <span className="file-name">{pdfLabel}</span>
            </label>
            {canBeDeleted && (
              <button
                type="button"
                className="button is-white ml-1"
                onClick={clearFile}
              >
                <i className="fa fa-close"></i>
              </button>
            )}
          </div>
        </div>

        <div className={styles.cell}>
          <div className="field">
            <div className="control">
              <div className="buttons is-pulled-right">
                <button
                  type="button"
                  className="button is-danger is-outlined is-concealed"
                  {...attrs.undoAttr}
                >
                  <i className="fa fa-undo"></i>
                </button>
                <button
                  className="button is-success is-outlined is-concealed"
                  type="submit"
                >
                  <i className="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

const Readonly = ({ data }: { data: SchemaData }) => {
  const { id, pdf } = data;
  const hasPdf = !!pdf;
  const { data: pdfUrl } = useQuery(
    [readSchemaPdf.id, id],
    () => {
      return readSchemaPdf.request(id);
    },
    {
      enabled: hasPdf,
    },
  );

  return (
    <div
      className={classNames(styles.item, styles.readonly, 'is-revealing')}
      {...attrs.idAttribute(String(id))}
    >
      <div className={styles.cell}>{data.name}</div>
      <div className={styles.cell}>{data.index}</div>
      <div className={styles.cell}>{data.schemaName}</div>
      <div className={styles.cell}>
        {data.stamp?.people.map(({ name }) => name).join(', ') ?? '—'}
      </div>
      <div className={styles.cell}>
        {!!pdfUrl && (
          <a
            href={pdfUrl.url}
            target="_blank"
            type="button"
            className="button is-white"
          >
            {pdf}
          </a>
        )}
      </div>
      <div className={styles.cell}>
        <div className="field">
          <div className="control">
            <div className="buttons is-pulled-right">
              <button
                type="button"
                className="button is-info is-outlined is-concealed"
                {...attrs.editAttr}
              >
                <i className="fa fa-edit"></i>
              </button>
              <Delete id={id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WorkSchemas = ({ workId }: { workId: number }) => {
  const queryClient = useQueryClient();

  const { data: items } = useQuery([readSchemas.id, workId], () => {
    return readSchemas.request(workId).then((schemas) => {
      return schemas.reverse();
    });
  });

  const { data: stamps } = useQuery([readAvailableStamps.id, workId], () => {
    return readAvailableStamps.request(workId);
  });

  const { added, edited, deleteItem, handleClickCapture, stopEditing } =
    useTable();

  const handleCreate = (data: SchemaFormData) => {
    const body = toSchemaDTO(data) as ExludeNullValues<SchemaBody>;

    return createSchema
      .request(body, workId)
      .then((response) => {
        const { id } = response as { id: number };
        const { pdf: pdfFileList } = data;

        return upadtePdf(id, pdfFileList);
      })
      .then(() => {
        deleteItem(data.id);
        queryClient.invalidateQueries([readSchemas.id, workId]);
      });
  };

  const handleUpdate = (data: SchemaFormData) => {
    const id = Number(data.id);
    const body = toSchemaDTO(data) as ExludeNullValues<SchemaBody>;

    return updateSchema
      .request(body, id)
      .then(() => {
        const { pdf: pdfFileList } = data;

        return upadtePdf(id, pdfFileList);
      })
      .then(() => {
        stopEditing(data.id);
        queryClient.invalidateQueries([readSchemas.id, workId]);
      });
  };

  // items are sorted by updatedAt from newest to oldest, so we use latest used value
  const defaultStampId = useMemo(() => {
    return String(items?.at(0)?.stamp?.id ?? '');
  }, [items]);

  return (
    <SchemaContext.Provider value={{ workId }}>
      <div className="" onClickCapture={handleClickCapture}>
        <div className="level">
          <div className="level-left">
            <div className="level-item"></div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="buttons">
                <button className="button is-primary" {...attrs.addAttr}>
                  Новая схема
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={styles.items}
          style={{ gridTemplateColumns: 'repeat(6, auto)' }}
        >
          <div className={styles.head}>
            <div className={styles.cell}>Документ</div>
            <div className={styles.cell}>Номер</div>
            <div className={styles.cell}>Схема</div>
            <div className={styles.cell}>Штамп</div>
            <div className={styles.cell}>&nbsp;</div>
            <div className={styles.cell}>&nbsp;</div>
          </div>
          <div className={styles.body}>
            {added.map((schema) => (
              <Editable
                key={schema}
                form={{
                  defaultValues: {
                    ...getDefaultFormValues(defaultStampId),
                    id: schema,
                  },
                  onSubmit: handleCreate,
                }}
                pdfName={''}
                stamps={stamps ?? null}
              />
            ))}
            {items?.map((schema) => {
              return edited[String(schema.id)] ? (
                <Editable
                  form={{
                    defaultValues: toSchemaFormData(schema),
                    onSubmit: handleUpdate,
                  }}
                  pdfName={schema.pdf ?? null}
                  stamps={stamps ?? null}
                  key={schema.id}
                />
              ) : (
                <Readonly data={schema} key={schema.id} />
              );
            })}
          </div>
        </div>
      </div>
    </SchemaContext.Provider>
  );
};
