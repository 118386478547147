import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { FormProps } from '../../../utils/formProps';
import { GroupFormData, groupFormSchema } from './groupForm.misc';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../api/useDefaultApiHandler';
import { Prompt } from 'react-router-dom';
import { useYupResolver } from '../../../utils/useYupResolver';

export const GroupForm = ({
  defaultValues,
  onSubmit,
  onSuccess,
}: FormProps<GroupFormData>) => {
  const { register, handleSubmit, formState, reset, getValues } =
    useForm<GroupFormData>({
      defaultValues,
      resolver: useYupResolver(groupFormSchema),
    });

  const { isDirty, errors } = formState;

  const showSuccessNotification = useDefaultSuccessHandler(
    'Организация успешно сохранена',
  );

  const { mutate, isLoading } = useMutation(
    (data: GroupFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: (response) => {
        showSuccessNotification();
        reset(getValues());

        onSuccess && onSuccess(response);
      },
      onError: useDefaultHandler(),
    },
  );

  const submit = (data: GroupFormData) => {
    mutate(data);
  };

  return (
    <>
      <Prompt
        when={isDirty}
        message="В форме остались несохраненные изменения. Вы точно хотите уйти?"
      />
      <form onSubmit={handleSubmit(submit)}>
        <div className="field">
          <label className="label">Название</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Краски лета"
              {...register('name')}
            />
            {!!errors.name && (
              <p className="help is-danger">{errors.name.message}</p>
            )}
          </div>
        </div>
        <div className="field">
          <label className="label">Домен</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="krleta"
              {...register('domain')}
            />
            {errors.domain ? (
              <p className="help is-danger">{errors.domain.message}</p>
            ) : (
              <p className="help">
                Домен используется в логинах участников команды при входе. Может
                включать только латинские буквы, цифры и точки
              </p>
            )}
          </div>
        </div>
        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              type="submit"
              disabled={isLoading || !isDirty}
              className={classNames(
                'button is-link',
                isLoading && 'is-loading',
              )}
            >
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
