import classNames from 'classnames';
import { PartnershipPieceDTO } from '../../../../api/_out';
import styles from './OrderGroup.sass';

export const OrderGroupPieces = ({
  pieces,
  orderGroupId,
}: {
  pieces: PartnershipPieceDTO[];
  orderGroupId: number | null;
}) => {
  const onPieceDragStart = (event: React.DragEvent<HTMLSpanElement>) => {
    const { dataset } = event.currentTarget;
    const pieceId = dataset['pieceId'] as string;
    const data = JSON.stringify({ orderGroupId, pieceId: Number(pieceId) });

    event.dataTransfer.setData('text/plain', data);
  };

  return (
    <div className="tags">
      {pieces.map((piece) => {
        return (
          <span
            key={piece.id}
            data-piece-id={piece.id}
            draggable
            className={classNames(styles.piece, 'tag')}
            onDragStart={onPieceDragStart}
          >
            {piece.name}
          </span>
        );
      })}
    </div>
  );
};
