import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { composeRoute, getRoute } from '../../routing/routing';
import { api } from '../../api';
import logo from './static/logo.png';
import classNames from 'classnames';
import styles from './Sidebar.sass';

const { readBuildingNames, readBuildingPieces } = api;

const BuildingsPageLink: FC<PropsWithChildren<{}>> = ({ children }) => {
  const matchBuildingsPage = useRouteMatch(getRoute('/buildings'));
  const matchWorkPage = useRouteMatch(
    getRoute('/pieces/:pieceId/works/:workId'),
  );
  const isMatch = !!matchBuildingsPage || !!matchWorkPage;
  const isExact = !!matchBuildingsPage?.isExact;
  const className = classNames(
    isMatch && 'has-text-weight-bold',
    isExact && 'is-active',
  );

  return (
    <NavLink to={composeRoute('/buildings')} className={className}>
      {children}
    </NavLink>
  );
};

const SingleBuildingPageLink: FC<
  PropsWithChildren<{ name: string; id: number; pieces: number[] }>
> = ({ children, id, pieces }) => {
  const location = useLocation();

  const isExact = useMemo(() => {
    return [
      composeRoute('/buildings/:buildingId', { buildingId: id }),
      composeRoute('/buildings/:buildingId/information', { buildingId: id }),
      composeRoute('/buildings/:buildingId/orders', { buildingId: id }),
      composeRoute('/buildings/:buildingId/pieces', { buildingId: id }),
    ].includes(location.pathname);
  }, [location.pathname, id]);

  const pieceRouteParts = useMemo(() => {
    return pieces.map((piece) => {
      return `/pieces/${piece}`;
    });
  }, [pieces]);

  const inSomePieceRoute = useMemo(() => {
    return pieceRouteParts.some((routePart) => {
      return !!location.pathname.match(routePart);
    });
  }, [pieceRouteParts, location.pathname]);

  const isMatch = isExact || inSomePieceRoute;

  const className = classNames(
    isMatch && 'has-text-weight-bold',
    isExact && 'is-active',
  );

  return (
    <NavLink
      to={composeRoute('/buildings/:buildingId', { buildingId: id })}
      className={className}
      strict
    >
      {children}
    </NavLink>
  );
};

const SinglePiecePageLink: FC<
  PropsWithChildren<{ buildingId: number; id: number }>
> = ({ buildingId, children, id }) => {
  const location = useLocation();
  const isExact = useMemo(() => {
    const route = composeRoute('/buildings/:buildingId/pieces/:pieceId', {
      buildingId: buildingId,
      pieceId: id,
    });

    return location.pathname.startsWith(route);
  }, [buildingId, id, location]);

  const isMatch = isExact || location.pathname.startsWith(`/pieces/${id}`);
  const className = classNames(isMatch && 'has-text-weight-bold is-active');

  return (
    <NavLink
      to={composeRoute('/buildings/:buildingId/pieces/:pieceId', {
        buildingId: buildingId,
        pieceId: id,
      })}
      className={className}
    >
      {children}
    </NavLink>
  );
};

const Building = ({
  id,
  name,
  hasPieces,
}: {
  id: number;
  name: string;
  hasPieces: boolean;
}) => {
  const { data: pieces } = useQuery(
    [readBuildingPieces.id, id],
    () => {
      return readBuildingPieces.request(id);
    },
    {
      enabled: hasPieces,
    },
  );

  const [shouldShowPieces, setPiecesVisibility] = useState(false);

  const togglePiecesVisibility = useCallback(() => {
    return setPiecesVisibility((value) => !value);
  }, [setPiecesVisibility]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    togglePiecesVisibility();
  };

  const pieceIds = useMemo(() => {
    return pieces?.map((piece) => piece.id) ?? [];
  }, [pieces]);

  return (
    <li>
      <SingleBuildingPageLink name={name} id={id} pieces={pieceIds}>
        {name}
        {hasPieces && (
          <i
            className={classNames(
              'fas ml-2 has-text-grey',
              shouldShowPieces ? 'fa-caret-up' : 'fa-caret-down',
            )}
            onClick={handleClick}
          ></i>
        )}
      </SingleBuildingPageLink>

      {pieces && pieces.length > 0 && shouldShowPieces && (
        <ul>
          {pieces.map(({ id: pieceId, name: pieceName }) => {
            return (
              <li key={pieceId}>
                <SinglePiecePageLink buildingId={id} id={pieceId}>
                  <div className={styles.name}>{pieceName}</div>
                </SinglePiecePageLink>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};

const Buildings = () => {
  const { data: buildings } = useQuery(readBuildingNames.id, () => {
    return readBuildingNames.request();
  });

  return buildings ? (
    <ul>
      {buildings.buildings.map((building) => {
        return <Building key={building.id} {...building} />;
      })}
    </ul>
  ) : null;
};

export const Sidebar = () => {
  const [shouldShowBuildings, setBuildingsVisibility] = useState(false);

  const toggleBuildingsVisibility = useCallback(() => {
    return setBuildingsVisibility((value) => !value);
  }, [setBuildingsVisibility]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    toggleBuildingsVisibility();
  };

  return (
    <div>
      <div className="p-3 pr-4">
        <NavLink to={composeRoute('/')}>
          <img src={logo} alt="" />
        </NavLink>
      </div>
      <aside className="menu">
        <ul className="menu-list">
          <BuildingsPageLink>
            Объекты
            <i
              className={classNames(
                'fas p-1 ml-auto',
                shouldShowBuildings ? 'fa-angle-up' : 'fa-angle-down',
              )}
              onClick={handleClick}
            ></i>
          </BuildingsPageLink>
          <li>{shouldShowBuildings && <Buildings />}</li>
          <li>
            <NavLink
              to={composeRoute('/materials')}
              className=""
              activeClassName="is-active"
            >
              Материалы
            </NavLink>
          </li>
          <li>
            <NavLink
              to={composeRoute('/companies')}
              className=""
              activeClassName="is-active"
            >
              Организации
            </NavLink>
          </li>
        </ul>
      </aside>
    </div>
  );
};
