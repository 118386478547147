import * as yup from 'yup';
import { createContext } from 'react';
import { StampDTO, ReadStampDTO } from '../../../api/_out/api';

export type StampData = ReadStampDTO;
export type StampBody = StampDTO;

export const stampFormSchema = yup.object({
  people: yup.array(
    yup
      .object({
        name: yup.string(),
        position: yup.string(),
      })
      .required(),
  ),
});

export type StampFormData = yup.InferType<typeof stampFormSchema>;

export const toStampFormData = (stamp: StampData): StampFormData => {
  const { people } = stamp;

  return {
    people,
  };
};

export const toStampDTO = (data: StampFormData): StampBody => {
  const { people } = data;

  return {
    people: people?.filter((person) => {
      return person.name && person.position;
    }) as StampBody['people'],
  };
};

export type ContextType = {
  pieceId: number;
};

export const StampContext = createContext<ContextType>({ pieceId: -1 });
