import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { api } from '../../api';
import { composeRoute } from '../../routing/routing';

const { readBuilding } = api;

export const BuildingBreadcrumb = ({ id }: { id: number }) => {
  const { data } = useQuery([readBuilding.id, id], () => {
    return readBuilding.request(id);
  });

  return (
    <Link
      to={composeRoute('/buildings/:buildingId', {
        buildingId: id,
      })}
    >
      <i className="fas fa-city mr-2"></i> {data ? data.name : '...'}
    </Link>
  );
};
