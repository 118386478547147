import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../api';
import { useDefaultSuccessHandler } from '../../../api/useDefaultApiHandler';

const { readSubscriptions, deleteSubscription } = api;

export const DeleteSubscription = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();
  const showSuccessNotification = useDefaultSuccessHandler(
    'Подписка успешно удалена',
  );
  const { mutate: suicide } = useMutation(
    () => {
      return deleteSubscription.request(id);
    },
    {
      onSuccess: () => {
        showSuccessNotification();
        queryClient.invalidateQueries(readSubscriptions.id);
      },
    },
  );

  const handleDelete = () => {
    const shouldDo = window.confirm('Точно удалить подписку?');

    if (shouldDo) {
      suicide();
    }
  };

  return (
    <button
      className="button is-small is-danger is-outlined"
      onClick={handleDelete}
    >
      <i className="fas fa-trash"></i>
    </button>
  );
};
