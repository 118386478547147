import classNames from 'classnames';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../../../api';
import { CreateOrderGroup } from '../CreateOrderGroup/CreateOrderGroup';
import { OrderGroup } from '../OrderGroup/OrderGroup';
import { OrderGroupPieces } from '../OrderGroup/OrderGroupPieces';
import styles from './PartnershipOrders.sass';

const { readPartnership, readBuildingPieces } = api;

export const PartnershipOrders = ({
  buildingId,
  id,
}: {
  buildingId: number;
  id: number;
}) => {
  const { data: partnership } = useQuery([readPartnership.id, id], () => {
    return readPartnership.request(id);
  });

  const { data: pieces } = useQuery([readBuildingPieces.id, buildingId], () => {
    return readBuildingPieces.request(buildingId);
  });

  const piecesInUse = useMemo(() => {
    const inUse = new Set<number>();

    if (!partnership) return inUse;

    partnership.orderGroups.forEach((group) => {
      group.pieces.forEach((piece) => inUse.add(piece.id));
    });

    return inUse;
  }, [partnership]);

  const piecesNotInUse = useMemo(() => {
    if (!pieces) return [];

    return pieces.filter((piece) => !piecesInUse.has(piece.id));
  }, [pieces, piecesInUse]);

  const colorsInUse = useMemo(() => {
    const inUse =
      partnership?.orderGroups.map((group) => {
        return group.color;
      }) ?? [];

    return new Set<string>(inUse);
  }, [partnership?.orderGroups]);

  return partnership ? (
    <div>
      <div className="field is-grouped is-grouped-right">
        <CreateOrderGroup
          colorsInUse={colorsInUse}
          partnershipId={partnership.id}
        />
      </div>

      {piecesNotInUse.length > 0 && (
        <div className="mb-3">
          <div className="mb-1">Разделы без группы:</div>
          <OrderGroupPieces pieces={piecesNotInUse} orderGroupId={null} />
        </div>
      )}

      {!!partnership && (
        <div className={classNames(styles.groups, 'columns is-multiline')}>
          {partnership.orderGroups.map((orderGroup) => {
            return (
              <div
                className="column is-narrow is-flex-grow-1"
                key={orderGroup.id}
              >
                <OrderGroup data={orderGroup} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  ) : null;
};
