import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { FormProps } from '../../../utils/formProps';
import { api } from '../../../api';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../api/useDefaultApiHandler';
import { Prompt } from 'react-router-dom';
import { useYupResolver } from '../../../utils/useYupResolver';
import { docFormSchema } from './docForm.misc';

export type MaterialDocFormData = {
  name: string;
  index: string;
  from: string;
  to: string;
  pdf: FileList | null | undefined;
};

const { readMaterials, readMaterial, deleteDoc } = api;

export const DocForm = ({
  id,
  pdfName,
  defaultValues,
  materialId,
  onSuccess,
  onSubmit,
}: FormProps<MaterialDocFormData> & {
  id: number;
  materialId: number;
  pdfName: string;
}) => {
  const queryClient = useQueryClient();
  const isNew = id === -1;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState,
  } = useForm<MaterialDocFormData>({
    defaultValues,
    resolver: useYupResolver(docFormSchema),
  });

  const { errors, isDirty } = formState;

  const showUpdateNotification = useDefaultSuccessHandler(
    'Документ успешно сохранен',
  );
  const showDeleteNotification = useDefaultSuccessHandler(
    'Документ успешно удален',
  );

  const { mutate, isLoading } = useMutation(
    (data: MaterialDocFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: (response) => {
        if (isNew) {
          reset();
        } else {
          reset(getValues());
        }

        showUpdateNotification();

        onSuccess && onSuccess(response);
      },
      onError: useDefaultHandler(),
    },
  );

  const submit = (data: MaterialDocFormData) => {
    mutate(data);
  };

  const { mutate: suicide } = useMutation(
    () => {
      return deleteDoc.request(id);
    },
    {
      onSuccess: () => {
        showDeleteNotification();

        queryClient.invalidateQueries([readMaterial.id, materialId]);
        queryClient.invalidateQueries(readMaterials.id);
      },
      onError: useDefaultHandler(),
    },
  );

  // TODO: share with WorkLabs.tsx somehow
  const canBeDeleted = pdfName || (watch('pdf')?.length ?? 0) > 0;
  const pdfLabel =
    watch('pdf') === null ? (
      pdfName ? (
        <s>{pdfName}</s>
      ) : (
        '...'
      )
    ) : (
      <span>{watch('pdf')?.item(0)?.name || pdfName || '...'}</span>
    );
  const clearFile = pdfName
    ? () => setValue('pdf', null)
    : () => setValue('pdf', undefined);

  return (
    <>
      <Prompt
        when={isDirty}
        message="В одном или нескольких документах есть несохраненные изменения. Вы точно хотите уйти?"
      />
      <form onSubmit={handleSubmit(submit)}>
        <div className="field">
          <label className="label is-small">Наименование</label>
          <div className="control">
            <input
              className="input is-small"
              type="text"
              placeholder="Сертификат соответствия"
              {...register('name')}
            />
          </div>
          {!!errors.name && (
            <p className="help is-danger">{errors.name.message}</p>
          )}
        </div>

        <div className="field">
          <label className="label is-small">Номер</label>
          <div className="control">
            <input
              className="input is-small"
              type="text"
              placeholder="РОСС RU.32079.04СПБ1.ОС02.090"
              {...register('index')}
            />
          </div>
          {!!errors.index && (
            <p className="help is-danger">{errors.index.message}</p>
          )}
        </div>

        <div className="field">
          <label className="label is-small">От</label>
          <div className="control">
            <input
              className="input is-small"
              type="date"
              placeholder=""
              {...register('from')}
            />
          </div>
          {!!errors.from && (
            <p className="help is-danger">{errors.from.message}</p>
          )}
        </div>

        <div className="field">
          <label className="label is-small">До</label>
          <div className="control">
            <input
              className="input is-small"
              type="date"
              placeholder=""
              {...register('to')}
            />
          </div>
          {!!errors.to && <p className="help is-danger">{errors.to.message}</p>}
        </div>

        <div className="field">
          <div className="file is-small has-name">
            <label className="file-label">
              <input
                className="file-input"
                accept=".pdf"
                type="file"
                {...register('pdf')}
              />
              <span className="file-cta">
                <span className="file-icon mr-0">
                  <i className="fas fa-file-pdf"></i>
                </span>
              </span>
              <span className="file-name">{pdfLabel}</span>
            </label>
            {canBeDeleted && (
              <button
                type="button"
                className="button is-small is-white ml-1"
                onClick={clearFile}
              >
                <i className="fa fa-close"></i>
              </button>
            )}
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <div className="buttons">
              {!isNew && (
                <button
                  className="button is-small is-outlined is-danger"
                  onClick={() => suicide()}
                  type="button"
                >
                  <i className="fa fa-trash"></i>
                </button>
              )}
              <button
                type="submit"
                disabled={isLoading}
                className="button is-small is-outlined is-info"
              >
                <i className="fa fa-check"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
