// @ts-nocheck
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Tears
 * Tears API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AddUserDto
 */
export interface AddUserDto {
    /**
     * 
     * @type {string}
     * @memberof AddUserDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AddUserDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface AttachPieceDTO
 */
export interface AttachPieceDTO {
    /**
     * 
     * @type {number}
     * @memberof AttachPieceDTO
     */
    pieceId: number;
}
/**
 * 
 * @export
 * @interface Building
 */
export interface Building {
    /**
     * 
     * @type {Array<BuildingPiece>}
     * @memberof Building
     */
    pieces: Array<BuildingPiece>;
    /**
     * 
     * @type {Array<Partnership>}
     * @memberof Building
     */
    partnerships: Array<Partnership>;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    name: string;
    /**
     * 
     * @type {Owner}
     * @memberof Building
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof Building
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Building
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Building
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface BuildingDTO
 */
export interface BuildingDTO {
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    name: string;
}
/**
 * 
 * @export
 * @interface BuildingNameDTO
 */
export interface BuildingNameDTO {
    /**
     * 
     * @type {number}
     * @memberof BuildingNameDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingNameDTO
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingNameDTO
     */
    hasPieces: boolean;
}
/**
 * 
 * @export
 * @interface BuildingPartnershipDTO
 */
export interface BuildingPartnershipDTO {
    /**
     * 
     * @type {number}
     * @memberof BuildingPartnershipDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingPartnershipDTO
     */
    desc: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingPartnershipDTO
     */
    alias: string;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingPartnershipDTO
     */
    isReadonly: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingPartnershipDTO
     */
    isDefault: boolean;
}
/**
 * 
 * @export
 * @interface BuildingPiece
 */
export interface BuildingPiece {
    /**
     * 
     * @type {string}
     * @memberof BuildingPiece
     */
    cipher: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingPiece
     */
    standard: string;
    /**
     * 
     * @type {Building}
     * @memberof BuildingPiece
     */
    parent: Building;
    /**
     * 
     * @type {number}
     * @memberof BuildingPiece
     */
    parentId: number;
    /**
     * 
     * @type {Array<Work>}
     * @memberof BuildingPiece
     */
    works: Array<Work>;
    /**
     * 
     * @type {Array<Stamp>}
     * @memberof BuildingPiece
     */
    stamps: Array<Stamp>;
    /**
     * 
     * @type {string}
     * @memberof BuildingPiece
     */
    signatoryPosition: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingPiece
     */
    signatoryName: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingPiece
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingPiece
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingPiece
     */
    name: string;
    /**
     * 
     * @type {Owner}
     * @memberof BuildingPiece
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof BuildingPiece
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BuildingPiece
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BuildingPiece
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface BuildingPieceDTO
 */
export interface BuildingPieceDTO {
    /**
     * 
     * @type {string}
     * @memberof BuildingPieceDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingPieceDTO
     */
    cipher: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingPieceDTO
     */
    standard: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingPieceDTO
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingPieceDTO
     */
    signatoryPosition: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingPieceDTO
     */
    signatoryName: string;
}
/**
 * 
 * @export
 * @interface ChangePasswordDTO
 */
export interface ChangePasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDTO
     */
    password: string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    tin: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    psrn: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    fax: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    sro: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    sroPsrn: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    sroTin: string;
    /**
     * 
     * @type {Owner}
     * @memberof Company
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface CompanyDTO
 */
export interface CompanyDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    fax: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    psrn: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    sro: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    sroPsrn: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    sroTin: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    tin: string;
}
/**
 * 
 * @export
 * @interface Confirm
 */
export interface Confirm {
    /**
     * 
     * @type {number}
     * @memberof Confirm
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof Confirm
     */
    sendingDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Confirm
     */
    isConfirmed: boolean;
    /**
     * 
     * @type {string}
     * @memberof Confirm
     */
    code: string;
    /**
     * 
     * @type {User}
     * @memberof Confirm
     */
    user: User;
    /**
     * 
     * @type {number}
     * @memberof Confirm
     */
    userId: number;
}
/**
 * 
 * @export
 * @interface ConfirmPaymentResponseDTO
 */
export interface ConfirmPaymentResponseDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ConfirmPaymentResponseDTO
     */
    isSucceeded: boolean;
}
/**
 * 
 * @export
 * @interface CreateOrderDTO
 */
export interface CreateOrderDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDTO
     */
    alias: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDTO
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDTO
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDTO
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDTO
     */
    position: string;
}
/**
 * 
 * @export
 * @interface CreateOrderGroupDTO
 */
export interface CreateOrderGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderGroupDTO
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderGroupDTO
     */
    label: string;
}
/**
 * 
 * @export
 * @interface CreateSubscriptionBodyDTO
 */
export interface CreateSubscriptionBodyDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionBodyDTO
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionBodyDTO
     */
    to: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSubscriptionBodyDTO
     */
    users: number;
}
/**
 * 
 * @export
 * @interface CreateSubscriptionResponseDTO
 */
export interface CreateSubscriptionResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateSubscriptionResponseDTO
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSubscriptionResponseDTO
     */
    paymentId: number;
}
/**
 * 
 * @export
 * @interface GetPriceBodyDTO
 */
export interface GetPriceBodyDTO {
    /**
     * 
     * @type {number}
     * @memberof GetPriceBodyDTO
     */
    time: number;
    /**
     * 
     * @type {number}
     * @memberof GetPriceBodyDTO
     */
    users: number;
}
/**
 * 
 * @export
 * @interface GetPriceResponseDTO
 */
export interface GetPriceResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof GetPriceResponseDTO
     */
    price: number;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    domain: string;
    /**
     * 
     * @type {User}
     * @memberof Group
     */
    owner: User;
    /**
     * 
     * @type {Array<User>}
     * @memberof Group
     */
    members: Array<User>;
}
/**
 * 
 * @export
 * @interface GroupMemberDTO
 */
export interface GroupMemberDTO {
    /**
     * 
     * @type {number}
     * @memberof GroupMemberDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GroupMemberDTO
     */
    username: string;
}
/**
 * 
 * @export
 * @interface HashDTO
 */
export interface HashDTO {
    /**
     * 
     * @type {string}
     * @memberof HashDTO
     */
    password: string;
}
/**
 * 
 * @export
 * @interface Identity
 */
export interface Identity {
    /**
     * 
     * @type {string}
     * @memberof Identity
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Identity
     */
    id: number;
}
/**
 * 
 * @export
 * @interface Lab
 */
export interface Lab {
    /**
     * 
     * @type {number}
     * @memberof Lab
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Lab
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof Lab
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Lab
     */
    date: string;
    /**
     * 
     * @type {Work}
     * @memberof Lab
     */
    work: Work;
    /**
     * 
     * @type {LabPdf}
     * @memberof Lab
     */
    pdf?: LabPdf;
    /**
     * 
     * @type {Owner}
     * @memberof Lab
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof Lab
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Lab
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Lab
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface LabDTO
 */
export interface LabDTO {
    /**
     * 
     * @type {string}
     * @memberof LabDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof LabDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LabDTO
     */
    date: string;
}
/**
 * 
 * @export
 * @interface LabPdf
 */
export interface LabPdf {
    /**
     * 
     * @type {number}
     * @memberof LabPdf
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LabPdf
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LabPdf
     */
    xys: Array<string>;
    /**
     * 
     * @type {Lab}
     * @memberof LabPdf
     */
    lab: Lab;
    /**
     * 
     * @type {ModelStatic}
     * @memberof LabPdf
     */
    file: ModelStatic;
    /**
     * 
     * @type {number}
     * @memberof LabPdf
     */
    fileId: number;
    /**
     * 
     * @type {Owner}
     * @memberof LabPdf
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof LabPdf
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof LabPdf
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof LabPdf
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface LoginDTO
 */
export interface LoginDTO {
    /**
     * 
     * @type {string}
     * @memberof LoginDTO
     */
    accessToken: string;
}
/**
 * 
 * @export
 * @interface Material
 */
export interface Material {
    /**
     * 
     * @type {number}
     * @memberof Material
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    manufacturer: string;
    /**
     * 
     * @type {boolean}
     * @memberof Material
     */
    isPublic: boolean;
    /**
     * 
     * @type {Array<MaterialDoc>}
     * @memberof Material
     */
    docs: Array<MaterialDoc>;
    /**
     * 
     * @type {Owner}
     * @memberof Material
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof Material
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Material
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Material
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface MaterialDTO
 */
export interface MaterialDTO {
    /**
     * 
     * @type {string}
     * @memberof MaterialDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDTO
     */
    manufacturer: string;
}
/**
 * 
 * @export
 * @interface MaterialDoc
 */
export interface MaterialDoc {
    /**
     * 
     * @type {number}
     * @memberof MaterialDoc
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MaterialDoc
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDoc
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDoc
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDoc
     */
    to: string;
    /**
     * 
     * @type {MaterialDocPdf}
     * @memberof MaterialDoc
     */
    pdf: MaterialDocPdf;
    /**
     * 
     * @type {number}
     * @memberof MaterialDoc
     */
    pdfId: number;
    /**
     * 
     * @type {Material}
     * @memberof MaterialDoc
     */
    material: Material;
    /**
     * 
     * @type {number}
     * @memberof MaterialDoc
     */
    materialId: number;
    /**
     * 
     * @type {Owner}
     * @memberof MaterialDoc
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof MaterialDoc
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MaterialDoc
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MaterialDoc
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface MaterialDocDTO
 */
export interface MaterialDocDTO {
    /**
     * 
     * @type {string}
     * @memberof MaterialDocDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDocDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDocDTO
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDocDTO
     */
    to: string;
}
/**
 * 
 * @export
 * @interface MaterialDocPdf
 */
export interface MaterialDocPdf {
    /**
     * 
     * @type {number}
     * @memberof MaterialDocPdf
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MaterialDocPdf
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MaterialDocPdf
     */
    xys: Array<string>;
    /**
     * 
     * @type {MaterialDoc}
     * @memberof MaterialDocPdf
     */
    doc: MaterialDoc;
    /**
     * 
     * @type {ModelStatic}
     * @memberof MaterialDocPdf
     */
    file: ModelStatic;
    /**
     * 
     * @type {number}
     * @memberof MaterialDocPdf
     */
    fileId: number;
    /**
     * 
     * @type {Owner}
     * @memberof MaterialDocPdf
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof MaterialDocPdf
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MaterialDocPdf
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MaterialDocPdf
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface ModelStatic
 */
export interface ModelStatic {
    /**
     * 
     * @type {number}
     * @memberof ModelStatic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ModelStatic
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof ModelStatic
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelStatic
     */
    key: string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    position: string;
    /**
     * 
     * @type {Company}
     * @memberof Order
     */
    company: Company;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    personId: string;
    /**
     * 
     * @type {OrderGroup}
     * @memberof Order
     */
    group: OrderGroup;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    groupId: number;
    /**
     * 
     * @type {Owner}
     * @memberof Order
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface OrderDTO
 */
export interface OrderDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    alias: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDTO
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDTO
     */
    position: string;
}
/**
 * 
 * @export
 * @interface OrderGroup
 */
export interface OrderGroup {
    /**
     * 
     * @type {number}
     * @memberof OrderGroup
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OrderGroup
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof OrderGroup
     */
    label: string;
    /**
     * 
     * @type {Partnership}
     * @memberof OrderGroup
     */
    partnership: Partnership;
    /**
     * 
     * @type {number}
     * @memberof OrderGroup
     */
    partnershipId: number;
    /**
     * 
     * @type {Array<OrderGroupToPiece>}
     * @memberof OrderGroup
     */
    pieces: Array<OrderGroupToPiece>;
    /**
     * 
     * @type {Array<Order>}
     * @memberof OrderGroup
     */
    orders: Array<Order>;
    /**
     * 
     * @type {Owner}
     * @memberof OrderGroup
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof OrderGroup
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderGroup
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderGroup
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface OrderGroupDTO
 */
export interface OrderGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderGroupDTO
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof OrderGroupDTO
     */
    label: string;
}
/**
 * 
 * @export
 * @interface OrderGroupToPiece
 */
export interface OrderGroupToPiece {
    /**
     * 
     * @type {number}
     * @memberof OrderGroupToPiece
     */
    id: number;
    /**
     * 
     * @type {OrderGroup}
     * @memberof OrderGroupToPiece
     */
    orderGroup: OrderGroup;
    /**
     * 
     * @type {number}
     * @memberof OrderGroupToPiece
     */
    orderGroupId: number;
    /**
     * 
     * @type {BuildingPiece}
     * @memberof OrderGroupToPiece
     */
    piece: BuildingPiece;
    /**
     * 
     * @type {number}
     * @memberof OrderGroupToPiece
     */
    pieceId: number;
}
/**
 * 
 * @export
 * @interface Owner
 */
export interface Owner {
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    domain: string;
    /**
     * 
     * @type {User}
     * @memberof Owner
     */
    owner: User;
    /**
     * 
     * @type {Array<User>}
     * @memberof Owner
     */
    members: Array<User>;
}
/**
 * 
 * @export
 * @interface Partnership
 */
export interface Partnership {
    /**
     * 
     * @type {number}
     * @memberof Partnership
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Partnership
     */
    desc: string;
    /**
     * 
     * @type {string}
     * @memberof Partnership
     */
    alias: string;
    /**
     * 
     * @type {boolean}
     * @memberof Partnership
     */
    isReadonly: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Partnership
     */
    isDefault: boolean;
    /**
     * 
     * @type {Building}
     * @memberof Partnership
     */
    building: Building;
    /**
     * 
     * @type {number}
     * @memberof Partnership
     */
    buildingId: number;
    /**
     * 
     * @type {Array<OrderGroup>}
     * @memberof Partnership
     */
    orderGroups: Array<OrderGroup>;
    /**
     * 
     * @type {Owner}
     * @memberof Partnership
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof Partnership
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Partnership
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Partnership
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface PartnershipOrderGroupDTO
 */
export interface PartnershipOrderGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof PartnershipOrderGroupDTO
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof PartnershipOrderGroupDTO
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof PartnershipOrderGroupDTO
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PartnershipOrderGroupDTO
     */
    partnershipId: number;
    /**
     * 
     * @type {Array<PartnershipPieceDTO>}
     * @memberof PartnershipOrderGroupDTO
     */
    pieces: Array<PartnershipPieceDTO>;
    /**
     * 
     * @type {Array<ReadOrderDTO>}
     * @memberof PartnershipOrderGroupDTO
     */
    orders: Array<ReadOrderDTO>;
}
/**
 * 
 * @export
 * @interface PartnershipPieceDTO
 */
export interface PartnershipPieceDTO {
    /**
     * 
     * @type {number}
     * @memberof PartnershipPieceDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PartnershipPieceDTO
     */
    name: string;
}
/**
 * 
 * @export
 * @interface PatchWorkDTO
 */
export interface PatchWorkDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchWorkDTO
     */
    docIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PatchWorkDTO
     */
    id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchWorkDTO
     */
    materialIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PatchWorkDTO
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof PatchWorkDTO
     */
    finishDate: string;
    /**
     * 
     * @type {string}
     * @memberof PatchWorkDTO
     */
    land: string;
    /**
     * 
     * @type {string}
     * @memberof PatchWorkDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PatchWorkDTO
     */
    next: string;
    /**
     * 
     * @type {string}
     * @memberof PatchWorkDTO
     */
    projectDoc: string;
    /**
     * 
     * @type {string}
     * @memberof PatchWorkDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof PatchWorkDTO
     */
    reportDate: string;
    /**
     * 
     * @type {string}
     * @memberof PatchWorkDTO
     */
    standard: string;
    /**
     * 
     * @type {string}
     * @memberof PatchWorkDTO
     */
    startDate: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchWorkDTO
     */
    tags: Array<string>;
}
/**
 * 
 * @export
 * @interface PatchWorksDto
 */
export interface PatchWorksDto {
    /**
     * 
     * @type {Array<PatchWorkDTO>}
     * @memberof PatchWorksDto
     */
    works: Array<PatchWorkDTO>;
}
/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    position: string;
}
/**
 * 
 * @export
 * @interface PinMaterialDTO
 */
export interface PinMaterialDTO {
    /**
     * 
     * @type {number}
     * @memberof PinMaterialDTO
     */
    materialId: number;
    /**
     * 
     * @type {boolean}
     * @memberof PinMaterialDTO
     */
    value: boolean;
}
/**
 * 
 * @export
 * @interface ReadAllBuildingsDTO
 */
export interface ReadAllBuildingsDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadAllBuildingsDTO
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ReadAllBuildingsDTO
     */
    pieces: number;
    /**
     * 
     * @type {string}
     * @memberof ReadAllBuildingsDTO
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ReadAllBuildingsDTO
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ReadBuildingDTO
 */
export interface ReadBuildingDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadBuildingDTO
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ReadBuildingDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReadBuildingDTO
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface ReadBuildingNamesDTO
 */
export interface ReadBuildingNamesDTO {
    /**
     * 
     * @type {Array<BuildingNameDTO>}
     * @memberof ReadBuildingNamesDTO
     */
    buildings: Array<BuildingNameDTO>;
}
/**
 * 
 * @export
 * @interface ReadBuildingPartnershipsDTO
 */
export interface ReadBuildingPartnershipsDTO {
    /**
     * 
     * @type {Array<BuildingPartnershipDTO>}
     * @memberof ReadBuildingPartnershipsDTO
     */
    partnerships: Array<BuildingPartnershipDTO>;
}
/**
 * 
 * @export
 * @interface ReadBuildingPieceDTO
 */
export interface ReadBuildingPieceDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadBuildingPieceDTO
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ReadBuildingPieceDTO
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ReadBuildingPieceDTO
     */
    parentId: number;
    /**
     * 
     * @type {string}
     * @memberof ReadBuildingPieceDTO
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ReadBuildingPieceDTO
     */
    cipher: string;
    /**
     * 
     * @type {string}
     * @memberof ReadBuildingPieceDTO
     */
    standard: string;
    /**
     * 
     * @type {string}
     * @memberof ReadBuildingPieceDTO
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof ReadBuildingPieceDTO
     */
    signatoryPosition: string;
    /**
     * 
     * @type {string}
     * @memberof ReadBuildingPieceDTO
     */
    signatoryName: string;
}
/**
 * 
 * @export
 * @interface ReadCompanyDTO
 */
export interface ReadCompanyDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadCompanyDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReadCompanyDTO
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ReadCompanyDTO
     */
    fax: string;
    /**
     * 
     * @type {string}
     * @memberof ReadCompanyDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadCompanyDTO
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ReadCompanyDTO
     */
    psrn: string;
    /**
     * 
     * @type {string}
     * @memberof ReadCompanyDTO
     */
    sro: string;
    /**
     * 
     * @type {string}
     * @memberof ReadCompanyDTO
     */
    sroPsrn: string;
    /**
     * 
     * @type {string}
     * @memberof ReadCompanyDTO
     */
    sroTin: string;
    /**
     * 
     * @type {string}
     * @memberof ReadCompanyDTO
     */
    tin: string;
}
/**
 * 
 * @export
 * @interface ReadCompanyInUseDTO
 */
export interface ReadCompanyInUseDTO {
    /**
     * 
     * @type {Array<ReadCompanyInUsePart>}
     * @memberof ReadCompanyInUseDTO
     */
    buildings: Array<ReadCompanyInUsePart>;
}
/**
 * 
 * @export
 * @interface ReadCompanyInUsePart
 */
export interface ReadCompanyInUsePart {
    /**
     * 
     * @type {Array<Identity>}
     * @memberof ReadCompanyInUsePart
     */
    pieces: Array<Identity>;
    /**
     * 
     * @type {string}
     * @memberof ReadCompanyInUsePart
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ReadCompanyInUsePart
     */
    id: number;
}
/**
 * 
 * @export
 * @interface ReadCurrentSubscriptionDTO
 */
export interface ReadCurrentSubscriptionDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadCurrentSubscriptionDTO
     */
    expiresAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReadCurrentSubscriptionDTO
     */
    isExceeded: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReadCurrentSubscriptionDTO
     */
    usersAllowed: number;
}
/**
 * 
 * @export
 * @interface ReadGroupDto
 */
export interface ReadGroupDto {
    /**
     * 
     * @type {string}
     * @memberof ReadGroupDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadGroupDto
     */
    domain: string;
}
/**
 * 
 * @export
 * @interface ReadGroupMembersDTO
 */
export interface ReadGroupMembersDTO {
    /**
     * 
     * @type {Array<GroupMemberDTO>}
     * @memberof ReadGroupMembersDTO
     */
    members: Array<GroupMemberDTO>;
}
/**
 * 
 * @export
 * @interface ReadLabDTO
 */
export interface ReadLabDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadLabDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof ReadLabDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadLabDTO
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof ReadLabDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReadLabDTO
     */
    pdf: string;
}
/**
 * 
 * @export
 * @interface ReadMaterialDTO
 */
export interface ReadMaterialDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadMaterialDTO
     */
    id: number;
    /**
     * 
     * @type {Array<ReadMaterialDocDTO>}
     * @memberof ReadMaterialDTO
     */
    docs: Array<ReadMaterialDocDTO>;
    /**
     * 
     * @type {string}
     * @memberof ReadMaterialDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadMaterialDTO
     */
    manufacturer: string;
}
/**
 * 
 * @export
 * @interface ReadMaterialDocDTO
 */
export interface ReadMaterialDocDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadMaterialDocDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReadMaterialDocDTO
     */
    pdf: string;
    /**
     * 
     * @type {string}
     * @memberof ReadMaterialDocDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadMaterialDocDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof ReadMaterialDocDTO
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof ReadMaterialDocDTO
     */
    to: string;
}
/**
 * 
 * @export
 * @interface ReadOrderDTO
 */
export interface ReadOrderDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadOrderDTO
     */
    alias: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOrderDTO
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOrderDTO
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOrderDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOrderDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOrderDTO
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOrderDTO
     */
    position: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOrderDTO
     */
    id: number;
}
/**
 * 
 * @export
 * @interface ReadPartnershipDTO
 */
export interface ReadPartnershipDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadPartnershipDTO
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ReadPartnershipDTO
     */
    buildingId: number;
    /**
     * 
     * @type {string}
     * @memberof ReadPartnershipDTO
     */
    desc: string;
    /**
     * 
     * @type {string}
     * @memberof ReadPartnershipDTO
     */
    alias: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReadPartnershipDTO
     */
    isReadonly: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReadPartnershipDTO
     */
    isDefault: boolean;
    /**
     * 
     * @type {Array<PartnershipOrderGroupDTO>}
     * @memberof ReadPartnershipDTO
     */
    orderGroups: Array<PartnershipOrderGroupDTO>;
}
/**
 * 
 * @export
 * @interface ReadPaymentDTO
 */
export interface ReadPaymentDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadPaymentDTO
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ReadPaymentDTO
     */
    money: number;
    /**
     * 
     * @type {string}
     * @memberof ReadPaymentDTO
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ReadPaymentDTO
     */
    completedAt: string;
}
/**
 * 
 * @export
 * @interface ReadPdfDTO
 */
export interface ReadPdfDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadPdfDTO
     */
    url: string;
}
/**
 * 
 * @export
 * @interface ReadPreferencesDTO
 */
export interface ReadPreferencesDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReadPreferencesDTO
     */
    pinnedMaterials: Array<number>;
}
/**
 * 
 * @export
 * @interface ReadReportDTO
 */
export interface ReadReportDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadReportDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReadReportDTO
     */
    state: string;
}
/**
 * 
 * @export
 * @interface ReadSchemaDTO
 */
export interface ReadSchemaDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadSchemaDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReadSchemaDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof ReadSchemaDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadSchemaDTO
     */
    schemaName: string;
    /**
     * 
     * @type {string}
     * @memberof ReadSchemaDTO
     */
    pdf: string;
    /**
     * 
     * @type {ReadStampDTO}
     * @memberof ReadSchemaDTO
     */
    stamp: ReadStampDTO;
}
/**
 * 
 * @export
 * @interface ReadStampDTO
 */
export interface ReadStampDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadStampDTO
     */
    id: number;
    /**
     * 
     * @type {Array<Person>}
     * @memberof ReadStampDTO
     */
    people: Array<Person>;
}
/**
 * 
 * @export
 * @interface ReadStampInUse
 */
export interface ReadStampInUse {
    /**
     * 
     * @type {Array<WorkUsedInStamp>}
     * @memberof ReadStampInUse
     */
    works: Array<WorkUsedInStamp>;
}
/**
 * 
 * @export
 * @interface ReadSubscriptionsDTO
 */
export interface ReadSubscriptionsDTO {
    /**
     * 
     * @type {Array<SubscriptionDTO>}
     * @memberof ReadSubscriptionsDTO
     */
    subscriptions: Array<SubscriptionDTO>;
}
/**
 * 
 * @export
 * @interface ReadWorkDTO
 */
export interface ReadWorkDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadWorkDTO
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ReadWorkDTO
     */
    buildingPieceId: number;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkDTO
     */
    land: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkDTO
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkDTO
     */
    finishDate: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReadWorkDTO
     */
    docIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkDTO
     */
    standard: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkDTO
     */
    projectDoc: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkDTO
     */
    reportDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkDTO
     */
    next: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadWorkDTO
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkDTO
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface ReadWorkItemDTO
 */
export interface ReadWorkItemDTO {
    /**
     * 
     * @type {number}
     * @memberof ReadWorkItemDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkItemDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkItemDTO
     */
    land: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkItemDTO
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkItemDTO
     */
    finishDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkItemDTO
     */
    standard: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkItemDTO
     */
    projectDoc: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkItemDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkItemDTO
     */
    reportDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkItemDTO
     */
    next: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadWorkItemDTO
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReadWorkItemDTO
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface ReattachPieceDTO
 */
export interface ReattachPieceDTO {
    /**
     * 
     * @type {number}
     * @memberof ReattachPieceDTO
     */
    pieceId: number;
    /**
     * 
     * @type {number}
     * @memberof ReattachPieceDTO
     */
    prevOrderGroupId: number;
    /**
     * 
     * @type {number}
     * @memberof ReattachPieceDTO
     */
    nextOrderGroupId: number;
}
/**
 * 
 * @export
 * @interface RegisterDTO
 */
export interface RegisterDTO {
    /**
     * 
     * @type {string}
     * @memberof RegisterDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDTO
     */
    password: string;
}
/**
 * 
 * @export
 * @interface RegisterResponseDTO
 */
export interface RegisterResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RegisterResponseDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponseDTO
     */
    accessToken: string;
}
/**
 * 
 * @export
 * @interface ReportOptionsDTO
 */
export interface ReportOptionsDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportOptionsDTO
     */
    works: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ReportOptionsDTO
     */
    format: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportOptionsDTO
     */
    shouldPasteApprove: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportOptionsDTO
     */
    shouldNumberApplications: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportOptionsDTO
     */
    actTemplate: string;
}
/**
 * 
 * @export
 * @interface Schema
 */
export interface Schema {
    /**
     * 
     * @type {number}
     * @memberof Schema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Schema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Schema
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof Schema
     */
    schemaName: string;
    /**
     * 
     * @type {Stamp}
     * @memberof Schema
     */
    stamp: Stamp;
    /**
     * 
     * @type {Work}
     * @memberof Schema
     */
    work: Work;
    /**
     * 
     * @type {SchemaPdf}
     * @memberof Schema
     */
    pdf?: SchemaPdf;
    /**
     * 
     * @type {Owner}
     * @memberof Schema
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof Schema
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Schema
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Schema
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface SchemaDTO
 */
export interface SchemaDTO {
    /**
     * 
     * @type {string}
     * @memberof SchemaDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaDTO
     */
    schemaName: string;
    /**
     * 
     * @type {number}
     * @memberof SchemaDTO
     */
    stampId: number;
}
/**
 * 
 * @export
 * @interface SchemaPdf
 */
export interface SchemaPdf {
    /**
     * 
     * @type {number}
     * @memberof SchemaPdf
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SchemaPdf
     */
    name: string;
    /**
     * 
     * @type {Schema}
     * @memberof SchemaPdf
     */
    schema: Schema;
    /**
     * 
     * @type {ModelStatic}
     * @memberof SchemaPdf
     */
    file: ModelStatic;
    /**
     * 
     * @type {number}
     * @memberof SchemaPdf
     */
    fileId: number;
    /**
     * 
     * @type {Owner}
     * @memberof SchemaPdf
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof SchemaPdf
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SchemaPdf
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SchemaPdf
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface Stamp
 */
export interface Stamp {
    /**
     * 
     * @type {number}
     * @memberof Stamp
     */
    id: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof Stamp
     */
    people: Array<any>;
    /**
     * 
     * @type {BuildingPiece}
     * @memberof Stamp
     */
    piece: BuildingPiece;
    /**
     * 
     * @type {Owner}
     * @memberof Stamp
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof Stamp
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Stamp
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Stamp
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface StampDTO
 */
export interface StampDTO {
    /**
     * 
     * @type {Array<Person>}
     * @memberof StampDTO
     */
    people: Array<Person>;
}
/**
 * 
 * @export
 * @interface SubscriptionDTO
 */
export interface SubscriptionDTO {
    /**
     * 
     * @type {ReadPaymentDTO}
     * @memberof SubscriptionDTO
     */
    payment: ReadPaymentDTO;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDTO
     */
    expiresAt: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDTO
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDTO
     */
    isActivated: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDTO
     */
    startsAt: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDTO
     */
    users: number;
}
/**
 * 
 * @export
 * @interface UpdateGroupDTO
 */
export interface UpdateGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateGroupDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGroupDTO
     */
    domain: string;
}
/**
 * 
 * @export
 * @interface UpdateWorkDocsDTO
 */
export interface UpdateWorkDocsDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateWorkDocsDTO
     */
    docIds: Array<number>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password: string;
    /**
     * 
     * @type {Confirm}
     * @memberof User
     */
    confirm: Confirm;
    /**
     * 
     * @type {Group}
     * @memberof User
     */
    memberOf: Group;
    /**
     * 
     * @type {Group}
     * @memberof User
     */
    ownerOf: Group;
}
/**
 * 
 * @export
 * @interface WhoamiDTO
 */
export interface WhoamiDTO {
    /**
     * 
     * @type {string}
     * @memberof WhoamiDTO
     */
    awaitingConfirmation: string;
    /**
     * 
     * @type {number}
     * @memberof WhoamiDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WhoamiDTO
     */
    username: string;
    /**
     * 
     * @type {number}
     * @memberof WhoamiDTO
     */
    ownerOf: number;
    /**
     * 
     * @type {number}
     * @memberof WhoamiDTO
     */
    memberOf: number;
}
/**
 * 
 * @export
 * @interface Work
 */
export interface Work {
    /**
     * 
     * @type {number}
     * @memberof Work
     */
    id: number;
    /**
     * 
     * @type {BuildingPiece}
     * @memberof Work
     */
    buildingPiece: BuildingPiece;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    land: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    finishDate: string;
    /**
     * 
     * @type {Array<MaterialDoc>}
     * @memberof Work
     */
    docs: Array<MaterialDoc>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Work
     */
    docIds: Array<number>;
    /**
     * 
     * @type {Array<Schema>}
     * @memberof Work
     */
    schemas: Array<Schema>;
    /**
     * 
     * @type {Array<Lab>}
     * @memberof Work
     */
    labs: Array<Lab>;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    standard: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    projectDoc: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    reportDate: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    next: string;
    /**
     * 
     * @type {string}
     * @memberof Work
     */
    comment: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Work
     */
    tags: Array<string>;
    /**
     * 
     * @type {Owner}
     * @memberof Work
     */
    owner: Owner;
    /**
     * 
     * @type {Date}
     * @memberof Work
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Work
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Work
     */
    deletedAt: Date;
}
/**
 * 
 * @export
 * @interface WorkDTO
 */
export interface WorkDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkDTO
     */
    docIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    finishDate: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    land: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    next: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    projectDoc: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    index: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    reportDate: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    standard: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    startDate: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkDTO
     */
    tags: Array<string>;
}
/**
 * 
 * @export
 * @interface WorkUsedInStamp
 */
export interface WorkUsedInStamp {
    /**
     * 
     * @type {string}
     * @memberof WorkUsedInStamp
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WorkUsedInStamp
     */
    id: number;
}
/**
 * 
 * @export
 * @interface YooNotificationDTO
 */
export interface YooNotificationDTO {
}
/**
 * ActApi - fetch parameter creator
 * @export
 */
export const ActApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(pieceId: number, options: any = {}): FetchArgs {
            // verify required parameter 'pieceId' is not null or undefined
            if (pieceId === null || pieceId === undefined) {
                throw new RequiredError('pieceId','Required parameter pieceId was null or undefined when calling deleteReport.');
            }
            const localVarPath = `/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pieceId !== undefined) {
                localVarQueryParameter['pieceId'] = pieceId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadReport.');
            }
            const localVarPath = `/report/{id}/zip`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeepPieceData(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readDeepPieceData.');
            }
            const localVarPath = `/report/deepPiece/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReport(pieceId: number, options: any = {}): FetchArgs {
            // verify required parameter 'pieceId' is not null or undefined
            if (pieceId === null || pieceId === undefined) {
                throw new RequiredError('pieceId','Required parameter pieceId was null or undefined when calling readReport.');
            }
            const localVarPath = `/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pieceId !== undefined) {
                localVarQueryParameter['pieceId'] = pieceId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActApi - functional programming interface
 * @export
 */
export const ActApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(pieceId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ActApiFetchParamCreator(configuration).deleteReport(pieceId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ActApiFetchParamCreator(configuration).downloadReport(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeepPieceData(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BuildingPiece> {
            const localVarFetchArgs = ActApiFetchParamCreator(configuration).readDeepPieceData(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReport(pieceId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadReportDTO> {
            const localVarFetchArgs = ActApiFetchParamCreator(configuration).readReport(pieceId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ActApi - factory interface
 * @export
 */
export const ActApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(pieceId: number, options?: any) {
            return ActApiFp(configuration).deleteReport(pieceId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(id: number, options?: any) {
            return ActApiFp(configuration).downloadReport(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeepPieceData(id: number, options?: any) {
            return ActApiFp(configuration).readDeepPieceData(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReport(pieceId: number, options?: any) {
            return ActApiFp(configuration).readReport(pieceId, options)(fetch, basePath);
        },
    };
};

/**
 * ActApi - object-oriented interface
 * @export
 * @class ActApi
 * @extends {BaseAPI}
 */
export class ActApi extends BaseAPI {
    /**
     * 
     * @param {number} pieceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActApi
     */
    public deleteReport(pieceId: number, options?: any) {
        return ActApiFp(this.configuration).deleteReport(pieceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActApi
     */
    public downloadReport(id: number, options?: any) {
        return ActApiFp(this.configuration).downloadReport(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActApi
     */
    public readDeepPieceData(id: number, options?: any) {
        return ActApiFp(this.configuration).readDeepPieceData(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} pieceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActApi
     */
    public readReport(pieceId: number, options?: any) {
        return ActApiFp(this.configuration).readReport(pieceId, options)(this.fetch, this.basePath);
    }

}
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(options: any = {}): FetchArgs {
            const localVarPath = `/auth/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options: any = {}): FetchArgs {
            const localVarPath = `/auth/ping`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoami(options: any = {}): FetchArgs {
            const localVarPath = `/auth/whoami`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoginDTO> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).login(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).ping(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoami(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WhoamiDTO> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).whoami(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(options?: any) {
            return AuthApiFp(configuration).login(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any) {
            return AuthApiFp(configuration).ping(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoami(options?: any) {
            return AuthApiFp(configuration).whoami(options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(options?: any) {
        return AuthApiFp(this.configuration).login(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public ping(options?: any) {
        return AuthApiFp(this.configuration).ping(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public whoami(options?: any) {
        return AuthApiFp(this.configuration).whoami(options)(this.fetch, this.basePath);
    }

}
/**
 * BuildingApi - fetch parameter creator
 * @export
 */
export const BuildingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuilding(body: BuildingDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createBuilding.');
            }
            const localVarPath = `/building`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BuildingDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingPiece(body: BuildingPieceDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createBuildingPiece.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createBuildingPiece.');
            }
            const localVarPath = `/building/{id}/pieces`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BuildingPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoBuilding(options: any = {}): FetchArgs {
            const localVarPath = `/building/demo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteBuilding.');
            }
            const localVarPath = `/building/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuilding(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readBuilding.');
            }
            const localVarPath = `/building/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingNames(options: any = {}): FetchArgs {
            const localVarPath = `/building/names`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPartnerships(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readBuildingPartnerships.');
            }
            const localVarPath = `/building/{id}/partnerships`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPieces(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readBuildingPieces.');
            }
            const localVarPath = `/building/{id}/pieces`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildings(options: any = {}): FetchArgs {
            const localVarPath = `/building`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuilding(body: BuildingDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateBuilding.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateBuilding.');
            }
            const localVarPath = `/building/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BuildingDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingApi - functional programming interface
 * @export
 */
export const BuildingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuilding(body: BuildingDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).createBuilding(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingPiece(body: BuildingPieceDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).createBuildingPiece(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoBuilding(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).createDemoBuilding(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).deleteBuilding(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuilding(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadBuildingDTO> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).readBuilding(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingNames(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadBuildingNamesDTO> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).readBuildingNames(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPartnerships(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadBuildingPartnershipsDTO> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).readBuildingPartnerships(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPieces(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadBuildingPieceDTO>> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).readBuildingPieces(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildings(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadAllBuildingsDTO>> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).readBuildings(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuilding(body: BuildingDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = BuildingApiFetchParamCreator(configuration).updateBuilding(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BuildingApi - factory interface
 * @export
 */
export const BuildingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuilding(body: BuildingDTO, options?: any) {
            return BuildingApiFp(configuration).createBuilding(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingPiece(body: BuildingPieceDTO, id: number, options?: any) {
            return BuildingApiFp(configuration).createBuildingPiece(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoBuilding(options?: any) {
            return BuildingApiFp(configuration).createDemoBuilding(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(id: number, options?: any) {
            return BuildingApiFp(configuration).deleteBuilding(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuilding(id: number, options?: any) {
            return BuildingApiFp(configuration).readBuilding(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingNames(options?: any) {
            return BuildingApiFp(configuration).readBuildingNames(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPartnerships(id: number, options?: any) {
            return BuildingApiFp(configuration).readBuildingPartnerships(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPieces(id: number, options?: any) {
            return BuildingApiFp(configuration).readBuildingPieces(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildings(options?: any) {
            return BuildingApiFp(configuration).readBuildings(options)(fetch, basePath);
        },
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuilding(body: BuildingDTO, id: number, options?: any) {
            return BuildingApiFp(configuration).updateBuilding(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * BuildingApi - object-oriented interface
 * @export
 * @class BuildingApi
 * @extends {BaseAPI}
 */
export class BuildingApi extends BaseAPI {
    /**
     * 
     * @param {BuildingDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public createBuilding(body: BuildingDTO, options?: any) {
        return BuildingApiFp(this.configuration).createBuilding(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BuildingPieceDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public createBuildingPiece(body: BuildingPieceDTO, id: number, options?: any) {
        return BuildingApiFp(this.configuration).createBuildingPiece(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public createDemoBuilding(options?: any) {
        return BuildingApiFp(this.configuration).createDemoBuilding(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public deleteBuilding(id: number, options?: any) {
        return BuildingApiFp(this.configuration).deleteBuilding(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public readBuilding(id: number, options?: any) {
        return BuildingApiFp(this.configuration).readBuilding(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public readBuildingNames(options?: any) {
        return BuildingApiFp(this.configuration).readBuildingNames(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public readBuildingPartnerships(id: number, options?: any) {
        return BuildingApiFp(this.configuration).readBuildingPartnerships(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public readBuildingPieces(id: number, options?: any) {
        return BuildingApiFp(this.configuration).readBuildingPieces(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public readBuildings(options?: any) {
        return BuildingApiFp(this.configuration).readBuildings(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BuildingDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public updateBuilding(body: BuildingDTO, id: number, options?: any) {
        return BuildingApiFp(this.configuration).updateBuilding(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * CompanyApi - fetch parameter creator
 * @export
 */
export const CompanyApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(body: CompanyDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCompany.');
            }
            const localVarPath = `/company`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompanyDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCompany.');
            }
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanies(options: any = {}): FetchArgs {
            const localVarPath = `/company`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompany(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readCompany.');
            }
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyInUse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readCompanyInUse.');
            }
            const localVarPath = `/company/{id}/in-use`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(body: CompanyDTO, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateCompany.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCompany.');
            }
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompanyDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(body: CompanyDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompanyDTO> {
            const localVarFetchArgs = CompanyApiFetchParamCreator(configuration).createCompany(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CompanyApiFetchParamCreator(configuration).deleteCompany(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanies(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadCompanyDTO>> {
            const localVarFetchArgs = CompanyApiFetchParamCreator(configuration).readCompanies(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompany(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadCompanyDTO> {
            const localVarFetchArgs = CompanyApiFetchParamCreator(configuration).readCompany(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyInUse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadCompanyInUseDTO> {
            const localVarFetchArgs = CompanyApiFetchParamCreator(configuration).readCompanyInUse(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(body: CompanyDTO, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = CompanyApiFetchParamCreator(configuration).updateCompany(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(body: CompanyDTO, options?: any) {
            return CompanyApiFp(configuration).createCompany(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(id: string, options?: any) {
            return CompanyApiFp(configuration).deleteCompany(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanies(options?: any) {
            return CompanyApiFp(configuration).readCompanies(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompany(id: number, options?: any) {
            return CompanyApiFp(configuration).readCompany(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyInUse(id: number, options?: any) {
            return CompanyApiFp(configuration).readCompanyInUse(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(body: CompanyDTO, id: string, options?: any) {
            return CompanyApiFp(configuration).updateCompany(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {CompanyDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createCompany(body: CompanyDTO, options?: any) {
        return CompanyApiFp(this.configuration).createCompany(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public deleteCompany(id: string, options?: any) {
        return CompanyApiFp(this.configuration).deleteCompany(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public readCompanies(options?: any) {
        return CompanyApiFp(this.configuration).readCompanies(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public readCompany(id: number, options?: any) {
        return CompanyApiFp(this.configuration).readCompany(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public readCompanyInUse(id: number, options?: any) {
        return CompanyApiFp(this.configuration).readCompanyInUse(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CompanyDTO} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompany(body: CompanyDTO, id: string, options?: any) {
        return CompanyApiFp(this.configuration).updateCompany(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * ConfirmApi - fetch parameter creator
 * @export
 */
export const ConfirmApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationId(options: any = {}): FetchArgs {
            const localVarPath = `/confirm/resend`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfirmApi - functional programming interface
 * @export
 */
export const ConfirmApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationId(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = ConfirmApiFetchParamCreator(configuration).resendConfirmationId(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ConfirmApi - factory interface
 * @export
 */
export const ConfirmApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationId(options?: any) {
            return ConfirmApiFp(configuration).resendConfirmationId(options)(fetch, basePath);
        },
    };
};

/**
 * ConfirmApi - object-oriented interface
 * @export
 * @class ConfirmApi
 * @extends {BaseAPI}
 */
export class ConfirmApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfirmApi
     */
    public resendConfirmationId(options?: any) {
        return ConfirmApiFp(this.configuration).resendConfirmationId(options)(this.fetch, this.basePath);
    }

}
/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHelloWorld(options: any = {}): FetchArgs {
            const localVarPath = `/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {number} confirmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmControllerConfirmEmail(code: string, confirmId: number, options: any = {}): FetchArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling confirmControllerConfirmEmail.');
            }
            // verify required parameter 'confirmId' is not null or undefined
            if (confirmId === null || confirmId === undefined) {
                throw new RequiredError('confirmId','Required parameter confirmId was null or undefined when calling confirmControllerConfirmEmail.');
            }
            const localVarPath = `/confirm`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (confirmId !== undefined) {
                localVarQueryParameter['confirmId'] = confirmId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerConfirm(id: number, code: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling paymentControllerConfirm.');
            }
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling paymentControllerConfirm.');
            }
            const localVarPath = `/payment/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YooNotificationDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerNotificate(body: YooNotificationDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling paymentControllerNotificate.');
            }
            const localVarPath = `/payment/notificate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"YooNotificationDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerReadAll(options: any = {}): FetchArgs {
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHelloWorld(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).appControllerHelloWorld(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {number} confirmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmControllerConfirmEmail(code: string, confirmId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).confirmControllerConfirmEmail(code, confirmId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerConfirm(id: number, code: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConfirmPaymentResponseDTO> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).paymentControllerConfirm(id, code, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {YooNotificationDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerNotificate(body: YooNotificationDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).paymentControllerNotificate(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerReadAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<User>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).userControllerReadAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHelloWorld(options?: any) {
            return DefaultApiFp(configuration).appControllerHelloWorld(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {number} confirmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmControllerConfirmEmail(code: string, confirmId: number, options?: any) {
            return DefaultApiFp(configuration).confirmControllerConfirmEmail(code, confirmId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerConfirm(id: number, code: string, options?: any) {
            return DefaultApiFp(configuration).paymentControllerConfirm(id, code, options)(fetch, basePath);
        },
        /**
         * 
         * @param {YooNotificationDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerNotificate(body: YooNotificationDTO, options?: any) {
            return DefaultApiFp(configuration).paymentControllerNotificate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerReadAll(options?: any) {
            return DefaultApiFp(configuration).userControllerReadAll(options)(fetch, basePath);
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerHelloWorld(options?: any) {
        return DefaultApiFp(this.configuration).appControllerHelloWorld(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} code 
     * @param {number} confirmId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public confirmControllerConfirmEmail(code: string, confirmId: number, options?: any) {
        return DefaultApiFp(this.configuration).confirmControllerConfirmEmail(code, confirmId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerConfirm(id: number, code: string, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerConfirm(id, code, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {YooNotificationDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerNotificate(body: YooNotificationDTO, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerNotificate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerReadAll(options?: any) {
        return DefaultApiFp(this.configuration).userControllerReadAll(options)(this.fetch, this.basePath);
    }

}
/**
 * GroupApi - fetch parameter creator
 * @export
 */
export const GroupApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupUser(body: AddUserDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createGroupUser.');
            }
            const localVarPath = `/group/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddUserDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/group/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroup(options: any = {}): FetchArgs {
            const localVarPath = `/group`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupMembers(options: any = {}): FetchArgs {
            const localVarPath = `/group/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGroupDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(body: UpdateGroupDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateGroup.');
            }
            const localVarPath = `/group`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateGroupDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupUser(body: ChangePasswordDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateGroupUser.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateGroupUser.');
            }
            const localVarPath = `/group/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangePasswordDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupUser(body: AddUserDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).createGroupUser(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).deleteUser(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroup(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadGroupDto> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).readGroup(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupMembers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadGroupMembersDTO> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).readGroupMembers(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateGroupDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(body: UpdateGroupDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).updateGroup(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ChangePasswordDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupUser(body: ChangePasswordDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = GroupApiFetchParamCreator(configuration).updateGroupUser(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AddUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupUser(body: AddUserDto, options?: any) {
            return GroupApiFp(configuration).createGroupUser(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: number, options?: any) {
            return GroupApiFp(configuration).deleteUser(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroup(options?: any) {
            return GroupApiFp(configuration).readGroup(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupMembers(options?: any) {
            return GroupApiFp(configuration).readGroupMembers(options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateGroupDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(body: UpdateGroupDTO, options?: any) {
            return GroupApiFp(configuration).updateGroup(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ChangePasswordDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupUser(body: ChangePasswordDTO, id: number, options?: any) {
            return GroupApiFp(configuration).updateGroupUser(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @param {AddUserDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public createGroupUser(body: AddUserDto, options?: any) {
        return GroupApiFp(this.configuration).createGroupUser(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public deleteUser(id: number, options?: any) {
        return GroupApiFp(this.configuration).deleteUser(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public readGroup(options?: any) {
        return GroupApiFp(this.configuration).readGroup(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public readGroupMembers(options?: any) {
        return GroupApiFp(this.configuration).readGroupMembers(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UpdateGroupDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public updateGroup(body: UpdateGroupDTO, options?: any) {
        return GroupApiFp(this.configuration).updateGroup(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ChangePasswordDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public updateGroupUser(body: ChangePasswordDTO, id: number, options?: any) {
        return GroupApiFp(this.configuration).updateGroupUser(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * LabApi - fetch parameter creator
 * @export
 */
export const LabApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLab(body: LabDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLab.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createLab.');
            }
            const localVarPath = `/work/{id}/labs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LabDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLab(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLab.');
            }
            const localVarPath = `/lab/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLabPdf.');
            }
            const localVarPath = `/lab/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readLabPdf.');
            }
            const localVarPath = `/lab/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabs(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readLabs.');
            }
            const localVarPath = `/work/{id}/labs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLab(body: LabDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateLab.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateLab.');
            }
            const localVarPath = `/lab/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LabDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLabPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadLabPdf.');
            }
            const localVarPath = `/lab/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LabApi - functional programming interface
 * @export
 */
export const LabApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLab(body: LabDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = LabApiFetchParamCreator(configuration).createLab(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLab(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LabApiFetchParamCreator(configuration).deleteLab(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LabApiFetchParamCreator(configuration).deleteLabPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPdfDTO> {
            const localVarFetchArgs = LabApiFetchParamCreator(configuration).readLabPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabs(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadLabDTO>> {
            const localVarFetchArgs = LabApiFetchParamCreator(configuration).readLabs(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLab(body: LabDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = LabApiFetchParamCreator(configuration).updateLab(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLabPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = LabApiFetchParamCreator(configuration).uploadLabPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LabApi - factory interface
 * @export
 */
export const LabApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLab(body: LabDTO, id: number, options?: any) {
            return LabApiFp(configuration).createLab(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLab(id: number, options?: any) {
            return LabApiFp(configuration).deleteLab(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabPdf(id: number, options?: any) {
            return LabApiFp(configuration).deleteLabPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabPdf(id: number, options?: any) {
            return LabApiFp(configuration).readLabPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabs(id: number, options?: any) {
            return LabApiFp(configuration).readLabs(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLab(body: LabDTO, id: number, options?: any) {
            return LabApiFp(configuration).updateLab(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLabPdf(id: number, options?: any) {
            return LabApiFp(configuration).uploadLabPdf(id, options)(fetch, basePath);
        },
    };
};

/**
 * LabApi - object-oriented interface
 * @export
 * @class LabApi
 * @extends {BaseAPI}
 */
export class LabApi extends BaseAPI {
    /**
     * 
     * @param {LabDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public createLab(body: LabDTO, id: number, options?: any) {
        return LabApiFp(this.configuration).createLab(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public deleteLab(id: number, options?: any) {
        return LabApiFp(this.configuration).deleteLab(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public deleteLabPdf(id: number, options?: any) {
        return LabApiFp(this.configuration).deleteLabPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public readLabPdf(id: number, options?: any) {
        return LabApiFp(this.configuration).readLabPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public readLabs(id: number, options?: any) {
        return LabApiFp(this.configuration).readLabs(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {LabDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public updateLab(body: LabDTO, id: number, options?: any) {
        return LabApiFp(this.configuration).updateLab(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public uploadLabPdf(id: number, options?: any) {
        return LabApiFp(this.configuration).uploadLabPdf(id, options)(this.fetch, this.basePath);
    }

}
/**
 * MaterialApi - fetch parameter creator
 * @export
 */
export const MaterialApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterial(body: MaterialDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createMaterial.');
            }
            const localVarPath = `/material`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MaterialDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterialDoc(body: MaterialDocDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createMaterialDoc.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createMaterialDoc.');
            }
            const localVarPath = `/material/{id}/doc`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MaterialDocDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoc(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDoc.');
            }
            const localVarPath = `/docs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDocPdf.');
            }
            const localVarPath = `/docs/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaterial(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMaterial.');
            }
            const localVarPath = `/material/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadDocPdf.');
            }
            const localVarPath = `/docs/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDoc(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readDoc.');
            }
            const localVarPath = `/docs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterial(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readMaterial.');
            }
            const localVarPath = `/material/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterials(options: any = {}): FetchArgs {
            const localVarPath = `/material`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoc(body: MaterialDocDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDoc.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDoc.');
            }
            const localVarPath = `/docs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MaterialDocDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterial(body: MaterialDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateMaterial.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMaterial.');
            }
            const localVarPath = `/material/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MaterialDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadDocPdf.');
            }
            const localVarPath = `/docs/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaterialApi - functional programming interface
 * @export
 */
export const MaterialApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterial(body: MaterialDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).createMaterial(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterialDoc(body: MaterialDocDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).createMaterialDoc(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoc(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).deleteDoc(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).deleteDocPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaterial(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).deleteMaterial(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPdfDTO> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).downloadDocPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDoc(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadMaterialDocDTO> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).readDoc(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterial(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadMaterialDTO> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).readMaterial(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterials(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadMaterialDTO>> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).readMaterials(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoc(body: MaterialDocDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).updateDoc(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterial(body: MaterialDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).updateMaterial(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MaterialApiFetchParamCreator(configuration).uploadDocPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MaterialApi - factory interface
 * @export
 */
export const MaterialApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterial(body: MaterialDTO, options?: any) {
            return MaterialApiFp(configuration).createMaterial(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterialDoc(body: MaterialDocDTO, id: number, options?: any) {
            return MaterialApiFp(configuration).createMaterialDoc(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoc(id: number, options?: any) {
            return MaterialApiFp(configuration).deleteDoc(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocPdf(id: number, options?: any) {
            return MaterialApiFp(configuration).deleteDocPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaterial(id: string, options?: any) {
            return MaterialApiFp(configuration).deleteMaterial(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocPdf(id: number, options?: any) {
            return MaterialApiFp(configuration).downloadDocPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDoc(id: number, options?: any) {
            return MaterialApiFp(configuration).readDoc(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterial(id: number, options?: any) {
            return MaterialApiFp(configuration).readMaterial(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterials(options?: any) {
            return MaterialApiFp(configuration).readMaterials(options)(fetch, basePath);
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoc(body: MaterialDocDTO, id: number, options?: any) {
            return MaterialApiFp(configuration).updateDoc(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterial(body: MaterialDTO, id: number, options?: any) {
            return MaterialApiFp(configuration).updateMaterial(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocPdf(id: number, options?: any) {
            return MaterialApiFp(configuration).uploadDocPdf(id, options)(fetch, basePath);
        },
    };
};

/**
 * MaterialApi - object-oriented interface
 * @export
 * @class MaterialApi
 * @extends {BaseAPI}
 */
export class MaterialApi extends BaseAPI {
    /**
     * 
     * @param {MaterialDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public createMaterial(body: MaterialDTO, options?: any) {
        return MaterialApiFp(this.configuration).createMaterial(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {MaterialDocDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public createMaterialDoc(body: MaterialDocDTO, id: number, options?: any) {
        return MaterialApiFp(this.configuration).createMaterialDoc(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public deleteDoc(id: number, options?: any) {
        return MaterialApiFp(this.configuration).deleteDoc(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public deleteDocPdf(id: number, options?: any) {
        return MaterialApiFp(this.configuration).deleteDocPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public deleteMaterial(id: string, options?: any) {
        return MaterialApiFp(this.configuration).deleteMaterial(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public downloadDocPdf(id: number, options?: any) {
        return MaterialApiFp(this.configuration).downloadDocPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public readDoc(id: number, options?: any) {
        return MaterialApiFp(this.configuration).readDoc(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public readMaterial(id: number, options?: any) {
        return MaterialApiFp(this.configuration).readMaterial(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public readMaterials(options?: any) {
        return MaterialApiFp(this.configuration).readMaterials(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {MaterialDocDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public updateDoc(body: MaterialDocDTO, id: number, options?: any) {
        return MaterialApiFp(this.configuration).updateDoc(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {MaterialDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public updateMaterial(body: MaterialDTO, id: number, options?: any) {
        return MaterialApiFp(this.configuration).updateMaterial(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public uploadDocPdf(id: number, options?: any) {
        return MaterialApiFp(this.configuration).uploadDocPdf(id, options)(this.fetch, this.basePath);
    }

}
/**
 * OrderApi - fetch parameter creator
 * @export
 */
export const OrderApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(body: CreateOrderDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrder.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createOrder.');
            }
            const localVarPath = `/order-group/{id}/create-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateOrderDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOrder.');
            }
            const localVarPath = `/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersOfGroup(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readOrdersOfGroup.');
            }
            const localVarPath = `/order-group/{id}/orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(body: OrderDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrder.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOrder.');
            }
            const localVarPath = `/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(body: CreateOrderDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OrderApiFetchParamCreator(configuration).createOrder(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = OrderApiFetchParamCreator(configuration).deleteOrder(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersOfGroup(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadOrderDTO>> {
            const localVarFetchArgs = OrderApiFetchParamCreator(configuration).readOrdersOfGroup(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(body: OrderDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OrderApiFetchParamCreator(configuration).updateOrder(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateOrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(body: CreateOrderDTO, id: number, options?: any) {
            return OrderApiFp(configuration).createOrder(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder(id: number, options?: any) {
            return OrderApiFp(configuration).deleteOrder(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersOfGroup(id: number, options?: any) {
            return OrderApiFp(configuration).readOrdersOfGroup(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(body: OrderDTO, id: number, options?: any) {
            return OrderApiFp(configuration).updateOrder(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public createOrder(body: CreateOrderDTO, id: number, options?: any) {
        return OrderApiFp(this.configuration).createOrder(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public deleteOrder(id: number, options?: any) {
        return OrderApiFp(this.configuration).deleteOrder(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public readOrdersOfGroup(id: number, options?: any) {
        return OrderApiFp(this.configuration).readOrdersOfGroup(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {OrderDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateOrder(body: OrderDTO, id: number, options?: any) {
        return OrderApiFp(this.configuration).updateOrder(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * OrderGroupApi - fetch parameter creator
 * @export
 */
export const OrderGroupApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachPieceToOrderGroup(body: AttachPieceDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling attachPieceToOrderGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling attachPieceToOrderGroup.');
            }
            const localVarPath = `/order-group/{id}/attach-piece`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AttachPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderGroup(body: CreateOrderGroupDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrderGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createOrderGroup.');
            }
            const localVarPath = `/partnership/{id}/order-group`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateOrderGroupDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderGroup_1(body: CreateOrderGroupDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrderGroup_1.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createOrderGroup_1.');
            }
            const localVarPath = `/partnership/{id}/order-group`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateOrderGroupDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderGroup(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOrderGroup.');
            }
            const localVarPath = `/order-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettachPieceFromOrderGroup(body: AttachPieceDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling dettachPieceFromOrderGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dettachPieceFromOrderGroup.');
            }
            const localVarPath = `/order-group/{id}/dettach-piece`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AttachPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPartnerships(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readBuildingPartnerships.');
            }
            const localVarPath = `/building/{id}/partnerships`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnership(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readPartnership.');
            }
            const localVarPath = `/partnership/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReattachPieceDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reattachPieceInOrderGroups(body: ReattachPieceDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling reattachPieceInOrderGroups.');
            }
            const localVarPath = `/order-group/reattachPiece`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReattachPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderGroup(body: OrderGroupDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrderGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOrderGroup.');
            }
            const localVarPath = `/order-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderGroupDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderGroupApi - functional programming interface
 * @export
 */
export const OrderGroupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachPieceToOrderGroup(body: AttachPieceDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OrderGroupApiFetchParamCreator(configuration).attachPieceToOrderGroup(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateOrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderGroup(body: CreateOrderGroupDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OrderGroupApiFetchParamCreator(configuration).createOrderGroup(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateOrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderGroup_1(body: CreateOrderGroupDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OrderGroupApiFetchParamCreator(configuration).createOrderGroup_1(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderGroup(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = OrderGroupApiFetchParamCreator(configuration).deleteOrderGroup(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettachPieceFromOrderGroup(body: AttachPieceDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = OrderGroupApiFetchParamCreator(configuration).dettachPieceFromOrderGroup(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPartnerships(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadBuildingPartnershipsDTO> {
            const localVarFetchArgs = OrderGroupApiFetchParamCreator(configuration).readBuildingPartnerships(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnership(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPartnershipDTO> {
            const localVarFetchArgs = OrderGroupApiFetchParamCreator(configuration).readPartnership(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ReattachPieceDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reattachPieceInOrderGroups(body: ReattachPieceDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = OrderGroupApiFetchParamCreator(configuration).reattachPieceInOrderGroups(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderGroup(body: OrderGroupDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OrderGroupApiFetchParamCreator(configuration).updateOrderGroup(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrderGroupApi - factory interface
 * @export
 */
export const OrderGroupApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachPieceToOrderGroup(body: AttachPieceDTO, id: number, options?: any) {
            return OrderGroupApiFp(configuration).attachPieceToOrderGroup(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateOrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderGroup(body: CreateOrderGroupDTO, id: number, options?: any) {
            return OrderGroupApiFp(configuration).createOrderGroup(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateOrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderGroup_1(body: CreateOrderGroupDTO, id: number, options?: any) {
            return OrderGroupApiFp(configuration).createOrderGroup_1(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderGroup(id: number, options?: any) {
            return OrderGroupApiFp(configuration).deleteOrderGroup(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettachPieceFromOrderGroup(body: AttachPieceDTO, id: number, options?: any) {
            return OrderGroupApiFp(configuration).dettachPieceFromOrderGroup(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPartnerships(id: number, options?: any) {
            return OrderGroupApiFp(configuration).readBuildingPartnerships(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnership(id: number, options?: any) {
            return OrderGroupApiFp(configuration).readPartnership(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ReattachPieceDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reattachPieceInOrderGroups(body: ReattachPieceDTO, options?: any) {
            return OrderGroupApiFp(configuration).reattachPieceInOrderGroups(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderGroup(body: OrderGroupDTO, id: number, options?: any) {
            return OrderGroupApiFp(configuration).updateOrderGroup(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * OrderGroupApi - object-oriented interface
 * @export
 * @class OrderGroupApi
 * @extends {BaseAPI}
 */
export class OrderGroupApi extends BaseAPI {
    /**
     * 
     * @param {AttachPieceDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderGroupApi
     */
    public attachPieceToOrderGroup(body: AttachPieceDTO, id: number, options?: any) {
        return OrderGroupApiFp(this.configuration).attachPieceToOrderGroup(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CreateOrderGroupDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderGroupApi
     */
    public createOrderGroup(body: CreateOrderGroupDTO, id: number, options?: any) {
        return OrderGroupApiFp(this.configuration).createOrderGroup(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CreateOrderGroupDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderGroupApi
     */
    public createOrderGroup_1(body: CreateOrderGroupDTO, id: number, options?: any) {
        return OrderGroupApiFp(this.configuration).createOrderGroup_1(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderGroupApi
     */
    public deleteOrderGroup(id: number, options?: any) {
        return OrderGroupApiFp(this.configuration).deleteOrderGroup(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AttachPieceDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderGroupApi
     */
    public dettachPieceFromOrderGroup(body: AttachPieceDTO, id: number, options?: any) {
        return OrderGroupApiFp(this.configuration).dettachPieceFromOrderGroup(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderGroupApi
     */
    public readBuildingPartnerships(id: number, options?: any) {
        return OrderGroupApiFp(this.configuration).readBuildingPartnerships(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderGroupApi
     */
    public readPartnership(id: number, options?: any) {
        return OrderGroupApiFp(this.configuration).readPartnership(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ReattachPieceDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderGroupApi
     */
    public reattachPieceInOrderGroups(body: ReattachPieceDTO, options?: any) {
        return OrderGroupApiFp(this.configuration).reattachPieceInOrderGroups(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {OrderGroupDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderGroupApi
     */
    public updateOrderGroup(body: OrderGroupDTO, id: number, options?: any) {
        return OrderGroupApiFp(this.configuration).updateOrderGroup(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * PaymentApi - fetch parameter creator
 * @export
 */
export const PaymentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetPriceBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPrice(body: GetPriceBodyDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getSubscriptionPrice.');
            }
            const localVarPath = `/payment/price`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetPriceBodyDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPayment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readPayment.');
            }
            const localVarPath = `/payment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetPriceBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPrice(body: GetPriceBodyDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetPriceResponseDTO> {
            const localVarFetchArgs = PaymentApiFetchParamCreator(configuration).getSubscriptionPrice(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPayment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPaymentDTO> {
            const localVarFetchArgs = PaymentApiFetchParamCreator(configuration).readPayment(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {GetPriceBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPrice(body: GetPriceBodyDTO, options?: any) {
            return PaymentApiFp(configuration).getSubscriptionPrice(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPayment(id: number, options?: any) {
            return PaymentApiFp(configuration).readPayment(id, options)(fetch, basePath);
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @param {GetPriceBodyDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getSubscriptionPrice(body: GetPriceBodyDTO, options?: any) {
        return PaymentApiFp(this.configuration).getSubscriptionPrice(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public readPayment(id: number, options?: any) {
        return PaymentApiFp(this.configuration).readPayment(id, options)(this.fetch, this.basePath);
    }

}
/**
 * PieceApi - fetch parameter creator
 * @export
 */
export const PieceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingPiece(body: BuildingPieceDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createBuildingPiece.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createBuildingPiece.');
            }
            const localVarPath = `/building/{id}/pieces`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BuildingPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportOptionsDTO} body 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(body: ReportOptionsDTO, pieceId: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createReport.');
            }
            // verify required parameter 'pieceId' is not null or undefined
            if (pieceId === null || pieceId === undefined) {
                throw new RequiredError('pieceId','Required parameter pieceId was null or undefined when calling createReport.');
            }
            const localVarPath = `/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pieceId !== undefined) {
                localVarQueryParameter['pieceId'] = pieceId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportOptionsDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp(body: StampDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStamp.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createStamp.');
            }
            const localVarPath = `/piece/{id}/stamps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StampDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork(body: WorkDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createWork.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createWork.');
            }
            const localVarPath = `/piece/{id}/works`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WorkDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuildingPiece(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteBuildingPiece.');
            }
            const localVarPath = `/piece/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(pieceId: number, options: any = {}): FetchArgs {
            // verify required parameter 'pieceId' is not null or undefined
            if (pieceId === null || pieceId === undefined) {
                throw new RequiredError('pieceId','Required parameter pieceId was null or undefined when calling deleteReport.');
            }
            const localVarPath = `/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pieceId !== undefined) {
                localVarQueryParameter['pieceId'] = pieceId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadReport.');
            }
            const localVarPath = `/report/{id}/zip`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPiece(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readBuildingPiece.');
            }
            const localVarPath = `/piece/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeepPieceData(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readDeepPieceData.');
            }
            const localVarPath = `/report/deepPiece/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceStamps(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readPieceStamps.');
            }
            const localVarPath = `/piece/{id}/stamps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [orderBy] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceWorks(id: number, orderBy?: string, sortBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readPieceWorks.');
            }
            const localVarPath = `/piece/{id}/works`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReport(pieceId: number, options: any = {}): FetchArgs {
            // verify required parameter 'pieceId' is not null or undefined
            if (pieceId === null || pieceId === undefined) {
                throw new RequiredError('pieceId','Required parameter pieceId was null or undefined when calling readReport.');
            }
            const localVarPath = `/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pieceId !== undefined) {
                localVarQueryParameter['pieceId'] = pieceId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuildingPiece(body: BuildingPieceDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateBuildingPiece.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateBuildingPiece.');
            }
            const localVarPath = `/piece/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BuildingPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PieceApi - functional programming interface
 * @export
 */
export const PieceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingPiece(body: BuildingPieceDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).createBuildingPiece(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ReportOptionsDTO} body 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(body: ReportOptionsDTO, pieceId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadReportDTO> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).createReport(body, pieceId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp(body: StampDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StampDTO> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).createStamp(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork(body: WorkDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).createWork(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuildingPiece(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).deleteBuildingPiece(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(pieceId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).deleteReport(pieceId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).downloadReport(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPiece(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadBuildingPieceDTO> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).readBuildingPiece(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeepPieceData(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BuildingPiece> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).readDeepPieceData(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceStamps(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadStampDTO>> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).readPieceStamps(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [orderBy] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceWorks(id: number, orderBy?: string, sortBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadWorkItemDTO>> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).readPieceWorks(id, orderBy, sortBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReport(pieceId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadReportDTO> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).readReport(pieceId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuildingPiece(body: BuildingPieceDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = PieceApiFetchParamCreator(configuration).updateBuildingPiece(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PieceApi - factory interface
 * @export
 */
export const PieceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingPiece(body: BuildingPieceDTO, id: number, options?: any) {
            return PieceApiFp(configuration).createBuildingPiece(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ReportOptionsDTO} body 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(body: ReportOptionsDTO, pieceId: number, options?: any) {
            return PieceApiFp(configuration).createReport(body, pieceId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp(body: StampDTO, id: number, options?: any) {
            return PieceApiFp(configuration).createStamp(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork(body: WorkDTO, id: number, options?: any) {
            return PieceApiFp(configuration).createWork(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuildingPiece(id: number, options?: any) {
            return PieceApiFp(configuration).deleteBuildingPiece(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(pieceId: number, options?: any) {
            return PieceApiFp(configuration).deleteReport(pieceId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(id: number, options?: any) {
            return PieceApiFp(configuration).downloadReport(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPiece(id: number, options?: any) {
            return PieceApiFp(configuration).readBuildingPiece(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeepPieceData(id: number, options?: any) {
            return PieceApiFp(configuration).readDeepPieceData(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceStamps(id: number, options?: any) {
            return PieceApiFp(configuration).readPieceStamps(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [orderBy] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceWorks(id: number, orderBy?: string, sortBy?: string, options?: any) {
            return PieceApiFp(configuration).readPieceWorks(id, orderBy, sortBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReport(pieceId: number, options?: any) {
            return PieceApiFp(configuration).readReport(pieceId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuildingPiece(body: BuildingPieceDTO, id: number, options?: any) {
            return PieceApiFp(configuration).updateBuildingPiece(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * PieceApi - object-oriented interface
 * @export
 * @class PieceApi
 * @extends {BaseAPI}
 */
export class PieceApi extends BaseAPI {
    /**
     * 
     * @param {BuildingPieceDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public createBuildingPiece(body: BuildingPieceDTO, id: number, options?: any) {
        return PieceApiFp(this.configuration).createBuildingPiece(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ReportOptionsDTO} body 
     * @param {number} pieceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public createReport(body: ReportOptionsDTO, pieceId: number, options?: any) {
        return PieceApiFp(this.configuration).createReport(body, pieceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {StampDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public createStamp(body: StampDTO, id: number, options?: any) {
        return PieceApiFp(this.configuration).createStamp(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {WorkDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public createWork(body: WorkDTO, id: number, options?: any) {
        return PieceApiFp(this.configuration).createWork(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public deleteBuildingPiece(id: number, options?: any) {
        return PieceApiFp(this.configuration).deleteBuildingPiece(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} pieceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public deleteReport(pieceId: number, options?: any) {
        return PieceApiFp(this.configuration).deleteReport(pieceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public downloadReport(id: number, options?: any) {
        return PieceApiFp(this.configuration).downloadReport(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public readBuildingPiece(id: number, options?: any) {
        return PieceApiFp(this.configuration).readBuildingPiece(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public readDeepPieceData(id: number, options?: any) {
        return PieceApiFp(this.configuration).readDeepPieceData(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public readPieceStamps(id: number, options?: any) {
        return PieceApiFp(this.configuration).readPieceStamps(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [orderBy] 
     * @param {string} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public readPieceWorks(id: number, orderBy?: string, sortBy?: string, options?: any) {
        return PieceApiFp(this.configuration).readPieceWorks(id, orderBy, sortBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} pieceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public readReport(pieceId: number, options?: any) {
        return PieceApiFp(this.configuration).readReport(pieceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BuildingPieceDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PieceApi
     */
    public updateBuildingPiece(body: BuildingPieceDTO, id: number, options?: any) {
        return PieceApiFp(this.configuration).updateBuildingPiece(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * PreferencesApi - fetch parameter creator
 * @export
 */
export const PreferencesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PinMaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinMaterial(body: PinMaterialDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling pinMaterial.');
            }
            const localVarPath = `/preferences/pin-material`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PinMaterialDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPreferences(options: any = {}): FetchArgs {
            const localVarPath = `/preferences`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreferencesApi - functional programming interface
 * @export
 */
export const PreferencesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PinMaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinMaterial(body: PinMaterialDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = PreferencesApiFetchParamCreator(configuration).pinMaterial(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPreferences(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPreferencesDTO> {
            const localVarFetchArgs = PreferencesApiFetchParamCreator(configuration).readPreferences(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PreferencesApi - factory interface
 * @export
 */
export const PreferencesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PinMaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinMaterial(body: PinMaterialDTO, options?: any) {
            return PreferencesApiFp(configuration).pinMaterial(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPreferences(options?: any) {
            return PreferencesApiFp(configuration).readPreferences(options)(fetch, basePath);
        },
    };
};

/**
 * PreferencesApi - object-oriented interface
 * @export
 * @class PreferencesApi
 * @extends {BaseAPI}
 */
export class PreferencesApi extends BaseAPI {
    /**
     * 
     * @param {PinMaterialDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public pinMaterial(body: PinMaterialDTO, options?: any) {
        return PreferencesApiFp(this.configuration).pinMaterial(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public readPreferences(options?: any) {
        return PreferencesApiFp(this.configuration).readPreferences(options)(this.fetch, this.basePath);
    }

}
/**
 * ReportApi - fetch parameter creator
 * @export
 */
export const ReportApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportOptionsDTO} body 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(body: ReportOptionsDTO, pieceId: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createReport.');
            }
            // verify required parameter 'pieceId' is not null or undefined
            if (pieceId === null || pieceId === undefined) {
                throw new RequiredError('pieceId','Required parameter pieceId was null or undefined when calling createReport.');
            }
            const localVarPath = `/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pieceId !== undefined) {
                localVarQueryParameter['pieceId'] = pieceId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportOptionsDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportOptionsDTO} body 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(body: ReportOptionsDTO, pieceId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadReportDTO> {
            const localVarFetchArgs = ReportApiFetchParamCreator(configuration).createReport(body, pieceId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ReportOptionsDTO} body 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(body: ReportOptionsDTO, pieceId: number, options?: any) {
            return ReportApiFp(configuration).createReport(body, pieceId, options)(fetch, basePath);
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @param {ReportOptionsDTO} body 
     * @param {number} pieceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public createReport(body: ReportOptionsDTO, pieceId: number, options?: any) {
        return ReportApiFp(this.configuration).createReport(body, pieceId, options)(this.fetch, this.basePath);
    }

}
/**
 * SchemaApi - fetch parameter creator
 * @export
 */
export const SchemaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchema(body: SchemaDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSchema.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createSchema.');
            }
            const localVarPath = `/work/{id}/schemas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SchemaDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchema(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSchema.');
            }
            const localVarPath = `/schema/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchemaPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSchemaPdf.');
            }
            const localVarPath = `/schema/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemaPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readSchemaPdf.');
            }
            const localVarPath = `/schema/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemas(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readSchemas.');
            }
            const localVarPath = `/work/{id}/schemas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchema(body: SchemaDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSchema.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSchema.');
            }
            const localVarPath = `/schema/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SchemaDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSchemaPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadSchemaPdf.');
            }
            const localVarPath = `/schema/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchemaApi - functional programming interface
 * @export
 */
export const SchemaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchema(body: SchemaDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = SchemaApiFetchParamCreator(configuration).createSchema(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchema(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SchemaApiFetchParamCreator(configuration).deleteSchema(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchemaPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SchemaApiFetchParamCreator(configuration).deleteSchemaPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemaPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPdfDTO> {
            const localVarFetchArgs = SchemaApiFetchParamCreator(configuration).readSchemaPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemas(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadSchemaDTO>> {
            const localVarFetchArgs = SchemaApiFetchParamCreator(configuration).readSchemas(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchema(body: SchemaDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SchemaApiFetchParamCreator(configuration).updateSchema(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSchemaPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = SchemaApiFetchParamCreator(configuration).uploadSchemaPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SchemaApi - factory interface
 * @export
 */
export const SchemaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchema(body: SchemaDTO, id: number, options?: any) {
            return SchemaApiFp(configuration).createSchema(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchema(id: number, options?: any) {
            return SchemaApiFp(configuration).deleteSchema(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchemaPdf(id: number, options?: any) {
            return SchemaApiFp(configuration).deleteSchemaPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemaPdf(id: number, options?: any) {
            return SchemaApiFp(configuration).readSchemaPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemas(id: number, options?: any) {
            return SchemaApiFp(configuration).readSchemas(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchema(body: SchemaDTO, id: number, options?: any) {
            return SchemaApiFp(configuration).updateSchema(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSchemaPdf(id: number, options?: any) {
            return SchemaApiFp(configuration).uploadSchemaPdf(id, options)(fetch, basePath);
        },
    };
};

/**
 * SchemaApi - object-oriented interface
 * @export
 * @class SchemaApi
 * @extends {BaseAPI}
 */
export class SchemaApi extends BaseAPI {
    /**
     * 
     * @param {SchemaDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemaApi
     */
    public createSchema(body: SchemaDTO, id: number, options?: any) {
        return SchemaApiFp(this.configuration).createSchema(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemaApi
     */
    public deleteSchema(id: number, options?: any) {
        return SchemaApiFp(this.configuration).deleteSchema(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemaApi
     */
    public deleteSchemaPdf(id: number, options?: any) {
        return SchemaApiFp(this.configuration).deleteSchemaPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemaApi
     */
    public readSchemaPdf(id: number, options?: any) {
        return SchemaApiFp(this.configuration).readSchemaPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemaApi
     */
    public readSchemas(id: number, options?: any) {
        return SchemaApiFp(this.configuration).readSchemas(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SchemaDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemaApi
     */
    public updateSchema(body: SchemaDTO, id: number, options?: any) {
        return SchemaApiFp(this.configuration).updateSchema(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemaApi
     */
    public uploadSchemaPdf(id: number, options?: any) {
        return SchemaApiFp(this.configuration).uploadSchemaPdf(id, options)(this.fetch, this.basePath);
    }

}
/**
 * SessionApi - fetch parameter creator
 * @export
 */
export const SessionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keepAlive(options: any = {}): FetchArgs {
            const localVarPath = `/session`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceSession(options: any = {}): FetchArgs {
            const localVarPath = `/session`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keepAlive(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionApiFetchParamCreator(configuration).keepAlive(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceSession(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SessionApiFetchParamCreator(configuration).replaceSession(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keepAlive(options?: any) {
            return SessionApiFp(configuration).keepAlive(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceSession(options?: any) {
            return SessionApiFp(configuration).replaceSession(options)(fetch, basePath);
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public keepAlive(options?: any) {
        return SessionApiFp(this.configuration).keepAlive(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public replaceSession(options?: any) {
        return SessionApiFp(this.configuration).replaceSession(options)(this.fetch, this.basePath);
    }

}
/**
 * StampApi - fetch parameter creator
 * @export
 */
export const StampApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp(body: StampDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStamp.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createStamp.');
            }
            const localVarPath = `/piece/{id}/stamps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StampDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStamp(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteStamp.');
            }
            const localVarPath = `/stamp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceStamps(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readPieceStamps.');
            }
            const localVarPath = `/piece/{id}/stamps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStampInUse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readStampInUse.');
            }
            const localVarPath = `/stamp/{id}/in-use`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStamp(body: StampDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStamp.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateStamp.');
            }
            const localVarPath = `/stamp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StampDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StampApi - functional programming interface
 * @export
 */
export const StampApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp(body: StampDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StampDTO> {
            const localVarFetchArgs = StampApiFetchParamCreator(configuration).createStamp(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStamp(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StampApiFetchParamCreator(configuration).deleteStamp(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceStamps(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadStampDTO>> {
            const localVarFetchArgs = StampApiFetchParamCreator(configuration).readPieceStamps(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStampInUse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadStampInUse> {
            const localVarFetchArgs = StampApiFetchParamCreator(configuration).readStampInUse(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStamp(body: StampDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StampDTO> {
            const localVarFetchArgs = StampApiFetchParamCreator(configuration).updateStamp(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StampApi - factory interface
 * @export
 */
export const StampApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp(body: StampDTO, id: number, options?: any) {
            return StampApiFp(configuration).createStamp(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStamp(id: number, options?: any) {
            return StampApiFp(configuration).deleteStamp(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceStamps(id: number, options?: any) {
            return StampApiFp(configuration).readPieceStamps(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStampInUse(id: number, options?: any) {
            return StampApiFp(configuration).readStampInUse(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStamp(body: StampDTO, id: number, options?: any) {
            return StampApiFp(configuration).updateStamp(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * StampApi - object-oriented interface
 * @export
 * @class StampApi
 * @extends {BaseAPI}
 */
export class StampApi extends BaseAPI {
    /**
     * 
     * @param {StampDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public createStamp(body: StampDTO, id: number, options?: any) {
        return StampApiFp(this.configuration).createStamp(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public deleteStamp(id: number, options?: any) {
        return StampApiFp(this.configuration).deleteStamp(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public readPieceStamps(id: number, options?: any) {
        return StampApiFp(this.configuration).readPieceStamps(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public readStampInUse(id: number, options?: any) {
        return StampApiFp(this.configuration).readStampInUse(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {StampDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public updateStamp(body: StampDTO, id: number, options?: any) {
        return StampApiFp(this.configuration).updateStamp(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * SubscriptionApi - fetch parameter creator
 * @export
 */
export const SubscriptionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSubscriptionBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(body: CreateSubscriptionBodyDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSubscription.');
            }
            const localVarPath = `/subscription`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateSubscriptionBodyDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSubscription.');
            }
            const localVarPath = `/subscription/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCurrentSubscription(options: any = {}): FetchArgs {
            const localVarPath = `/subscription/current`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSubscriptions(options: any = {}): FetchArgs {
            const localVarPath = `/subscription`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSubscriptionBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(body: CreateSubscriptionBodyDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateSubscriptionResponseDTO> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).createSubscription(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).deleteSubscription(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCurrentSubscription(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadCurrentSubscriptionDTO> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).readCurrentSubscription(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSubscriptions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadSubscriptionsDTO> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).readSubscriptions(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateSubscriptionBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(body: CreateSubscriptionBodyDTO, options?: any) {
            return SubscriptionApiFp(configuration).createSubscription(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription(id: number, options?: any) {
            return SubscriptionApiFp(configuration).deleteSubscription(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCurrentSubscription(options?: any) {
            return SubscriptionApiFp(configuration).readCurrentSubscription(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSubscriptions(options?: any) {
            return SubscriptionApiFp(configuration).readSubscriptions(options)(fetch, basePath);
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @param {CreateSubscriptionBodyDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public createSubscription(body: CreateSubscriptionBodyDTO, options?: any) {
        return SubscriptionApiFp(this.configuration).createSubscription(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public deleteSubscription(id: number, options?: any) {
        return SubscriptionApiFp(this.configuration).deleteSubscription(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public readCurrentSubscription(options?: any) {
        return SubscriptionApiFp(this.configuration).readCurrentSubscription(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public readSubscriptions(options?: any) {
        return SubscriptionApiFp(this.configuration).readSubscriptions(options)(this.fetch, this.basePath);
    }

}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachPieceToOrderGroup(body: AttachPieceDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling attachPieceToOrderGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling attachPieceToOrderGroup.');
            }
            const localVarPath = `/order-group/{id}/attach-piece`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AttachPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyWork(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling copyWork.');
            }
            const localVarPath = `/work/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuilding(body: BuildingDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createBuilding.');
            }
            const localVarPath = `/building`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BuildingDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingPiece(body: BuildingPieceDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createBuildingPiece.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createBuildingPiece.');
            }
            const localVarPath = `/building/{id}/pieces`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BuildingPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(body: CompanyDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCompany.');
            }
            const localVarPath = `/company`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompanyDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoBuilding(options: any = {}): FetchArgs {
            const localVarPath = `/building/demo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupUser(body: AddUserDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createGroupUser.');
            }
            const localVarPath = `/group/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddUserDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HashDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHash(body: HashDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createHash.');
            }
            const localVarPath = `/users/hash`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"HashDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLab(body: LabDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLab.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createLab.');
            }
            const localVarPath = `/work/{id}/labs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LabDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterial(body: MaterialDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createMaterial.');
            }
            const localVarPath = `/material`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MaterialDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterialDoc(body: MaterialDocDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createMaterialDoc.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createMaterialDoc.');
            }
            const localVarPath = `/material/{id}/doc`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MaterialDocDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(body: CreateOrderDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrder.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createOrder.');
            }
            const localVarPath = `/order-group/{id}/create-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateOrderDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderGroup(body: CreateOrderGroupDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrderGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createOrderGroup.');
            }
            const localVarPath = `/partnership/{id}/order-group`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateOrderGroupDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportOptionsDTO} body 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(body: ReportOptionsDTO, pieceId: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createReport.');
            }
            // verify required parameter 'pieceId' is not null or undefined
            if (pieceId === null || pieceId === undefined) {
                throw new RequiredError('pieceId','Required parameter pieceId was null or undefined when calling createReport.');
            }
            const localVarPath = `/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pieceId !== undefined) {
                localVarQueryParameter['pieceId'] = pieceId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportOptionsDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchema(body: SchemaDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSchema.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createSchema.');
            }
            const localVarPath = `/work/{id}/schemas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SchemaDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp(body: StampDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStamp.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createStamp.');
            }
            const localVarPath = `/piece/{id}/stamps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StampDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSubscriptionBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(body: CreateSubscriptionBodyDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSubscription.');
            }
            const localVarPath = `/subscription`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateSubscriptionBodyDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork(body: WorkDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createWork.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createWork.');
            }
            const localVarPath = `/piece/{id}/works`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WorkDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteBuilding.');
            }
            const localVarPath = `/building/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuildingPiece(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteBuildingPiece.');
            }
            const localVarPath = `/piece/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCompany.');
            }
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoc(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDoc.');
            }
            const localVarPath = `/docs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDocPdf.');
            }
            const localVarPath = `/docs/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLab(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLab.');
            }
            const localVarPath = `/lab/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLabPdf.');
            }
            const localVarPath = `/lab/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaterial(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMaterial.');
            }
            const localVarPath = `/material/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOrder.');
            }
            const localVarPath = `/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderGroup(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOrderGroup.');
            }
            const localVarPath = `/order-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(pieceId: number, options: any = {}): FetchArgs {
            // verify required parameter 'pieceId' is not null or undefined
            if (pieceId === null || pieceId === undefined) {
                throw new RequiredError('pieceId','Required parameter pieceId was null or undefined when calling deleteReport.');
            }
            const localVarPath = `/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pieceId !== undefined) {
                localVarQueryParameter['pieceId'] = pieceId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchema(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSchema.');
            }
            const localVarPath = `/schema/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchemaPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSchemaPdf.');
            }
            const localVarPath = `/schema/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStamp(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteStamp.');
            }
            const localVarPath = `/stamp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSubscription.');
            }
            const localVarPath = `/subscription/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/group/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWork(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteWork.');
            }
            const localVarPath = `/work/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettachPieceFromOrderGroup(body: AttachPieceDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling dettachPieceFromOrderGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dettachPieceFromOrderGroup.');
            }
            const localVarPath = `/order-group/{id}/dettach-piece`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AttachPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadDocPdf.');
            }
            const localVarPath = `/docs/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadReport.');
            }
            const localVarPath = `/report/{id}/zip`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetPriceBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPrice(body: GetPriceBodyDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getSubscriptionPrice.');
            }
            const localVarPath = `/payment/price`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetPriceBodyDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keepAlive(options: any = {}): FetchArgs {
            const localVarPath = `/session`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(options: any = {}): FetchArgs {
            const localVarPath = `/auth/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatchWorksDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorks(body: PatchWorksDto, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchWorks.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchWorks.');
            }
            const localVarPath = `/piece/{id}/works`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchWorksDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PinMaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinMaterial(body: PinMaterialDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling pinMaterial.');
            }
            const localVarPath = `/preferences/pin-material`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PinMaterialDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options: any = {}): FetchArgs {
            const localVarPath = `/auth/ping`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailableStamps(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readAvailableStamps.');
            }
            const localVarPath = `/work/{id}/stamps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuilding(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readBuilding.');
            }
            const localVarPath = `/building/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingNames(options: any = {}): FetchArgs {
            const localVarPath = `/building/names`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPartnerships(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readBuildingPartnerships.');
            }
            const localVarPath = `/building/{id}/partnerships`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPiece(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readBuildingPiece.');
            }
            const localVarPath = `/piece/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPieces(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readBuildingPieces.');
            }
            const localVarPath = `/building/{id}/pieces`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildings(options: any = {}): FetchArgs {
            const localVarPath = `/building`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanies(options: any = {}): FetchArgs {
            const localVarPath = `/company`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompany(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readCompany.');
            }
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyInUse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readCompanyInUse.');
            }
            const localVarPath = `/company/{id}/in-use`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCurrentSubscription(options: any = {}): FetchArgs {
            const localVarPath = `/subscription/current`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeepPieceData(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readDeepPieceData.');
            }
            const localVarPath = `/report/deepPiece/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDoc(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readDoc.');
            }
            const localVarPath = `/docs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroup(options: any = {}): FetchArgs {
            const localVarPath = `/group`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupMembers(options: any = {}): FetchArgs {
            const localVarPath = `/group/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readLabPdf.');
            }
            const localVarPath = `/lab/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabs(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readLabs.');
            }
            const localVarPath = `/work/{id}/labs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterial(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readMaterial.');
            }
            const localVarPath = `/material/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterials(options: any = {}): FetchArgs {
            const localVarPath = `/material`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersOfGroup(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readOrdersOfGroup.');
            }
            const localVarPath = `/order-group/{id}/orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnership(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readPartnership.');
            }
            const localVarPath = `/partnership/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPayment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readPayment.');
            }
            const localVarPath = `/payment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceStamps(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readPieceStamps.');
            }
            const localVarPath = `/piece/{id}/stamps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [orderBy] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceWorks(id: number, orderBy?: string, sortBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readPieceWorks.');
            }
            const localVarPath = `/piece/{id}/works`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPreferences(options: any = {}): FetchArgs {
            const localVarPath = `/preferences`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReport(pieceId: number, options: any = {}): FetchArgs {
            // verify required parameter 'pieceId' is not null or undefined
            if (pieceId === null || pieceId === undefined) {
                throw new RequiredError('pieceId','Required parameter pieceId was null or undefined when calling readReport.');
            }
            const localVarPath = `/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pieceId !== undefined) {
                localVarQueryParameter['pieceId'] = pieceId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemaPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readSchemaPdf.');
            }
            const localVarPath = `/schema/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemas(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readSchemas.');
            }
            const localVarPath = `/work/{id}/schemas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStampInUse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readStampInUse.');
            }
            const localVarPath = `/stamp/{id}/in-use`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSubscriptions(options: any = {}): FetchArgs {
            const localVarPath = `/subscription`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWork(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readWork.');
            }
            const localVarPath = `/work/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReattachPieceDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reattachPieceInOrderGroups(body: ReattachPieceDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling reattachPieceInOrderGroups.');
            }
            const localVarPath = `/order-group/reattachPiece`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReattachPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(body: RegisterDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling register.');
            }
            const localVarPath = `/users/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegisterDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceSession(options: any = {}): FetchArgs {
            const localVarPath = `/session`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationId(options: any = {}): FetchArgs {
            const localVarPath = `/confirm/resend`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuilding(body: BuildingDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateBuilding.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateBuilding.');
            }
            const localVarPath = `/building/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BuildingDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuildingPiece(body: BuildingPieceDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateBuildingPiece.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateBuildingPiece.');
            }
            const localVarPath = `/piece/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BuildingPieceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(body: CompanyDTO, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateCompany.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCompany.');
            }
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompanyDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoc(body: MaterialDocDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDoc.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDoc.');
            }
            const localVarPath = `/docs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MaterialDocDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGroupDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(body: UpdateGroupDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateGroup.');
            }
            const localVarPath = `/group`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateGroupDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupUser(body: ChangePasswordDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateGroupUser.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateGroupUser.');
            }
            const localVarPath = `/group/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangePasswordDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLab(body: LabDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateLab.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateLab.');
            }
            const localVarPath = `/lab/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LabDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterial(body: MaterialDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateMaterial.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMaterial.');
            }
            const localVarPath = `/material/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MaterialDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(body: OrderDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrder.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOrder.');
            }
            const localVarPath = `/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderGroup(body: OrderGroupDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrderGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOrderGroup.');
            }
            const localVarPath = `/order-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderGroupDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchema(body: SchemaDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSchema.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSchema.');
            }
            const localVarPath = `/schema/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SchemaDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStamp(body: StampDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStamp.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateStamp.');
            }
            const localVarPath = `/stamp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StampDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWork(body: WorkDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateWork.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateWork.');
            }
            const localVarPath = `/work/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WorkDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWorkDocsDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkDocs(body: UpdateWorkDocsDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateWorkDocs.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateWorkDocs.');
            }
            const localVarPath = `/work/{id}/works`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateWorkDocsDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadDocPdf.');
            }
            const localVarPath = `/docs/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLabPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadLabPdf.');
            }
            const localVarPath = `/lab/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSchemaPdf(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadSchemaPdf.');
            }
            const localVarPath = `/schema/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoami(options: any = {}): FetchArgs {
            const localVarPath = `/auth/whoami`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachPieceToOrderGroup(body: AttachPieceDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).attachPieceToOrderGroup(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyWork(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).copyWork(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuilding(body: BuildingDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createBuilding(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingPiece(body: BuildingPieceDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createBuildingPiece(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(body: CompanyDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompanyDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createCompany(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoBuilding(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createDemoBuilding(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AddUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupUser(body: AddUserDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createGroupUser(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {HashDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHash(body: HashDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createHash(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLab(body: LabDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createLab(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterial(body: MaterialDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createMaterial(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterialDoc(body: MaterialDocDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createMaterialDoc(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateOrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(body: CreateOrderDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createOrder(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateOrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderGroup(body: CreateOrderGroupDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createOrderGroup(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ReportOptionsDTO} body 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(body: ReportOptionsDTO, pieceId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadReportDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createReport(body, pieceId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchema(body: SchemaDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createSchema(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp(body: StampDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StampDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createStamp(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateSubscriptionBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(body: CreateSubscriptionBodyDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateSubscriptionResponseDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createSubscription(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork(body: WorkDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).createWork(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteBuilding(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuildingPiece(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteBuildingPiece(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteCompany(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoc(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteDoc(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteDocPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLab(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteLab(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteLabPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaterial(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteMaterial(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteOrder(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderGroup(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteOrderGroup(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(pieceId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteReport(pieceId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchema(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteSchema(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchemaPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteSchemaPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStamp(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteStamp(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteSubscription(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteUser(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWork(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteWork(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettachPieceFromOrderGroup(body: AttachPieceDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).dettachPieceFromOrderGroup(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPdfDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).downloadDocPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).downloadReport(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GetPriceBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPrice(body: GetPriceBodyDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetPriceResponseDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getSubscriptionPrice(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keepAlive(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).keepAlive(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoginDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).login(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PatchWorksDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorks(body: PatchWorksDto, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).patchWorks(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PinMaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinMaterial(body: PinMaterialDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).pinMaterial(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).ping(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailableStamps(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadStampDTO>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readAvailableStamps(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuilding(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadBuildingDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readBuilding(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingNames(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadBuildingNamesDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readBuildingNames(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPartnerships(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadBuildingPartnershipsDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readBuildingPartnerships(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPiece(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadBuildingPieceDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readBuildingPiece(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPieces(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadBuildingPieceDTO>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readBuildingPieces(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildings(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadAllBuildingsDTO>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readBuildings(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanies(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadCompanyDTO>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readCompanies(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompany(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadCompanyDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readCompany(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyInUse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadCompanyInUseDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readCompanyInUse(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCurrentSubscription(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadCurrentSubscriptionDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readCurrentSubscription(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeepPieceData(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BuildingPiece> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readDeepPieceData(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDoc(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadMaterialDocDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readDoc(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroup(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadGroupDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readGroup(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupMembers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadGroupMembersDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readGroupMembers(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPdfDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readLabPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabs(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadLabDTO>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readLabs(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterial(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadMaterialDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readMaterial(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterials(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadMaterialDTO>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readMaterials(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersOfGroup(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadOrderDTO>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readOrdersOfGroup(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnership(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPartnershipDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readPartnership(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPayment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPaymentDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readPayment(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceStamps(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadStampDTO>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readPieceStamps(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [orderBy] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceWorks(id: number, orderBy?: string, sortBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadWorkItemDTO>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readPieceWorks(id, orderBy, sortBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPreferences(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPreferencesDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readPreferences(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReport(pieceId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadReportDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readReport(pieceId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemaPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadPdfDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readSchemaPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemas(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadSchemaDTO>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readSchemas(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStampInUse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadStampInUse> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readStampInUse(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSubscriptions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadSubscriptionsDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readSubscriptions(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWork(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadWorkDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).readWork(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ReattachPieceDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reattachPieceInOrderGroups(body: ReattachPieceDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).reattachPieceInOrderGroups(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RegisterDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(body: RegisterDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegisterResponseDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).register(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceSession(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).replaceSession(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationId(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).resendConfirmationId(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuilding(body: BuildingDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateBuilding(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuildingPiece(body: BuildingPieceDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateBuildingPiece(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(body: CompanyDTO, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateCompany(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoc(body: MaterialDocDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateDoc(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateGroupDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(body: UpdateGroupDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateGroup(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ChangePasswordDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupUser(body: ChangePasswordDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateGroupUser(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLab(body: LabDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateLab(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterial(body: MaterialDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateMaterial(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(body: OrderDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateOrder(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderGroup(body: OrderGroupDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateOrderGroup(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchema(body: SchemaDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateSchema(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStamp(body: StampDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StampDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateStamp(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWork(body: WorkDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateWork(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateWorkDocsDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkDocs(body: UpdateWorkDocsDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateWorkDocs(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).uploadDocPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLabPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).uploadLabPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSchemaPdf(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).uploadSchemaPdf(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoami(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WhoamiDTO> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).whoami(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachPieceToOrderGroup(body: AttachPieceDTO, id: number, options?: any) {
            return UserApiFp(configuration).attachPieceToOrderGroup(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyWork(id: number, options?: any) {
            return UserApiFp(configuration).copyWork(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuilding(body: BuildingDTO, options?: any) {
            return UserApiFp(configuration).createBuilding(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingPiece(body: BuildingPieceDTO, id: number, options?: any) {
            return UserApiFp(configuration).createBuildingPiece(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(body: CompanyDTO, options?: any) {
            return UserApiFp(configuration).createCompany(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoBuilding(options?: any) {
            return UserApiFp(configuration).createDemoBuilding(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AddUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupUser(body: AddUserDto, options?: any) {
            return UserApiFp(configuration).createGroupUser(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {HashDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHash(body: HashDTO, options?: any) {
            return UserApiFp(configuration).createHash(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLab(body: LabDTO, id: number, options?: any) {
            return UserApiFp(configuration).createLab(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterial(body: MaterialDTO, options?: any) {
            return UserApiFp(configuration).createMaterial(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterialDoc(body: MaterialDocDTO, id: number, options?: any) {
            return UserApiFp(configuration).createMaterialDoc(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateOrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(body: CreateOrderDTO, id: number, options?: any) {
            return UserApiFp(configuration).createOrder(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateOrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderGroup(body: CreateOrderGroupDTO, id: number, options?: any) {
            return UserApiFp(configuration).createOrderGroup(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ReportOptionsDTO} body 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(body: ReportOptionsDTO, pieceId: number, options?: any) {
            return UserApiFp(configuration).createReport(body, pieceId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchema(body: SchemaDTO, id: number, options?: any) {
            return UserApiFp(configuration).createSchema(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStamp(body: StampDTO, id: number, options?: any) {
            return UserApiFp(configuration).createStamp(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateSubscriptionBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(body: CreateSubscriptionBodyDTO, options?: any) {
            return UserApiFp(configuration).createSubscription(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork(body: WorkDTO, id: number, options?: any) {
            return UserApiFp(configuration).createWork(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(id: number, options?: any) {
            return UserApiFp(configuration).deleteBuilding(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuildingPiece(id: number, options?: any) {
            return UserApiFp(configuration).deleteBuildingPiece(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(id: string, options?: any) {
            return UserApiFp(configuration).deleteCompany(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoc(id: number, options?: any) {
            return UserApiFp(configuration).deleteDoc(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocPdf(id: number, options?: any) {
            return UserApiFp(configuration).deleteDocPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLab(id: number, options?: any) {
            return UserApiFp(configuration).deleteLab(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabPdf(id: number, options?: any) {
            return UserApiFp(configuration).deleteLabPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaterial(id: string, options?: any) {
            return UserApiFp(configuration).deleteMaterial(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder(id: number, options?: any) {
            return UserApiFp(configuration).deleteOrder(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderGroup(id: number, options?: any) {
            return UserApiFp(configuration).deleteOrderGroup(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(pieceId: number, options?: any) {
            return UserApiFp(configuration).deleteReport(pieceId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchema(id: number, options?: any) {
            return UserApiFp(configuration).deleteSchema(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchemaPdf(id: number, options?: any) {
            return UserApiFp(configuration).deleteSchemaPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStamp(id: number, options?: any) {
            return UserApiFp(configuration).deleteStamp(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription(id: number, options?: any) {
            return UserApiFp(configuration).deleteSubscription(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: number, options?: any) {
            return UserApiFp(configuration).deleteUser(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWork(id: number, options?: any) {
            return UserApiFp(configuration).deleteWork(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AttachPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dettachPieceFromOrderGroup(body: AttachPieceDTO, id: number, options?: any) {
            return UserApiFp(configuration).dettachPieceFromOrderGroup(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocPdf(id: number, options?: any) {
            return UserApiFp(configuration).downloadDocPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(id: number, options?: any) {
            return UserApiFp(configuration).downloadReport(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {GetPriceBodyDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPrice(body: GetPriceBodyDTO, options?: any) {
            return UserApiFp(configuration).getSubscriptionPrice(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keepAlive(options?: any) {
            return UserApiFp(configuration).keepAlive(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(options?: any) {
            return UserApiFp(configuration).login(options)(fetch, basePath);
        },
        /**
         * 
         * @param {PatchWorksDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorks(body: PatchWorksDto, id: number, options?: any) {
            return UserApiFp(configuration).patchWorks(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PinMaterialDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinMaterial(body: PinMaterialDTO, options?: any) {
            return UserApiFp(configuration).pinMaterial(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any) {
            return UserApiFp(configuration).ping(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailableStamps(id: number, options?: any) {
            return UserApiFp(configuration).readAvailableStamps(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuilding(id: number, options?: any) {
            return UserApiFp(configuration).readBuilding(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingNames(options?: any) {
            return UserApiFp(configuration).readBuildingNames(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPartnerships(id: number, options?: any) {
            return UserApiFp(configuration).readBuildingPartnerships(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPiece(id: number, options?: any) {
            return UserApiFp(configuration).readBuildingPiece(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildingPieces(id: number, options?: any) {
            return UserApiFp(configuration).readBuildingPieces(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBuildings(options?: any) {
            return UserApiFp(configuration).readBuildings(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanies(options?: any) {
            return UserApiFp(configuration).readCompanies(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompany(id: number, options?: any) {
            return UserApiFp(configuration).readCompany(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyInUse(id: number, options?: any) {
            return UserApiFp(configuration).readCompanyInUse(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCurrentSubscription(options?: any) {
            return UserApiFp(configuration).readCurrentSubscription(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeepPieceData(id: number, options?: any) {
            return UserApiFp(configuration).readDeepPieceData(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDoc(id: number, options?: any) {
            return UserApiFp(configuration).readDoc(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroup(options?: any) {
            return UserApiFp(configuration).readGroup(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupMembers(options?: any) {
            return UserApiFp(configuration).readGroupMembers(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabPdf(id: number, options?: any) {
            return UserApiFp(configuration).readLabPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabs(id: number, options?: any) {
            return UserApiFp(configuration).readLabs(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterial(id: number, options?: any) {
            return UserApiFp(configuration).readMaterial(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMaterials(options?: any) {
            return UserApiFp(configuration).readMaterials(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersOfGroup(id: number, options?: any) {
            return UserApiFp(configuration).readOrdersOfGroup(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnership(id: number, options?: any) {
            return UserApiFp(configuration).readPartnership(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPayment(id: number, options?: any) {
            return UserApiFp(configuration).readPayment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceStamps(id: number, options?: any) {
            return UserApiFp(configuration).readPieceStamps(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [orderBy] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceWorks(id: number, orderBy?: string, sortBy?: string, options?: any) {
            return UserApiFp(configuration).readPieceWorks(id, orderBy, sortBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPreferences(options?: any) {
            return UserApiFp(configuration).readPreferences(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} pieceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReport(pieceId: number, options?: any) {
            return UserApiFp(configuration).readReport(pieceId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemaPdf(id: number, options?: any) {
            return UserApiFp(configuration).readSchemaPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemas(id: number, options?: any) {
            return UserApiFp(configuration).readSchemas(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStampInUse(id: number, options?: any) {
            return UserApiFp(configuration).readStampInUse(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSubscriptions(options?: any) {
            return UserApiFp(configuration).readSubscriptions(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWork(id: number, options?: any) {
            return UserApiFp(configuration).readWork(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ReattachPieceDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reattachPieceInOrderGroups(body: ReattachPieceDTO, options?: any) {
            return UserApiFp(configuration).reattachPieceInOrderGroups(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RegisterDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(body: RegisterDTO, options?: any) {
            return UserApiFp(configuration).register(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceSession(options?: any) {
            return UserApiFp(configuration).replaceSession(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationId(options?: any) {
            return UserApiFp(configuration).resendConfirmationId(options)(fetch, basePath);
        },
        /**
         * 
         * @param {BuildingDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuilding(body: BuildingDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateBuilding(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {BuildingPieceDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuildingPiece(body: BuildingPieceDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateBuildingPiece(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CompanyDTO} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(body: CompanyDTO, id: string, options?: any) {
            return UserApiFp(configuration).updateCompany(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {MaterialDocDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoc(body: MaterialDocDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateDoc(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateGroupDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(body: UpdateGroupDTO, options?: any) {
            return UserApiFp(configuration).updateGroup(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ChangePasswordDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupUser(body: ChangePasswordDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateGroupUser(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLab(body: LabDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateLab(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {MaterialDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterial(body: MaterialDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateMaterial(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OrderDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(body: OrderDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateOrder(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OrderGroupDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderGroup(body: OrderGroupDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateOrderGroup(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchema(body: SchemaDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateSchema(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {StampDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStamp(body: StampDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateStamp(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWork(body: WorkDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateWork(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateWorkDocsDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkDocs(body: UpdateWorkDocsDTO, id: number, options?: any) {
            return UserApiFp(configuration).updateWorkDocs(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocPdf(id: number, options?: any) {
            return UserApiFp(configuration).uploadDocPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLabPdf(id: number, options?: any) {
            return UserApiFp(configuration).uploadLabPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSchemaPdf(id: number, options?: any) {
            return UserApiFp(configuration).uploadSchemaPdf(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoami(options?: any) {
            return UserApiFp(configuration).whoami(options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {AttachPieceDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public attachPieceToOrderGroup(body: AttachPieceDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).attachPieceToOrderGroup(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public copyWork(id: number, options?: any) {
        return UserApiFp(this.configuration).copyWork(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BuildingDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createBuilding(body: BuildingDTO, options?: any) {
        return UserApiFp(this.configuration).createBuilding(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BuildingPieceDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createBuildingPiece(body: BuildingPieceDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).createBuildingPiece(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CompanyDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createCompany(body: CompanyDTO, options?: any) {
        return UserApiFp(this.configuration).createCompany(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createDemoBuilding(options?: any) {
        return UserApiFp(this.configuration).createDemoBuilding(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AddUserDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createGroupUser(body: AddUserDto, options?: any) {
        return UserApiFp(this.configuration).createGroupUser(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {HashDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createHash(body: HashDTO, options?: any) {
        return UserApiFp(this.configuration).createHash(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {LabDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createLab(body: LabDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).createLab(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {MaterialDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createMaterial(body: MaterialDTO, options?: any) {
        return UserApiFp(this.configuration).createMaterial(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {MaterialDocDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createMaterialDoc(body: MaterialDocDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).createMaterialDoc(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CreateOrderDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createOrder(body: CreateOrderDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).createOrder(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CreateOrderGroupDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createOrderGroup(body: CreateOrderGroupDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).createOrderGroup(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ReportOptionsDTO} body 
     * @param {number} pieceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createReport(body: ReportOptionsDTO, pieceId: number, options?: any) {
        return UserApiFp(this.configuration).createReport(body, pieceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SchemaDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createSchema(body: SchemaDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).createSchema(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {StampDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createStamp(body: StampDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).createStamp(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CreateSubscriptionBodyDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createSubscription(body: CreateSubscriptionBodyDTO, options?: any) {
        return UserApiFp(this.configuration).createSubscription(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {WorkDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createWork(body: WorkDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).createWork(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteBuilding(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteBuilding(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteBuildingPiece(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteBuildingPiece(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteCompany(id: string, options?: any) {
        return UserApiFp(this.configuration).deleteCompany(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteDoc(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteDoc(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteDocPdf(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteDocPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteLab(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteLab(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteLabPdf(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteLabPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteMaterial(id: string, options?: any) {
        return UserApiFp(this.configuration).deleteMaterial(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteOrder(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteOrder(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteOrderGroup(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteOrderGroup(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} pieceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteReport(pieceId: number, options?: any) {
        return UserApiFp(this.configuration).deleteReport(pieceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteSchema(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteSchema(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteSchemaPdf(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteSchemaPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteStamp(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteStamp(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteSubscription(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteSubscription(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteUser(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteWork(id: number, options?: any) {
        return UserApiFp(this.configuration).deleteWork(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AttachPieceDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public dettachPieceFromOrderGroup(body: AttachPieceDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).dettachPieceFromOrderGroup(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public downloadDocPdf(id: number, options?: any) {
        return UserApiFp(this.configuration).downloadDocPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public downloadReport(id: number, options?: any) {
        return UserApiFp(this.configuration).downloadReport(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {GetPriceBodyDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getSubscriptionPrice(body: GetPriceBodyDTO, options?: any) {
        return UserApiFp(this.configuration).getSubscriptionPrice(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public keepAlive(options?: any) {
        return UserApiFp(this.configuration).keepAlive(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public login(options?: any) {
        return UserApiFp(this.configuration).login(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PatchWorksDto} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public patchWorks(body: PatchWorksDto, id: number, options?: any) {
        return UserApiFp(this.configuration).patchWorks(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PinMaterialDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public pinMaterial(body: PinMaterialDTO, options?: any) {
        return UserApiFp(this.configuration).pinMaterial(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public ping(options?: any) {
        return UserApiFp(this.configuration).ping(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readAvailableStamps(id: number, options?: any) {
        return UserApiFp(this.configuration).readAvailableStamps(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readBuilding(id: number, options?: any) {
        return UserApiFp(this.configuration).readBuilding(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readBuildingNames(options?: any) {
        return UserApiFp(this.configuration).readBuildingNames(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readBuildingPartnerships(id: number, options?: any) {
        return UserApiFp(this.configuration).readBuildingPartnerships(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readBuildingPiece(id: number, options?: any) {
        return UserApiFp(this.configuration).readBuildingPiece(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readBuildingPieces(id: number, options?: any) {
        return UserApiFp(this.configuration).readBuildingPieces(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readBuildings(options?: any) {
        return UserApiFp(this.configuration).readBuildings(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readCompanies(options?: any) {
        return UserApiFp(this.configuration).readCompanies(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readCompany(id: number, options?: any) {
        return UserApiFp(this.configuration).readCompany(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readCompanyInUse(id: number, options?: any) {
        return UserApiFp(this.configuration).readCompanyInUse(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readCurrentSubscription(options?: any) {
        return UserApiFp(this.configuration).readCurrentSubscription(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readDeepPieceData(id: number, options?: any) {
        return UserApiFp(this.configuration).readDeepPieceData(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readDoc(id: number, options?: any) {
        return UserApiFp(this.configuration).readDoc(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readGroup(options?: any) {
        return UserApiFp(this.configuration).readGroup(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readGroupMembers(options?: any) {
        return UserApiFp(this.configuration).readGroupMembers(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readLabPdf(id: number, options?: any) {
        return UserApiFp(this.configuration).readLabPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readLabs(id: number, options?: any) {
        return UserApiFp(this.configuration).readLabs(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readMaterial(id: number, options?: any) {
        return UserApiFp(this.configuration).readMaterial(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readMaterials(options?: any) {
        return UserApiFp(this.configuration).readMaterials(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readOrdersOfGroup(id: number, options?: any) {
        return UserApiFp(this.configuration).readOrdersOfGroup(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readPartnership(id: number, options?: any) {
        return UserApiFp(this.configuration).readPartnership(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readPayment(id: number, options?: any) {
        return UserApiFp(this.configuration).readPayment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readPieceStamps(id: number, options?: any) {
        return UserApiFp(this.configuration).readPieceStamps(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [orderBy] 
     * @param {string} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readPieceWorks(id: number, orderBy?: string, sortBy?: string, options?: any) {
        return UserApiFp(this.configuration).readPieceWorks(id, orderBy, sortBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readPreferences(options?: any) {
        return UserApiFp(this.configuration).readPreferences(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} pieceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readReport(pieceId: number, options?: any) {
        return UserApiFp(this.configuration).readReport(pieceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readSchemaPdf(id: number, options?: any) {
        return UserApiFp(this.configuration).readSchemaPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readSchemas(id: number, options?: any) {
        return UserApiFp(this.configuration).readSchemas(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readStampInUse(id: number, options?: any) {
        return UserApiFp(this.configuration).readStampInUse(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readSubscriptions(options?: any) {
        return UserApiFp(this.configuration).readSubscriptions(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readWork(id: number, options?: any) {
        return UserApiFp(this.configuration).readWork(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ReattachPieceDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public reattachPieceInOrderGroups(body: ReattachPieceDTO, options?: any) {
        return UserApiFp(this.configuration).reattachPieceInOrderGroups(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RegisterDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public register(body: RegisterDTO, options?: any) {
        return UserApiFp(this.configuration).register(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public replaceSession(options?: any) {
        return UserApiFp(this.configuration).replaceSession(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resendConfirmationId(options?: any) {
        return UserApiFp(this.configuration).resendConfirmationId(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BuildingDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateBuilding(body: BuildingDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateBuilding(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {BuildingPieceDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateBuildingPiece(body: BuildingPieceDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateBuildingPiece(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CompanyDTO} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateCompany(body: CompanyDTO, id: string, options?: any) {
        return UserApiFp(this.configuration).updateCompany(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {MaterialDocDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateDoc(body: MaterialDocDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateDoc(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UpdateGroupDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateGroup(body: UpdateGroupDTO, options?: any) {
        return UserApiFp(this.configuration).updateGroup(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ChangePasswordDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateGroupUser(body: ChangePasswordDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateGroupUser(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {LabDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateLab(body: LabDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateLab(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {MaterialDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateMaterial(body: MaterialDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateMaterial(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {OrderDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateOrder(body: OrderDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateOrder(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {OrderGroupDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateOrderGroup(body: OrderGroupDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateOrderGroup(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SchemaDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateSchema(body: SchemaDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateSchema(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {StampDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateStamp(body: StampDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateStamp(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {WorkDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateWork(body: WorkDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateWork(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UpdateWorkDocsDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateWorkDocs(body: UpdateWorkDocsDTO, id: number, options?: any) {
        return UserApiFp(this.configuration).updateWorkDocs(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadDocPdf(id: number, options?: any) {
        return UserApiFp(this.configuration).uploadDocPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadLabPdf(id: number, options?: any) {
        return UserApiFp(this.configuration).uploadLabPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadSchemaPdf(id: number, options?: any) {
        return UserApiFp(this.configuration).uploadSchemaPdf(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public whoami(options?: any) {
        return UserApiFp(this.configuration).whoami(options)(this.fetch, this.basePath);
    }

}
/**
 * WorkApi - fetch parameter creator
 * @export
 */
export const WorkApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyWork(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling copyWork.');
            }
            const localVarPath = `/work/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLab(body: LabDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLab.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createLab.');
            }
            const localVarPath = `/work/{id}/labs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LabDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchema(body: SchemaDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSchema.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createSchema.');
            }
            const localVarPath = `/work/{id}/schemas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SchemaDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork(body: WorkDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createWork.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createWork.');
            }
            const localVarPath = `/piece/{id}/works`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WorkDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWork(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteWork.');
            }
            const localVarPath = `/work/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatchWorksDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorks(body: PatchWorksDto, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchWorks.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchWorks.');
            }
            const localVarPath = `/piece/{id}/works`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchWorksDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailableStamps(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readAvailableStamps.');
            }
            const localVarPath = `/work/{id}/stamps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabs(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readLabs.');
            }
            const localVarPath = `/work/{id}/labs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [orderBy] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceWorks(id: number, orderBy?: string, sortBy?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readPieceWorks.');
            }
            const localVarPath = `/piece/{id}/works`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemas(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readSchemas.');
            }
            const localVarPath = `/work/{id}/schemas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWork(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling readWork.');
            }
            const localVarPath = `/work/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWork(body: WorkDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateWork.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateWork.');
            }
            const localVarPath = `/work/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WorkDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWorkDocsDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkDocs(body: UpdateWorkDocsDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateWorkDocs.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateWorkDocs.');
            }
            const localVarPath = `/work/{id}/works`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateWorkDocsDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkApi - functional programming interface
 * @export
 */
export const WorkApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyWork(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).copyWork(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLab(body: LabDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).createLab(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchema(body: SchemaDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).createSchema(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork(body: WorkDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).createWork(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWork(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).deleteWork(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PatchWorksDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorks(body: PatchWorksDto, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).patchWorks(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailableStamps(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadStampDTO>> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).readAvailableStamps(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabs(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadLabDTO>> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).readLabs(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [orderBy] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceWorks(id: number, orderBy?: string, sortBy?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadWorkItemDTO>> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).readPieceWorks(id, orderBy, sortBy, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemas(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReadSchemaDTO>> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).readSchemas(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWork(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReadWorkDTO> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).readWork(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWork(body: WorkDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).updateWork(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateWorkDocsDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkDocs(body: UpdateWorkDocsDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = WorkApiFetchParamCreator(configuration).updateWorkDocs(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WorkApi - factory interface
 * @export
 */
export const WorkApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyWork(id: number, options?: any) {
            return WorkApiFp(configuration).copyWork(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {LabDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLab(body: LabDTO, id: number, options?: any) {
            return WorkApiFp(configuration).createLab(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SchemaDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchema(body: SchemaDTO, id: number, options?: any) {
            return WorkApiFp(configuration).createSchema(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWork(body: WorkDTO, id: number, options?: any) {
            return WorkApiFp(configuration).createWork(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWork(id: number, options?: any) {
            return WorkApiFp(configuration).deleteWork(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PatchWorksDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorks(body: PatchWorksDto, id: number, options?: any) {
            return WorkApiFp(configuration).patchWorks(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailableStamps(id: number, options?: any) {
            return WorkApiFp(configuration).readAvailableStamps(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLabs(id: number, options?: any) {
            return WorkApiFp(configuration).readLabs(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [orderBy] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPieceWorks(id: number, orderBy?: string, sortBy?: string, options?: any) {
            return WorkApiFp(configuration).readPieceWorks(id, orderBy, sortBy, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSchemas(id: number, options?: any) {
            return WorkApiFp(configuration).readSchemas(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWork(id: number, options?: any) {
            return WorkApiFp(configuration).readWork(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {WorkDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWork(body: WorkDTO, id: number, options?: any) {
            return WorkApiFp(configuration).updateWork(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateWorkDocsDTO} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkDocs(body: UpdateWorkDocsDTO, id: number, options?: any) {
            return WorkApiFp(configuration).updateWorkDocs(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * WorkApi - object-oriented interface
 * @export
 * @class WorkApi
 * @extends {BaseAPI}
 */
export class WorkApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public copyWork(id: number, options?: any) {
        return WorkApiFp(this.configuration).copyWork(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {LabDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public createLab(body: LabDTO, id: number, options?: any) {
        return WorkApiFp(this.configuration).createLab(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SchemaDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public createSchema(body: SchemaDTO, id: number, options?: any) {
        return WorkApiFp(this.configuration).createSchema(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {WorkDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public createWork(body: WorkDTO, id: number, options?: any) {
        return WorkApiFp(this.configuration).createWork(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public deleteWork(id: number, options?: any) {
        return WorkApiFp(this.configuration).deleteWork(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PatchWorksDto} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public patchWorks(body: PatchWorksDto, id: number, options?: any) {
        return WorkApiFp(this.configuration).patchWorks(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public readAvailableStamps(id: number, options?: any) {
        return WorkApiFp(this.configuration).readAvailableStamps(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public readLabs(id: number, options?: any) {
        return WorkApiFp(this.configuration).readLabs(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [orderBy] 
     * @param {string} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public readPieceWorks(id: number, orderBy?: string, sortBy?: string, options?: any) {
        return WorkApiFp(this.configuration).readPieceWorks(id, orderBy, sortBy, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public readSchemas(id: number, options?: any) {
        return WorkApiFp(this.configuration).readSchemas(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public readWork(id: number, options?: any) {
        return WorkApiFp(this.configuration).readWork(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {WorkDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public updateWork(body: WorkDTO, id: number, options?: any) {
        return WorkApiFp(this.configuration).updateWork(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UpdateWorkDocsDTO} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkApi
     */
    public updateWorkDocs(body: UpdateWorkDocsDTO, id: number, options?: any) {
        return WorkApiFp(this.configuration).updateWorkDocs(body, id, options)(this.fetch, this.basePath);
    }

}
