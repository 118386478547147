import React, { useMemo, useEffect, useState, useCallback } from 'react';

export const getDefaultInputHandler = (setState: any) => {
  const eventHandler = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      setState(event.target.value);
    },
    [setState],
  );

  return eventHandler;
};

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const useDebouncedChangeHandler = (defaultValue: string) => {
  const [value, setValue] = useState<string>(defaultValue);
  const debouncedValue = useDebounce<string>(value, 500);
  const handleChange = useMemo(() => {
    return (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setValue(event.target.value);
    };
  }, [setValue]);

  return {
    value: debouncedValue,
    handleChange,
  };
};
