import { keyBy } from 'lodash-es';
import * as yup from 'yup';
import { ReadWorkItemDTO, PatchWorksDto } from '../../../api/_out';
import { toDateInputValue } from '../../../utils/toDatetimeInputValue';

const dateRegexp = /(\d{4})-(\d{2})-(\d{2})/;

const itemSchema = yup.object({
  id: yup.string().required(),
  index: yup.string().required(),
  name: yup.string().required(),
  startDate: yup.string().matches(dateRegexp, { excludeEmptyString: true }),
  finishDate: yup.string().matches(dateRegexp, { excludeEmptyString: true }),
  reportDate: yup.string().matches(dateRegexp, { excludeEmptyString: true }),
  tags: yup.string(),
});

export const worksFormSchema = yup.object({
  works: yup.array(itemSchema).required(),
});

type FormItemData = ExludeNullValues<yup.InferType<typeof itemSchema>>;
export type WorkData = ReadWorkItemDTO;
export type WorksFormData = { works: FormItemData[] };

const maybeIsoToInputValue = (date: string | null) => {
  return date ? toDateInputValue(new Date(date)) : '';
};

const maybeInputValueToIso = (date: string) => {
  return date ? new Date(date).toISOString() : null;
};

export const toWorksFormData = (array: ReadWorkItemDTO[]): WorksFormData => {
  const works = array.map((item) => {
    const { id, index, name, startDate, finishDate, reportDate, tags } = item;

    return {
      id: String(id),
      index,
      name,
      startDate: maybeIsoToInputValue(startDate),
      finishDate: maybeIsoToInputValue(finishDate),
      reportDate: maybeIsoToInputValue(reportDate),
      tags: tags.join(', '),
    };
  });

  return { works };
};

const keysToCompare = [
  'finishDate',
  'index',
  'name',
  'reportDate',
  'startDate',
] as const;

type KeysToCompare = typeof keysToCompare[number];
type Comparable = Record<KeysToCompare, any>;

export const toPatchWorksDTO = (
  data: WorksFormData,
  original: ReadWorkItemDTO[],
): PatchWorksDto => {
  const hasSomeChange = (a: Comparable, b: Comparable) => {
    return keysToCompare.some((key) => {
      return a[key] !== b[key];
    });
  };
  const originalHashMap = keyBy(original, (item) => item.id);

  const works = data.works
    .map((work) => {
      const { finishDate, index, id, name, reportDate, startDate, tags } = work;

      return {
        finishDate: maybeInputValueToIso(finishDate),
        id,
        index,
        name,
        reportDate: maybeInputValueToIso(reportDate),
        startDate: maybeInputValueToIso(startDate),
        tags: tags
          .replaceAll(/(,[\s]+)/g, ',')
          .split(',')
          .filter((value) => !!value),
      };
    })
    .filter((work) => {
      const fromOriginal = originalHashMap[work.id];

      return (
        hasSomeChange(work, fromOriginal) ||
        work.tags.join() !== fromOriginal.tags.join()
      );
    })
    .map((work) => {
      const fromOriginal = originalHashMap[work.id];
      const { id: _, ...rest } = work;

      return {
        ...fromOriginal,
        ...rest,
      };
    });

  return { works: works as unknown as PatchWorksDto['works'] };
};
