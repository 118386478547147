import React, { useCallback } from 'react';
import DeviceDetector from 'device-detector-js';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { upperFirst } from 'lodash-es';
import { usePing } from './usePing';

const deviceDetector = new DeviceDetector();

const DeviceInfo = ({ useragent }: { useragent: string }) => {
  const { os, client, device } = deviceDetector.parse(useragent);

  return (
    <b>
      {upperFirst(device?.type)} {os?.name} OS {client?.name} {client?.version}
    </b>
  );
};

export const Ping = () => {
  const { conflict, logout, relog } = usePing();

  const handleContinueClick = useCallback(() => {
    relog();
  }, [relog]);

  return conflict !== null
    ? ReactDOM.createPortal(
        <div className={classNames('modal', 'is-active')}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="box">
              <div className="content">
                <p>
                  Этот аккаунт в данный момент используется на другом
                  устройстве:{' '}
                </p>
                <p className="message is-info">
                  <div className="message-body">
                    <DeviceInfo useragent={conflict} />
                  </div>
                </p>
                <p>
                  Продолжив использовать приложение на этом устройстве, вы
                  потеряете возможность использовать его на предыдущем
                </p>
              </div>
              <div className="field is-grouped is-grouped-right">
                <p className="control">
                  <a className="button is-light" onClick={logout}>
                    Выйти
                  </a>
                </p>
                <p className="control">
                  <a
                    className="button is-primary"
                    onClick={handleContinueClick}
                  >
                    Продолжить на этом устройстве
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>,
        document.getElementById('modal') as HTMLDivElement,
      )
    : null;
};
