import * as yup from 'yup';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { api } from '../../../../api';
import { useYupResolver } from '../../../../utils/useYupResolver';
import styles from './ReportForm.sass';
import classNames from 'classnames';

const { createReport, readReport } = api;

const reportFormSchema = yup.object({
  format: yup.string(),
  actTemplate: yup.string(),
});

type FormData = yup.InferType<typeof reportFormSchema>;
type CompleteFormData = FormData & {
  shouldNumberApplications: boolean;
  shouldPasteApprove: boolean;
};

const defaultValues: FormData = {
  format: 'two-sides',
  actTemplate: 'Копия верна',
};

const completeDefaultValues: CompleteFormData = {
  ...defaultValues,
  shouldNumberApplications: true,
  shouldPasteApprove: true,
};

// TODO: use backend for that
const getStorageKey = (buildingId: number) =>
  `report_preferences_of_building_with_id_${buildingId}`;

const getDefaultValues = (buildingId: number): CompleteFormData => {
  const key = getStorageKey(buildingId);
  const inStorage = localStorage.getItem(key);
  const data = inStorage ? JSON.parse(inStorage) : {};

  return {
    ...completeDefaultValues,
    ...data,
  };
};

const saveDefaultValues =
  (buildingId: number) => (values: CompleteFormData) => {
    const key = getStorageKey(buildingId);
    const data = JSON.stringify(values);

    localStorage.setItem(key, data);
  };

export const ReportForm = ({
  buildingId,
  pieceId,
  works: works,
}: {
  buildingId: number;
  pieceId: number;
  works: number[];
}) => {
  const defaultValues = useMemo(() => {
    return getDefaultValues(buildingId);
  }, [buildingId, getDefaultValues]);
  const queryClient = useQueryClient();
  const { register, handleSubmit, getValues } = useForm<FormData>({
    defaultValues: {
      format: defaultValues.format,
      actTemplate: defaultValues.actTemplate,
    },
    resolver: useYupResolver(reportFormSchema),
  });

  // useform acts very wierd with checkboxes, so have to do this
  const [shouldNumberApplications, setShouldNumberApplications] = useState(
    defaultValues.shouldNumberApplications,
  );

  const [shouldPasteApprove, setShouldPasteApprove] = useState(
    defaultValues.shouldPasteApprove,
  );

  const [forSelectedOnly, setForSelectedOnly] = useState(true);

  const handleApplicationsChange = () => {
    setShouldNumberApplications((value) => !value);
  };

  const handleApproveChange = () => {
    setShouldPasteApprove((value) => !value);
  };

  const handleForSelectedChange = () => {
    setForSelectedOnly((prev) => !prev);
  };

  const { mutate: requestReport } = useMutation(
    (data: FormData) => {
      const body = {
        ...(data as ExludeNullValues<FormData>),
        shouldNumberApplications,
        shouldPasteApprove,
        works: (forSelectedOnly ? works : null) as number[],
      };

      return createReport.request(body, pieceId);
    },
    {
      onSuccess: () => {
        const formData = getValues();

        queryClient.invalidateQueries([readReport.id, pieceId]);

        saveDefaultValues(buildingId)({
          ...formData,
          shouldNumberApplications,
          shouldPasteApprove,
        });
      },
    },
  );

  const submit = (data: FormData) => {
    return requestReport(data);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="field">
        <div className="control">
          <label className="checkbox">
            <input
              type="checkbox"
              defaultChecked={defaultValues.shouldPasteApprove}
              onChange={handleApproveChange}
            />
            <span className="ml-1">Заверять документы</span>
          </label>
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="checkbox">
            <input
              type="checkbox"
              defaultChecked={defaultValues.shouldNumberApplications}
              onChange={handleApplicationsChange}
            />
            <span className="ml-1">Нумеровать приложения</span>
          </label>
        </div>
      </div>

      <div className="field">
        <label className="label is-small">Формат</label>
        <div className="control">
          <div className="select is-small is-expanded">
            <select {...register('format')} defaultValue="two-sides">
              <option value="one-file">В одном файле</option>
              <option value="two-sides">
                В одном файле под двустороннюю печать
              </option>
              <option value="dimension-selective">По форматам документа</option>
            </select>
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label is-small">Текст заверки копии</label>
        <textarea
          className={classNames('textarea is-small', styles.actTemplateControl)}
          rows={2}
          {...register('actTemplate')}
        />
      </div>

      <div className="field">
        <div className="control">
          <label className="checkbox">
            <input
              type="checkbox"
              defaultChecked
              onChange={handleForSelectedChange}
            />
            <span className="ml-1">Только по отображаемым работам</span>
          </label>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button type="submit" className="button is-small is-info">
            Получить
          </button>
        </div>
      </div>
    </form>
  );
};
