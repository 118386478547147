import { isFunction } from 'lodash-es';
import { Dispatch, SetStateAction, useState } from 'react';

export const useStoredBoolean = (key: string, defaultValue = true) => {
  const inStore = localStorage.getItem(key);

  if (inStore !== null) {
    defaultValue = inStore === 'true' ? true : false;
  }

  const [state, defaultSetState] = useState<boolean>(defaultValue);

  const setState: Dispatch<SetStateAction<boolean>> = (setStateAction) => {
    defaultSetState((prevState) => {
      const nextValue = isFunction(setStateAction)
        ? setStateAction(prevState)
        : setStateAction;
      localStorage.setItem(key, nextValue === true ? 'true' : 'false');

      return nextValue;
    });
  };

  return [state, setState] as const;
};
