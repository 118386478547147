import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { api } from '../../../api';
import { useDefaultSuccessHandler } from '../../../api/useDefaultApiHandler';
import { ReadMaterialDTO } from '../../../api/_out';
import { composeRoute } from '../../../routing/routing';
import { FormProps } from '../../../utils/formProps';
import { useDebouncedChangeHandler } from '../../../utils/getDefaultInputHandler';
import { toDateInputValue } from '../../../utils/toDatetimeInputValue';
import { millenium } from '../../../vars/millenium';
import styles from './WorkDocs.sass';

const {
  readMaterials,
  readWork,
  updateWorkDocs,
  downloadDocPdf,
  readPreferences,
} = api;

type FormData = Record<number, boolean>;

type MoreProps = {
  materials: ReadMaterialDTO[];
  areSelected: FormData;
  onChange: (data: FormData) => void;
};

const Form = ({
  areSelected,
  materials,
  onSubmit,
  onChange,
}: FormProps<FormData> & MoreProps) => {
  const dataRef = useRef<Record<number, boolean>>(areSelected);

  const select = useCallback(
    (key: number) => {
      const nextValue = {
        ...dataRef.current,
        [key]: true,
      };

      onChange((dataRef.current = nextValue));
    },
    [dataRef],
  );

  const unselect = useCallback(
    (key: number) => {
      const { [key]: _, ...nextValue } = dataRef.current;

      onChange((dataRef.current = nextValue));
    },
    [dataRef],
  );

  const showSuccessNotification = useDefaultSuccessHandler(
    'Используемые материалы успешно сохранены',
  );

  const getPdf = (docId: number) => {
    downloadDocPdf.request(docId).then((data) => {
      window.open(data.url);
    });
  };

  const { mutate } = useMutation(
    (data: FormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: () => {
        showSuccessNotification();
      },
    },
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.checked;
      const key = Number(event.currentTarget.name);

      if (value) {
        select(key);
      } else {
        unselect(key);
      }
    },
    [select, unselect],
  );

  const handleSubmit = () => {
    return mutate(dataRef.current);
  };

  return (
    <div className="content">
      <div
        className="grid-table"
        style={{ gridTemplateColumns: 'repeat(2, auto)' }}
      >
        <div className="head">
          <div className="cell">Наименование</div>
          <div className="cell">Документы</div>
        </div>
        <div className="body">
          {materials
            .filter((material) => material.docs.length > 0)
            .map((material) => {
              return (
                <div className="item is-revealing">
                  <div className="cell">
                    <span className="icon-text">
                      <span>{material.name}</span>
                    </span>
                  </div>
                  <div
                    className="cell"
                    style={{ flexFlow: 'column wrap', alignItems: 'unset' }}
                  >
                    {material.docs.map((doc, index, docs) => {
                      const isLast = index === docs.length - 1;

                      return (
                        <div
                          className={classNames(
                            'has-background-light p-2 is-size-7 is-flex is-align-items-center is-rounded',
                            isLast ? 'mb-0' : 'mb-1',
                          )}
                          key={doc.id}
                        >
                          <label className="checkbox mr-3">
                            <input
                              type="checkbox"
                              name={String(doc.id)}
                              onChange={handleChange}
                              defaultChecked={!!dataRef.current[doc.id]}
                            />
                          </label>
                          <div>
                            <b>
                              <span>{doc.name} </span>
                              <span>№{doc.index} </span>
                            </b>
                            <div>
                              <span>
                                {new Date(doc.from).toLocaleDateString()}
                              </span>
                              {!!doc.to && (
                                <span>
                                  —{new Date(doc.to).toLocaleDateString()}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="ml-auto">
                            {!!doc.pdf && (
                              <button
                                className="button is-small"
                                onClick={() => getPdf(doc.id)}
                              >
                                <span className="icon is-small">
                                  <i className="fas fa-file-pdf"></i>
                                </span>
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="is-clearfix">
        <div className="buttons is-pulled-right">
          <button
            className="button is-info"
            type="button"
            onClick={handleSubmit}
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

const DateSearch = ({
  defaultValue,
  onChange,
  ...rest
}: Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'defaultValue' | 'onChange'
> & { defaultValue: string; onChange: (value: string) => void }) => {
  const { value, handleChange: handleDateChange } = useDebouncedChangeHandler(
    String(defaultValue),
  );

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  return (
    <input
      {...rest}
      className="input"
      type="date"
      placeholder="Дата"
      defaultValue={value}
      onChange={handleDateChange}
    />
  );
};

export const WorkDocs = ({ id }: { id: number }) => {
  const { data: materials } = useQuery(readMaterials.id, () => {
    return readMaterials.request();
  });

  const { data: work } = useQuery(
    [readWork.id, id],
    () => {
      return readWork.request(id);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: preferences } = useQuery(readPreferences.id, () => {
    return readPreferences.request();
  });

  const pinned = useMemo(() => {
    return new Set(preferences?.pinnedMaterials ?? []);
  }, [preferences]);

  const [ids, setIds] = useState<Set<number>>(new Set([]));

  const [filtered, setFiltered] = useState<ReadMaterialDTO[]>([]);
  const { value: nameSearch, handleChange: handleNameChange } =
    useDebouncedChangeHandler('');
  const [dateSearch, setDateSearch] = useState<string>('');

  const handleDateSearchChange = useCallback(
    (value: string) => {
      setDateSearch(value);
    },
    [setDateSearch],
  );

  const [showSelectedOnly, setShowSelectedOnly] = useState<boolean>(false);

  const handleShowSelectedSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;

      setShowSelectedOnly(checked);
    },
    [setShowSelectedOnly],
  );

  useEffect(() => {
    setFiltered(() => {
      const nextValue = showSelectedOnly
        ? materials?.reduce((acc, material) => {
            const onlySelectedDocs = material.docs.filter((doc) => {
              return ids.has(doc.id);
            });

            return onlySelectedDocs.length > 0
              ? [...acc, { ...material, docs: onlySelectedDocs }]
              : acc;
          }, [] as ReadMaterialDTO[])
        : materials?.filter(({ name, docs }) => {
            const isWithinDate =
              dateSearch === ''
                ? true
                : docs.some((doc) => {
                    const from = doc.from
                      ? new Date(doc.from)
                      : new Date(Date.now() - millenium);
                    const to = doc.to
                      ? new Date(doc.to)
                      : new Date(Date.now() + millenium);
                    const date = new Date(dateSearch);

                    return date >= from && date <= to;
                  });

            return (
              name.toLowerCase().match(nameSearch.toLowerCase()) && isWithinDate
            );
          });

      return (
        nextValue
          ?.sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
          .sort((a, b) => {
            if (pinned.has(a.id) === pinned.has(b.id)) return 0;
            if (pinned.has(a.id)) return -1;
            if (pinned.has(b.id)) return 1;

            return 0;
          }) ?? []
      );
    });
  }, [materials, nameSearch, dateSearch, pinned, showSelectedOnly, ids]);

  const defaultValues = useMemo(() => {
    return (
      work?.docIds.reduce((acc, docId) => {
        return { ...acc, [docId]: true };
      }, {}) ?? {}
    );
  }, [work]);

  const handleSubmit = useCallback((data: FormData) => {
    return updateWorkDocs.request(
      { docIds: Object.keys(data).map((key) => Number(key)) },
      id,
    );
  }, []);

  const handleFormChange = useCallback(
    (data: FormData) => {
      const nextValue = Object.keys(data).map((key) => Number(key));

      setIds(new Set(nextValue));
    },
    [setIds],
  );

  // DOES NOT WORK PROPERLY, UPDATE IDS EVERY TIME TAB IS FOCUSED
  useEffect(() => {
    const ids = work?.docIds ?? [];

    setIds(new Set(ids));
  }, [work]);

  return (
    <>
      <div className="level">
        <div className="level-left"></div>
        <div className="level-right">
          <div className="level-item">
            <div className="buttons">
              <Link
                to={composeRoute('/materials/:materialId', {
                  materialId: -1,
                })}
                target="_blank"
                type="button"
                className="button is-primary"
              >
                Новый материал
              </Link>
            </div>
          </div>
        </div>
      </div>
      {work && materials ? (
        <div className="box">
          <div className="columns is-align-items-end">
            <div className="column is-narrow">
              <div className="field">
                <label
                  className={classNames(
                    'checkbox is-flex is-align-items-center',
                    styles.switchContainer,
                  )}
                >
                  <input
                    type="checkbox"
                    onChange={handleShowSelectedSwitchChange}
                    checked={showSelectedOnly}
                  />
                  <span className="ml-2">Только выбранные</span>
                </label>
              </div>
            </div>
            <div className="column ">
              <div className="field">
                <label className="label is-small">Наименование материала</label>
                <p className="control has-icons-left">
                  <input
                    className="input"
                    type="text"
                    disabled={showSelectedOnly}
                    placeholder="Поиск"
                    defaultValue={nameSearch}
                    onChange={handleNameChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-search"></i>
                  </span>
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="field">
                <label className="label is-small">
                  С документом действительным на
                </label>
                <p className="control">
                  {work ? (
                    <DateSearch
                      defaultValue={
                        work.reportDate
                          ? toDateInputValue(new Date(work.reportDate))
                          : ''
                      }
                      disabled={showSelectedOnly}
                      onChange={handleDateSearchChange}
                    />
                  ) : null}
                </p>
              </div>
            </div>
          </div>

          <Form
            areSelected={defaultValues}
            materials={filtered}
            onSubmit={handleSubmit}
            onChange={handleFormChange}
          />
        </div>
      ) : null}
    </>
  );
};
