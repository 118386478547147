import * as yup from 'yup';
import { CreateSubscriptionBodyDTO } from '../../../api/_out/api';

export type SubscriptionBody = CreateSubscriptionBodyDTO;

export const subscriptionFormSchema = yup.object({
  from: yup
    .string()
    .matches(/(\d{4})-(\d{2})-(\d{2})/, { excludeEmptyString: true })
    .required('')
    .test({
      test: (from: string | undefined) => {
        if (!from) return false;

        const asDate = new Date(from);
        const now = new Date();

        now.setHours(0, 0, 0, 0);

        return asDate > now;
      },
      message: 'Дата начала подписки не может быть датой в прошлом',
    }),
  to: yup
    .string()
    .matches(/(\d{4})-(\d{2})-(\d{2})/, { excludeEmptyString: true })
    .required('')
    .test({
      test: (to: string | undefined, context) => {
        const { from } = context.parent;

        if (!to || !from) return false;

        const fromDate = new Date(from);
        const toDate = new Date(to);

        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(0, 0, 0, 0);

        return toDate > fromDate;
      },
      message: 'Дата окончания подписки должна быть позднее даты начала',
    }),
  users: yup.string().matches(/^\d+$/),
});

export type SubscriptionFormData = yup.InferType<typeof subscriptionFormSchema>;

export const toSubscriptionDTO = (
  data: SubscriptionFormData,
): SubscriptionBody => {
  const { from, to, users } = data;

  return {
    from: new Date(from).toISOString(),
    to: new Date(to).toISOString(),
    users: Number(users),
  };
};
