import classNames from 'classnames';
import { useQuery, useQueryClient } from 'react-query';
import { api } from '../../../api';
import { Help } from '../../../components/Help/Help';
import { useHelp } from '../../../components/Help/useHelp';
import { StampForm } from './StampForm';
import { StampContext, toStampFormData } from './StampForm.misc';

const { readPieceStamps: readStamps } = api;

const getDefaultFormValues = () => ({
  people: [] as any[],
});

export const Stamps = ({ pieceId }: { pieceId: number }) => {
  const queryClient = useQueryClient();
  const { data: stamps } = useQuery([readStamps.id, pieceId], () => {
    return readStamps.request(pieceId).then((items) => {
      return items.sort((a, b) => b.id - a.id);
    });
  });

  const handleSubmit = () => Promise.resolve();

  const handleFormSuccess = () => {
    queryClient.invalidateQueries([readStamps.id, pieceId]);
  };

  const {
    isVisible: showHelp,
    toggle: toggleHelp,
    close: closeHelp,
  } = useHelp('stamps');

  return (
    <>
      <div className="level">
        <div className="level-left">
          <div className="level-item"></div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div className="buttons">
              <button
                className={classNames(
                  showHelp ? 'has-text-info' : 'has-text-grey',
                  'button is-white',
                )}
                onClick={toggleHelp}
              >
                <i className="fas fa-question-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      {showHelp && (
        <Help close={closeHelp}>
          Каждый штамп представляет собой список лиц, подписвающих
          исполнительные схемы. Этот список объединяется с другой информацией о
          разделе и используется для добавления штампов на исполнительные схемы.
          В ИД попадает полностью оформленный штамп с рамкой.
        </Help>
      )}

      {stamps ? (
        <StampContext.Provider value={{ pieceId }}>
          <div className="columns is-multiline is-align-items-stretch">
            <div key={-1} className="column is-half">
              <div className="box has-background-success-light is-h-100">
                <StampForm
                  id={-1}
                  defaultValues={getDefaultFormValues()}
                  onSubmit={handleSubmit}
                  onSuccess={handleFormSuccess}
                />
              </div>
            </div>
            {stamps.map((stamp) => (
              <div key={stamp.id} className="column is-half">
                <div className="box is-h-100">
                  <StampForm
                    id={stamp.id}
                    defaultValues={toStampFormData(stamp)}
                    onSubmit={handleSubmit}
                    onSuccess={handleFormSuccess}
                  />
                </div>
              </div>
            ))}
          </div>
        </StampContext.Provider>
      ) : null}
    </>
  );
};
