import * as yup from 'yup';
import { ReadWorkDTO, WorkDTO } from '../../../api/_out';
import { toDateInputValue } from '../../../utils/toDatetimeInputValue';

type NullableFields = 'startDate' | 'finishDate' | 'reportDate';

export type WorkData = WithNulls<ReadWorkDTO, NullableFields>;
export type WorkBody = PartialBy<WithNulls<WorkDTO, NullableFields>, 'docIds'>;

export const getFormSchema = (firstLabDate: Date, lastLabDate: Date) =>
  yup.object({
    name: yup
      .string()
      .required('Наименование не может быть пустым')
      .max(200, 'Максимум 200 символов'),
    index: yup.string(),
    land: yup.string(),
    next: yup.string(),
    startDate: yup
      .string()
      .typeError({})
      .strict()
      .matches(/(\d{4})-(\d{2})-(\d{2})/, { excludeEmptyString: true })
      .test({
        name: 'shouldBeBeforeFirstLab',
        message: `Дата начала должна быть не позже, чем дата первой лабораторной работы (${firstLabDate.toLocaleDateString()})`,
        test: (value) => {
          if (!value) {
            return true;
          }

          const asDate = new Date(value ?? '');

          return asDate.valueOf() <= firstLabDate.valueOf();
        },
      }),
    finishDate: yup
      .string()
      .typeError({})
      .strict()
      .matches(/(\d{4})-(\d{2})-(\d{2})/, { excludeEmptyString: true })
      .test({
        name: 'shouldBeAfterStartDate',
        message: 'Дата окончания должна быть не раньше, чем дата начала',
        test: (value, context) => {
          const { parent } = context;
          const startDate: string = parent.startDate;

          if (!value || !startDate) {
            return true;
          }

          const startAsDate = new Date(startDate);
          const finishAsDate = new Date(value ?? '');

          return startAsDate.valueOf() - finishAsDate.valueOf() <= 0;
        },
      }),
    standard: yup.string(),
    projectDoc: yup.string(),
    reportDate: yup
      .string()
      .typeError({})
      .strict()
      .matches(/(\d{4})-(\d{2})-(\d{2})/, { excludeEmptyString: true })
      .test({
        name: 'shouldBeAfterStartDate',
        message: 'Дата акта должна быть не раньше, чем дата окончания',
        test: (value, context) => {
          const { parent } = context;
          const finishDate: string = parent.finishDate;

          if (!value || !finishDate) {
            return true;
          }

          const startAsDate = new Date(finishDate);
          const finishAsDate = new Date(value ?? '');

          return startAsDate.valueOf() - finishAsDate.valueOf() <= 0;
        },
      })
      .test({
        name: 'shouldBeAfterLastLab',
        message: `Дата акта должна быть не раньше, чем дата последней лабораторной работы (${lastLabDate.toLocaleDateString()})`,
        test: (value) => {
          if (!value) {
            return true;
          }

          const asDate = new Date(value ?? '');

          return asDate.valueOf() >= lastLabDate.valueOf();
        },
      }),
    comment: yup.string(),
    tags: yup.string(),
  });

export type WorkFormData = ExludeNullValues<
  yup.InferType<ReturnType<typeof getFormSchema>>
>;

export const toWorkFormData = (work: WorkData): WorkFormData => {
  const { name, land, standard, projectDoc, comment, index, next, tags } = work;
  const startDate = work.startDate && new Date(work.startDate);
  const finishDate = work.finishDate && new Date(work.finishDate);
  const reportDate = work.reportDate && new Date(work.reportDate);

  return {
    comment,
    finishDate: finishDate ? toDateInputValue(finishDate) : '',
    index,
    land,
    name,
    next,
    projectDoc,
    reportDate: reportDate ? toDateInputValue(reportDate) : '',
    standard,
    startDate: startDate ? toDateInputValue(startDate) : '',
    tags: tags.join(', '),
  };
};

export const toWorkDTO = (data: WorkFormData): WorkBody => {
  const {
    comment,
    finishDate,
    index,
    land,
    name,
    next,
    projectDoc,
    reportDate,
    standard,
    startDate,
    tags,
  } = data;

  return {
    comment,
    finishDate: finishDate ? new Date(finishDate).toISOString() : null,
    index,
    land,
    name,
    next,
    projectDoc,
    reportDate: reportDate ? new Date(reportDate).toISOString() : null,
    standard,
    startDate: startDate ? new Date(startDate).toISOString() : null,
    tags: tags
      .replaceAll(/(,[\s]+)/g, ',')
      .split(',')
      .filter((value) => !!value),
  };
};
