// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aZPBEyq9iU9GbhlreinP{display:flex;position:fixed;width:100%;height:100%;background:#fff;align-items:center;justify-content:center}.waiclZor0UyVKUFokv9A{width:300px;max-width:100%}.ePGtyRO2e5H1VZbrXA5V{margin:0 auto;margin-bottom:32px;max-width:90%}", "",{"version":3,"sources":["webpack://./src/pages/Auth/AuthPage.sass"],"names":[],"mappings":"AAAA,sBAAW,YAAY,CAAC,cAAc,CAAC,UAAU,CAAC,WAAW,CAAC,eAAe,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,sBAAS,WAAW,CAAC,cAAc,CAAC,sBAAM,aAAa,CAAC,kBAAkB,CAAC,aAAa","sourcesContent":[".container{display:flex;position:fixed;width:100%;height:100%;background:#fff;align-items:center;justify-content:center}.content{width:300px;max-width:100%}.logo{margin:0 auto;margin-bottom:32px;max-width:90%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "aZPBEyq9iU9GbhlreinP",
	"content": "waiclZor0UyVKUFokv9A",
	"logo": "ePGtyRO2e5H1VZbrXA5V"
};
export default ___CSS_LOADER_EXPORT___;
