// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iu40ytfclzrFtxMfeYAL{padding-left:240px;padding-top:3.25rem}.xe7x_ICxfHTu5AcDFnIQ{position:fixed;width:240px;left:0;top:0;bottom:0}._PJlfgNnHSQF8wTUCoLN{position:fixed;height:3.25rem;left:240px;right:0;top:0;z-index:1}", "",{"version":3,"sources":["webpack://./src/modules/App/App.sass"],"names":[],"mappings":"AAAA,sBAAM,kBAAkB,CAAC,mBAAmB,CAAC,sBAAS,cAAc,CAAC,WAAW,CAAC,MAAM,CAAC,KAAK,CAAC,QAAQ,CAAC,sBAAQ,cAAc,CAAC,cAAc,CAAC,UAAU,CAAC,OAAO,CAAC,KAAK,CAAC,SAAS","sourcesContent":[".body{padding-left:240px;padding-top:3.25rem}.sidebar{position:fixed;width:240px;left:0;top:0;bottom:0}.topbar{position:fixed;height:3.25rem;left:240px;right:0;top:0;z-index:1}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "iu40ytfclzrFtxMfeYAL",
	"sidebar": "xe7x_ICxfHTu5AcDFnIQ",
	"topbar": "_PJlfgNnHSQF8wTUCoLN"
};
export default ___CSS_LOADER_EXPORT___;
