// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ohboe4WsvFbhylubqpi3{display:grid;align-items:start}.SHFBHM50yFK3t8ZcXJMq,.A57kkELU8jdaW9q0PnhU,.alJil0HkvcmjTyDrY_5n{display:contents}.SHFBHM50yFK3t8ZcXJMq.hzDV79H7vB88A16ev8RK .v7VjB8EZysUSuoi5Q85z,.A57kkELU8jdaW9q0PnhU.hzDV79H7vB88A16ev8RK .v7VjB8EZysUSuoi5Q85z,.alJil0HkvcmjTyDrY_5n.hzDV79H7vB88A16ev8RK .v7VjB8EZysUSuoi5Q85z{align-items:center}.v7VjB8EZysUSuoi5Q85z{align-items:start;border-bottom:1px solid #dbdbdb;display:flex;padding:.75rem 1rem;height:100%}.v7VjB8EZysUSuoi5Q85z:last-child{justify-content:end}.SHFBHM50yFK3t8ZcXJMq{font-weight:bold}.alJil0HkvcmjTyDrY_5n:last-child .v7VjB8EZysUSuoi5Q85z{border-bottom:0}", "",{"version":3,"sources":["webpack://./src/pages/Work/WorkTable.sass"],"names":[],"mappings":"AAAA,sBAAO,YAAY,CAAC,iBAAiB,CAAC,kEAAkB,gBAAgB,CAAC,mMAA+D,kBAAkB,CAAC,sBAAM,iBAAiB,CAAC,+BAA+B,CAAC,YAAY,CAAC,mBAAmB,CAAC,WAAW,CAAC,iCAAiB,mBAAmB,CAAC,sBAAM,gBAAgB,CAAC,uDAAuB,eAAe","sourcesContent":[".items{display:grid;align-items:start}.head,.body,.item{display:contents}.head.readonly .cell,.body.readonly .cell,.item.readonly .cell{align-items:center}.cell{align-items:start;border-bottom:1px solid #dbdbdb;display:flex;padding:.75rem 1rem;height:100%}.cell:last-child{justify-content:end}.head{font-weight:bold}.item:last-child .cell{border-bottom:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"items": "Ohboe4WsvFbhylubqpi3",
	"head": "SHFBHM50yFK3t8ZcXJMq",
	"body": "A57kkELU8jdaW9q0PnhU",
	"item": "alJil0HkvcmjTyDrY_5n",
	"readonly": "hzDV79H7vB88A16ev8RK",
	"cell": "v7VjB8EZysUSuoi5Q85z"
};
export default ___CSS_LOADER_EXPORT___;
