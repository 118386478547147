import React, { FC } from 'react';
import {
  Route as DefaultRoute,
  Redirect as DefaultRedirect,
} from 'react-router-dom';
export { BrowserRouter, Switch } from 'react-router-dom';

type BuildingRoute = {
  buildingId: number;
};

type PieceRoute = {
  buildingId: number;
  pieceId: number;
};

type WorkRoute = {
  pieceId: number;
  workId: number;
};

type OrderRoute = {
  orderGroupId: number;
  orderId: number;
};

type Routes = {
  '/': {};
  '/buildings': {};
  '/buildings/:buildingId': BuildingRoute;
  '/buildings/:buildingId/information': BuildingRoute;
  '/buildings/:buildingId/orders': BuildingRoute;
  '/buildings/:buildingId/orders/:orderGroupId/:orderId': BuildingRoute &
    OrderRoute;
  '/buildings/:buildingId/pieces': BuildingRoute;
  '/buildings/:buildingId/pieces/:pieceId': PieceRoute;
  '/buildings/:buildingId/pieces/:pieceId/information': PieceRoute;
  '/buildings/:buildingId/pieces/:pieceId/stamps': PieceRoute;
  '/buildings/:buildingId/pieces/:pieceId/works': PieceRoute;
  '/companies': {};
  '/companies/:companyId': {
    companyId: number;
  };
  '/materials': {};
  '/materials/:materialId': {
    materialId: number;
  };
  '/pieces/:pieceId/works/:workId': WorkRoute;
  '/pieces/:pieceId/works/:workId/information': WorkRoute;
  '/pieces/:pieceId/works/:workId/labs': WorkRoute;
  '/pieces/:pieceId/works/:workId/schemas': WorkRoute;
  '/pieces/:pieceId/works/:workId/materials': WorkRoute;
  '/account': {};
  '/account/team': {};
  '/account/members': {};
  '/account/subscriptions': {};
  '/account/subscriptions/new': {};
  '/payment/:paymentId': { paymentId: number };
};

type HasNoProperties<T extends {}> = keyof T extends never ? true : false;
type Params<T extends keyof Routes> = HasNoProperties<Routes[T]> extends true
  ? []
  : [Routes[T]];

export const composeRoute = <K extends keyof Routes>(
  route: K,
  ...tuple: Params<K>
) => {
  const [params] = tuple;

  if (!params) return route;

  const replaced = route.split('/').reduce((acc, chunk) => {
    const isPathParam = chunk.startsWith(':');

    if (!isPathParam) return [...acc, chunk];

    const key = chunk.slice(1) as keyof Routes[K];
    const value = (params as Routes[K])[key];

    return [...acc, String(value)];
  }, [] as string[]);

  return replaced.join('/');
};

export const getRoute = <K extends keyof Routes>(route: K) => {
  return route;
};

type PropsOf<T extends { props: Record<string, any> }> = T['props'];
type ReplaceWithKey<
  T extends React.Component,
  P extends keyof PropsOf<T>,
> = PropsOf<T> & { [key in P]?: keyof Routes };
type WithTypes<T extends React.Component, P extends keyof PropsOf<T>> = FC<
  ReplaceWithKey<T, P>
>;

export const Route: WithTypes<DefaultRoute, 'path'> = (props) => {
  return <DefaultRoute {...props} />;
};

export const Redirect: WithTypes<DefaultRedirect, 'from'> = (props) => {
  return <DefaultRedirect {...props} />;
};
