import { createContext } from 'react';
import * as yup from 'yup';
import { SchemaDTO, ReadSchemaDTO } from '../../../api/_out/api';

export type SchemaData = WithNulls<ReadSchemaDTO, 'stamp'>;
export type SchemaBody = WithNulls<SchemaDTO, 'stampId'>;

export const schemaFormSchema = yup.object({
  id: yup.string().required(),
  index: yup.string(),
  name: yup.string().required(),
  schemaName: yup.string(),
  stampId: yup.string(),
  pdf: yup.mixed(),
});

export type SchemaFormData = ExludeNullValues<
  yup.InferType<typeof schemaFormSchema>
>;

export type SchemaContextType = {
  workId: number;
};

export const SchemaContext = createContext<SchemaContextType>({ workId: -1 });

export const toSchemaFormData = (data: SchemaData): SchemaFormData => {
  const { id, name, schemaName, index, stamp } = data;

  return {
    id: String(id),
    name,
    schemaName,
    index,
    stampId: stamp ? String(stamp.id) : '',
    pdf: undefined,
  };
};

export const toSchemaDTO = (data: SchemaFormData): SchemaBody => {
  const { name, schemaName, index, stampId } = data;

  return {
    name,
    schemaName,
    index,
    stampId: stampId ? Number(stampId) : null,
  };
};
