// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y1ZUZTBlv0CG3Rs1fh45{border:1px dashed;border-color:#b5b5b5;background:transparent;border-radius:4px;color:#0a0a0a;transform:scale(1);transition:.1s ease-in-out 0s all}.y1ZUZTBlv0CG3Rs1fh45:hover{border-color:#7a7a7a}", "",{"version":3,"sources":["webpack://./src/pages/Building/BuildingOrders/Order/Order.sass"],"names":[],"mappings":"AAAA,sBAAK,iBAAiB,CAAC,oBAAoB,CAAC,sBAAsB,CAAC,iBAAiB,CAAC,aAAa,CAAC,kBAAkB,CAAC,iCAAiC,CAAC,4BAAW,oBAAoB","sourcesContent":[".new{border:1px dashed;border-color:#b5b5b5;background:transparent;border-radius:4px;color:#0a0a0a;transform:scale(1);transition:.1s ease-in-out 0s all}.new:hover{border-color:#7a7a7a}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new": "y1ZUZTBlv0CG3Rs1fh45"
};
export default ___CSS_LOADER_EXPORT___;
