import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { api } from '../../api';
import { composeRoute } from '../../routing/routing';
import { useSubscription } from '../Subscription/useSubscription';
import { useWhoami } from '../Whoami/useWhoami';

const { readGroupMembers } = api;

const SubscriptionInfo = () => {
  const { data: subscription } = useSubscription();

  const { data: members } = useQuery(readGroupMembers.id, () => {
    return readGroupMembers.request();
  });

  const expirationDate = useMemo(() => {
    return subscription
      ? new Date(subscription.expiresAt).toLocaleDateString()
      : '...';
  }, [subscription]);

  const isExpired = subscription && subscription.expiresAt === null;

  return (
    <Link
      to={composeRoute('/account')}
      className={classNames(
        'is-size-7 py-1 px-3 notification is-light has-text-right',
        subscription?.isExceeded || isExpired ? 'is-danger' : 'is-info',
      )}
    >
      {isExpired ? (
        <div>Нет активной подписки</div>
      ) : (
        <>
          <div>Активна до: {expirationDate}</div>
          <div>
            Пользователи: {members?.members.length ?? 0}/
            {subscription?.usersAllowed ?? 0}
          </div>
        </>
      )}
    </Link>
  );
};

export const Navbar = () => {
  const { logout, data: whoami } = useWhoami();

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-end">
        {!!process.env.GET_IN_TOUCH && (
          <div className="navbar-item">
            <Tippy
              content={
                <div className="box p-1">
                  <div className="text is-size-7">
                    Связаться с нами в Telegram
                  </div>
                </div>
              }
              interactive={false}
              trigger="mouseenter"
              delay={[400, 0]}
            >
              <a
                type="button"
                className="button is-white"
                href={process.env.GET_IN_TOUCH}
                target="_blank"
              >
                <span className="icon">
                  <i className="fa-brands fa-telegram"></i>
                </span>
              </a>
            </Tippy>
          </div>
        )}

        {whoami && (
          <>
            {whoami.ownerOf && (
              <div className="navbar-item">
                <SubscriptionInfo />
              </div>
            )}

            <div className="navbar-item">
              <div className="field has-addons">
                <div className="control">
                  <Tippy
                    content={
                      <div className="box p-1">
                        <div className="text is-size-7">{whoami.username}</div>
                      </div>
                    }
                    interactive={false}
                    trigger="mouseenter"
                    delay={[400, 0]}
                  >
                    <button className="button is-white is-static">
                      <b>{whoami.username.charAt(0).toUpperCase()}</b>
                    </button>
                  </Tippy>
                </div>
                <div className="control">
                  <Tippy
                    content={
                      <div className="box p-1">
                        <div className="text is-size-7">Выйти</div>
                      </div>
                    }
                    interactive={false}
                    trigger="mouseenter"
                    delay={[400, 0]}
                  >
                    <button type="button" className="button " onClick={logout}>
                      <span className="icon is-small">
                        <i className="fas fa-sign-out"></i>
                      </span>
                    </button>
                  </Tippy>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="navbar-item"></div>
      </div>
    </nav>
  );
};
