import { useCallback, useState } from 'react';

type OrderBy = 'ASC' | 'DESC';

export const useSort = <T extends string>(
  defaultKey: T,
  defaultOrder: OrderBy = 'DESC',
) => {
  const [key, setKey] = useState<T>(defaultKey);
  const [order, setOrder] = useState<OrderBy>(defaultOrder);
  const switchSort = useCallback(
    (nextKey: T) => {
      if (nextKey === key) {
        const nextOrder = order === 'ASC' ? 'DESC' : 'ASC';

        setOrder(nextOrder);

        return {
          nextKey,
          nextOrder,
        };
      }

      setKey(nextKey);
      setOrder(defaultOrder);

      return {
        nextKey,
        nextOrder: defaultOrder,
      };
    },
    [defaultOrder, key, setKey, order, setOrder],
  );

  return {
    order,
    key,
    switchSort,
  };
};
