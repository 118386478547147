import React, { FC, PropsWithChildren } from 'react';
import { AuthPage } from '../../pages/Auth/AuthPage';
import { ConfirmationPage } from '../../pages/Confirmation/ConfirmationPage';
import { useWhoami } from '../Whoami/useWhoami';

export const Wall: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { awaitingConfirmation, isLoggedIn } = useWhoami();

  if (!isLoggedIn) {
    return <AuthPage />;
  }

  return (
    <>
      {awaitingConfirmation === undefined && null}

      {awaitingConfirmation === null && children}

      {awaitingConfirmation instanceof Date && (
        <ConfirmationPage sentAt={awaitingConfirmation} />
      )}
    </>
  );
};
