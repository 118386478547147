import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Prompt } from 'react-router-dom';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../api/useDefaultApiHandler';
import { FormProps } from '../../../utils/formProps';
import { useYupResolver } from '../../../utils/useYupResolver';
import { WorkFormData, getFormSchema } from './workForm.misc';

export const WorkForm = ({
  defaultValues,
  onSubmit,
  onSuccess,
  firstLabDate,
  lastLabDate,
}: FormProps<WorkFormData> & { lastLabDate: Date; firstLabDate: Date }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
  } = useForm<WorkFormData>({
    defaultValues,
    resolver: useYupResolver(getFormSchema(firstLabDate, lastLabDate)),
  });

  const showSuccessNotification = useDefaultSuccessHandler(
    'Данные о работе успешно сохранены',
  );

  const { mutate, isLoading } = useMutation(
    (data: WorkFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: (response) => {
        reset(getValues());
        showSuccessNotification();

        onSuccess && onSuccess(response);
      },
      onError: useDefaultHandler(),
    },
  );

  const submit = (data: WorkFormData) => {
    mutate(data);
  };

  return (
    <>
      <Prompt
        when={isDirty}
        message="Несохраненные данные о работе будут потеряны, вы уверены, что хотите перейти?"
      />
      <form onSubmit={handleSubmit(submit)}>
        <div className="columns">
          <div className="column is-1">
            <div className="field">
              <label className="label">Номер</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="1"
                  {...register('index')}
                />
              </div>
              {errors.index && (
                <p className="help is-danger">{errors.index.message}</p>
              )}
            </div>
          </div>
          <div className="column is-11">
            <div className="field">
              <label className="label">Наименование работы</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Бетонирование фундамента"
                  {...register('name')}
                />
              </div>
              {errors.name && (
                <p className="help is-danger">{errors.name.message}</p>
              )}
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">Участок</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="в/о 1-2/а-б на отм. +0,100"
              {...register('land')}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">
            Разрешается выполнение следующих работ
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Устройство обмазочной гидроизоляции фундамента"
              {...register('next')}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Дата начала</label>
              <div className="control">
                <input
                  className="input"
                  type="date"
                  placeholder="Огород"
                  {...register('startDate')}
                />
              </div>
            </div>
            {!!errors.startDate && (
              <p className="help is-danger">{errors.startDate.message}</p>
            )}
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Дата окончания</label>
              <div className="control">
                <input
                  className="input"
                  type="date"
                  placeholder="Огород"
                  {...register('finishDate')}
                />
              </div>
              {!!errors.finishDate && (
                <p className="help is-danger">{errors.finishDate.message}</p>
              )}
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Дата акта</label>
              <div className="control">
                <input
                  className="input"
                  type="date"
                  placeholder="Огород"
                  {...register('reportDate')}
                />
              </div>
              {!!errors.reportDate && (
                <p className="help is-danger">{errors.reportDate.message}</p>
              )}
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Нормативная документация</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="СП 435.1325800.2018.КОНСТРУКЦИИ БЕТОННЫЕ И ЖЕЛЕЗОБЕТОННЫЕ МОНОЛИТНЫЕ"
                  {...register('standard')}
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="field">
              <label className="label">Проектная документация</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="4938278.КЖ л.5-6"
                  {...register('projectDoc')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <div className="label">Метки</div>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="06.21, 07.21"
              {...register('tags')}
            />
          </div>
          <p className="help">Через запятую</p>
        </div>

        <div className="field">
          <label className="label">Дополнительные сведения</label>
          <div className="control">
            <textarea
              rows={1}
              className="textarea"
              {...register('comment')}
            ></textarea>
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button disabled={isLoading} className="button is-link">
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
