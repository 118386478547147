import * as yup from 'yup';
import { ReadGroupDto, UpdateGroupDTO } from '../../../api/_out';

export type GroupData = ReadGroupDto;
export type GroupBody = UpdateGroupDTO;

export const groupFormSchema = yup.object({
  name: yup.string().required().min(1),
  domain: yup
    .string()
    .required()
    .min(3)
    .max(24)
    .matches(/^[a-z\.\d]+$/),
});

export type GroupFormData = yup.InferType<typeof groupFormSchema>;

export const toGroupFormData = (group: GroupData): GroupFormData => {
  const { name, domain } = group;

  return {
    name,
    domain,
  };
};

export const toGroupDTO = (data: GroupFormData): GroupBody => {
  const { name, domain } = data;

  return {
    name: name ?? '',
    domain,
  };
};
