import { useParams } from 'react-router-dom';
import { isNaN, isString } from 'lodash-es';

export const useNumericParams = <
  T extends Record<string, string | undefined>,
>(): Record<keyof T, number> => {
  const params = useParams<T>();

  return Object.keys(params).reduce((result, key) => {
    const value = params[key];
    const parsed = isString(value) ? parseInt(value, 10) : NaN;

    return !isNaN(parsed) ? { ...result, [key]: parsed } : result;
  }, {} as Record<string, number>) as Record<keyof T, number>;
};
