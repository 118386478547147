import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { api } from '../../api';
import { useDefaultSuccessHandler } from '../../api/useDefaultApiHandler';
import { ReadMaterialDocDTO } from '../../api/_out';
import { Help } from '../../components/Help/Help';
import { useHelp } from '../../components/Help/useHelp';
import { composeRoute, Route } from '../../routing/routing';
import { useDebouncedChangeHandler } from '../../utils/getDefaultInputHandler';
import { MaterialData } from './MaterialForm/materialForm.misc';
import { SingleMaterial } from './SingleMaterial';

const {
  readMaterials,
  deleteMaterial,
  downloadDocPdf,
  readPreferences,
  pinMaterial,
} = api;

const Doc = ({ data: doc }: { data: ReadMaterialDocDTO }) => {
  const { data: pdfUrl } = useQuery(
    [downloadDocPdf.id, doc.id],
    () => {
      return downloadDocPdf.request(doc.id);
    },
    {
      enabled: !!doc.pdf,
    },
  );

  return (
    <div
      className={classNames(
        'has-background-light p-2 is-size-7 is-flex is-align-items-center is-justify-content-space-between is-rounded',
      )}
      key={doc.id}
    >
      <div>
        <b>
          <span>{doc.name} </span>
          <span>№{doc.index} </span>
        </b>
        <div>
          <span>{new Date(doc.from).toLocaleDateString()}</span>
          {!!doc.to && <span>—{new Date(doc.to).toLocaleDateString()}</span>}
        </div>
      </div>
      <div>
        {!!pdfUrl && (
          <a className="button is-small" target={'_blank'} href={pdfUrl.url}>
            <span className="icon is-small">
              <i className="fas fa-file-pdf"></i>
            </span>
          </a>
        )}
      </div>
    </div>
  );
};

const Material = ({
  data,
  onEdit,
  isPinned,
}: {
  data: MaterialData;
  onEdit: (id: number) => void;
  isPinned: boolean;
}) => {
  const queryClient = useQueryClient();
  const showSuccessNotification = useDefaultSuccessHandler(
    'Материал успешно удален',
  );
  const { mutate: suicide } = useMutation(
    () => {
      return deleteMaterial.request(String(data.id));
    },
    {
      onSuccess: () => {
        showSuccessNotification();
        queryClient.invalidateQueries(readMaterials.id);
      },
    },
  );

  const handleDelete = () => {
    const shouldDo = window.confirm(
      'Вместе с материалом удалятся все его документы. Вы точно хотите удалить материал?',
    );

    if (shouldDo) {
      suicide();
    }
  };

  const startEditing = () => {
    onEdit(data.id);
  };

  const { mutate: pinMutate } = useMutation(
    () => {
      return pinMaterial.request({ materialId: data.id, value: !isPinned });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(readPreferences.id);
      },
    },
  );

  const pin = () => {
    pinMutate();
  };

  return (
    <div className="item is-revealing">
      <div className="cell">
        <span className="icon-text">
          <span className="mr-1">
            <span>{data.name}</span>{' '}
            {data.manufacturer ? <span>({data.manufacturer})</span> : null}
          </span>
          <span
            className={classNames(
              'icon is-clickable',
              isPinned ? 'has-text-grey' : 'has-text-grey-light is-concealed',
            )}
            onClick={pin}
          >
            <i className={classNames('fas', 'fa-star')}></i>
          </span>
        </span>
      </div>
      <div
        className="cell"
        style={{ flexFlow: 'column wrap', alignItems: 'unset', gap: '0.25rem' }}
      >
        {data.docs.map((doc) => {
          return <Doc data={doc} key={doc.id} />;
        })}
      </div>
      <div className="cell is-justify-content-end">
        <div className="is-clearfix">
          <div className="buttons is-concealed is-pulled-right">
            <button
              type="button"
              className="button is-info is-outlined is-concealed"
              onClick={startEditing}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              type="button"
              className="button is-danger is-outlined is-concealed"
              onClick={handleDelete}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MaterialsPage = () => {
  const history = useHistory();
  const { data: materials } = useQuery(readMaterials.id, () => {
    return readMaterials.request();
  });
  const { data: preferences } = useQuery(readPreferences.id, () => {
    return readPreferences.request();
  });
  const [filtered, setFiltered] = useState<MaterialData[]>([]);
  const { value: nameSearch, handleChange: handleNameInputChange } =
    useDebouncedChangeHandler('');
  const { value: dateSearch, handleChange: handleDateInputChange } =
    useDebouncedChangeHandler('');

  const pinned = useMemo(() => {
    return new Set(preferences?.pinnedMaterials ?? []);
  }, [preferences]);

  const createNewMaterial = () => {
    history.push(
      composeRoute('/materials/:materialId', {
        materialId: -1,
      }),
    );
  };

  const handleEdit = (id: number) => {
    history.push(
      composeRoute('/materials/:materialId', {
        materialId: id,
      }),
    );
  };

  const {
    isVisible: showHelp,
    toggle: toggleHelp,
    close: closeHelp,
  } = useHelp('materials');

  useEffect(() => {
    setFiltered(() => {
      const nextValue =
        materials?.filter(({ name, docs }) => {
          const isWithinDate =
            dateSearch === ''
              ? true
              : docs.some((doc) => {
                  const millenium = 1000 * 365 * 24 * 60 * 60 * 1000;
                  const from = doc.from
                    ? new Date(doc.from)
                    : new Date(Date.now() - millenium);
                  const to = doc.to
                    ? new Date(doc.to)
                    : new Date(Date.now() + millenium);
                  const date = new Date(dateSearch);

                  return date >= from && date <= to;
                });
          return (
            name.toLowerCase().match(nameSearch.toLowerCase()) && isWithinDate
          );
        }) ?? [];

      return nextValue
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .sort((a, b) => {
          if (pinned.has(a.id) === pinned.has(b.id)) return 0;
          if (pinned.has(a.id)) return -1;
          if (pinned.has(b.id)) return 1;

          return 0;
        });
    });
  }, [materials, nameSearch, dateSearch, preferences]);

  return (
    <>
      <Route path={'/materials/:materialId'} exact>
        <SingleMaterial />
      </Route>
      <div className="section">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <h1 className="title">Материалы</h1>
            </div>
            <div className="level-right">
              <div className="level-item">
                <div className="buttons">
                  <button
                    type="button"
                    onClick={createNewMaterial}
                    className="button is-primary"
                  >
                    Новый материал
                  </button>
                  <button
                    className={classNames(
                      showHelp ? 'has-text-info' : 'has-text-grey',
                      'button is-white',
                    )}
                    onClick={toggleHelp}
                  >
                    <i className="fas fa-question-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showHelp && (
            <Help close={closeHelp}>
              <div className="content">
                <p>
                  К каждому материалу прилагаются документы, например, паспорт
                  качества или сертификат соответствия. <br />
                  Материалы и сопутствующие документы используются для
                  заполнения АОСР и формирования приложений к нему. <br />
                  Материалы можно использовать в нескольких работах без
                  повторного заполнения данных. <br />
                  Часто используемые материалы можно добавить его в избранное с
                  помощью звездочки рядом с названием. <br />
                  Максимальный допустимый размер PDF для документа — 10Мб.
                </p>
              </div>
            </Help>
          )}
          <div className="columns">
            <div className="column is-9">
              <div className="field">
                <label className="label is-small">Наименование материала</label>
                <p className="control has-icons-left">
                  <input
                    className="input"
                    type="text"
                    placeholder="Поиск"
                    defaultValue={nameSearch}
                    onChange={handleNameInputChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-search"></i>
                  </span>
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="field">
                <label className="label is-small">
                  С документом действительным на
                </label>
                <p className="control">
                  <input
                    className="input"
                    type="date"
                    placeholder="Дата"
                    defaultValue={dateSearch}
                    onChange={handleDateInputChange}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="box">
            <div
              className="grid-table"
              style={{ gridTemplateColumns: 'repeat(3, auto)' }}
            >
              <div className="head">
                <div className="cell">Наименование</div>
                <div className="cell">Документы</div>
                <div className="cell">
                  {/* Кнопка редактирования и удаления */}
                </div>
              </div>
              <div className="body">
                {filtered?.map((material) => {
                  return (
                    <Material
                      key={material.id}
                      data={material}
                      onEdit={handleEdit}
                      isPinned={pinned.has(material.id)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
