import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { api } from '../../../api';
import { composeRoute } from '../../../routing/routing';

type Props = {
  buildingId: number;
};

const { readBuildingPieces } = api;

export const BuildingPieces = ({ buildingId }: Props) => {
  const { data: pieces } = useQuery([readBuildingPieces.id, buildingId], () => {
    return readBuildingPieces.request(buildingId);
  });

  return (
    <>
      <div className="level">
        <div className="level-left"></div>
        <div className="level-right">
          <div className="level-item">
            <div className="buttons">
              <Link
                to={composeRoute('/buildings/:buildingId/pieces/:pieceId', {
                  buildingId,
                  pieceId: -1,
                })}
                type="button"
                className="button is-primary"
              >
                Новый раздел
              </Link>
            </div>
          </div>
        </div>
      </div>
      {pieces ? (
        <div className="box">
          <div
            className="grid-table"
            style={{ gridTemplateColumns: 'repeat(3, auto)' }}
          >
            <div className="head">
              <div className="cell">Наименование</div>
              <div className="cell">Дата изменения</div>
              <div className="cell">Комментарий</div>
            </div>
            <div className="body">
              {pieces.map((piece) => {
                return (
                  <div className="item" key={piece.id}>
                    <div className="cell">
                      <Link
                        to={composeRoute(
                          '/buildings/:buildingId/pieces/:pieceId',
                          {
                            buildingId: Number(buildingId),
                            pieceId: Number(piece.id),
                          },
                        )}
                      >
                        {piece.name}
                      </Link>
                    </div>
                    <div className="cell">
                      {new Date(piece.updatedAt).toLocaleDateString()}
                    </div>
                    <div className="cell">{piece.comment}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
