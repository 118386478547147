import { uniqueId } from 'lodash-es';
import React, { FC, PropsWithChildren, useState } from 'react';
import { BaseMessage, NotifierContext } from './NotifierContext';

export const NotifierProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [messages, setMessages] = useState<(BaseMessage & { id: string })[]>(
    [],
  );

  const remove = (id: string) => {
    setMessages((prev) => {
      return prev.filter((message) => message.id !== id);
    });
  };

  const add = (message: BaseMessage) => {
    const id = uniqueId('message-');

    setMessages((prev) => {
      return [...prev, { ...message, id }];
    });

    setTimeout(() => {
      remove(id);
    }, 5000);
  };

  return (
    <NotifierContext.Provider value={{ messages, add, remove }}>
      {children}
    </NotifierContext.Provider>
  );
};
