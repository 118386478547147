import * as yup from 'yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { api } from '../../api/';
import { LoginDTO } from '../../api/_out';
import { useWhoami } from '../../modules/Whoami/useWhoami';
import styles from './AuthPage.sass';
import logo from './static/logo.png';
import { useYupResolver } from '../../utils/useYupResolver';

const { login, register: createUser } = api;

type OnChangeMode = () => void;

const formSchema = yup.object({
  username: yup.string().required('Обязательное поле'),
  password: yup
    .string()
    .required('Обязательное поле')
    .min(6, 'В пароле должно быть не меньше 6 символов'),
});

type FormData = yup.InferType<typeof formSchema>;

const LoginForm = ({ onChangeMode }: { onChangeMode: OnChangeMode }) => {
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const { login: setAuthToken } = useWhoami();
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: { username: '', password: '' },
    resolver: useYupResolver(formSchema),
  });

  const { mutate } = useMutation(
    (data: FormData) => {
      return login.request({
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    {
      onSuccess: (response) => {
        setAuthToken((response as LoginDTO)['accessToken']);
      },
      onError: (response: { status: number }) => {
        setErrorCode(response.status);
      },
    },
  );

  const submit = (data: FormData) => {
    return mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="field">
        <label className="label is-small">Email</label>
        <div className="control">
          <input
            type="text"
            className="input"
            placeholder="jonsnow@yandex.ru"
            autoFocus
            {...register('username')}
          />
        </div>
      </div>
      <div className="field">
        <label className="label is-small">Пароль</label>
        <div className="control">
          <input
            type="password"
            className="input"
            placeholder="*******"
            {...register('password')}
          />
        </div>
        {errorCode === 401 && (
          <p className="help is-danger">Неверный логин или пароль</p>
        )}

        {errorCode === 500 && (
          <p className="help is-danger">
            Что-то пошло не так. Попробуйте позднее
          </p>
        )}
      </div>
      <div className="field">
        <div className="control">
          <button className="button is-primary is-fullwidth" type="submit">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-sign-in"></i>
              </span>
              <span>Войти</span>
            </span>
          </button>
        </div>
        <div className="help has-text-right">
          <span>Еще нет аккаунта?</span>{' '}
          <span onClick={onChangeMode} className="has-text-info is-clickable">
            Зарегистрироваться
          </span>
        </div>
      </div>
    </form>
  );
};

const RegisterForm = ({ onChangeMode }: { onChangeMode: OnChangeMode }) => {
  const { login } = useWhoami();
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const { register, handleSubmit, formState } = useForm<FormData>({
    defaultValues: { username: '', password: '' },
    resolver: useYupResolver(formSchema),
  });
  const { errors } = formState;

  const { mutate } = useMutation(
    (data: FormData) => {
      return createUser.request(data);
    },
    {
      onError: (response: { status: number }) => {
        setErrorCode(response.status);
      },
      onSuccess: (response) => {
        login(response.accessToken);
      },
    },
  );

  const submit = (data: FormData) => {
    return mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="field">
        <label className="label is-small">Email</label>
        <div className="control">
          <input
            type="text"
            className="input"
            placeholder="jonsnow@yandex.ru"
            autoFocus
            {...register('username')}
          />
        </div>
        {errorCode === 409 && (
          <p className="help is-danger">Email уже используется</p>
        )}
      </div>
      <div className="field">
        <label className="label is-small">Пароль</label>
        <div className="control">
          <input
            type="password"
            className="input"
            placeholder="******"
            {...register('password')}
          />
        </div>
        {!!errors.password && (
          <div className="help is-danger">{errors.password.message}</div>
        )}
      </div>
      <div className="field">
        <div className="control">
          <button className="button is-primary is-fullwidth" type="submit">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-user-plus"></i>
              </span>
              <span>Зарегистрироваться</span>
            </span>
          </button>
        </div>
        <div className="help has-text-right">
          Уже зарегистрированы?{' '}
          <span onClick={onChangeMode} className="has-text-info is-clickable">
            Войти
          </span>
        </div>
      </div>
    </form>
  );
};

export const AuthPage = () => {
  const { login: setAuthToken } = useWhoami();
  const [isRegisterMode, setIsRegister] = useState<boolean>(false);

  const { register, handleSubmit, setError, formState, clearErrors } =
    useForm<FormData>({
      defaultValues: { username: '', password: '' },
      resolver: useYupResolver(formSchema),
    });

  const { errors } = formState;

  const { mutate } = useMutation(
    (data: FormData) => {
      return isRegisterMode
        ? createUser.request(data)
        : login.request({
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
            },
          });
    },
    {
      onError: (response: { status: number }) => {
        if (response.status === 409) {
          setError('username', { message: 'Email уже используется' });

          return;
        }

        if (response.status === 401) {
          setError('password', { message: 'Неверный логин или пароль' });

          return;
        }

        setError('password', {
          message: 'Что-то пошло не так. Попробуйте позднее',
        });
      },

      onSuccess: (response) => {
        setAuthToken(response.accessToken);
      },
    },
  );

  const toggleMode = () => {
    setIsRegister((prev) => !prev);
    clearErrors();
  };

  const submit = (data: FormData) => {
    return mutate(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>

        <form onSubmit={handleSubmit(submit)}>
          <div className="field">
            <label className="label is-small">Email</label>
            <div className="control">
              <input
                type="text"
                className="input"
                placeholder="jonsnow@yandex.ru"
                autoFocus
                {...register('username')}
              />
            </div>
            {errors.username && (
              <p className="help is-danger">{errors.username.message}</p>
            )}
          </div>
          <div className="field">
            <label className="label is-small">Пароль</label>
            <div className="control">
              <input
                type="password"
                className="input"
                placeholder="******"
                {...register('password')}
              />
            </div>
            {!!errors.password && (
              <div className="help is-danger">{errors.password.message}</div>
            )}
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-primary is-fullwidth" type="submit">
                <span className="icon-text">
                  <span className="icon">
                    <i className="fas fa-user-plus"></i>
                  </span>
                  <span>{isRegisterMode ? 'Зарегистрироваться' : 'Войти'}</span>
                </span>
              </button>
            </div>

            {isRegisterMode && (
              <div className="help has-text-right">
                Нажимая кнопку "Зарегистрироваться", вы соглашаетесь с{' '}
                <a href="/terms-of-service.pdf" target="_blank">
                  пользовательским соглашением
                </a>
              </div>
            )}

            {isRegisterMode ? (
              <div className="help has-text-right">
                Уже зарегистрированы?{' '}
                <span
                  onClick={toggleMode}
                  className="has-text-info is-clickable"
                >
                  Войти
                </span>
              </div>
            ) : (
              <div className="help has-text-right">
                <span>Еще нет аккаунта?</span>{' '}
                <span
                  onClick={toggleMode}
                  className="has-text-info is-clickable"
                >
                  Зарегистрироваться
                </span>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
