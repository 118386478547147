import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { FormProps } from '../../../utils/formProps';
import { MemberFormData, memberFormSchema } from './memberForm.misc';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../api/useDefaultApiHandler';
import { Prompt } from 'react-router-dom';
import { useYupResolver } from '../../../utils/useYupResolver';
import { attrs } from '../../../utils/useTable';
import { UsernameCircle } from '../UsernameCircle';

export const MemberForm = ({
  defaultValues,
  readonly,
  onSubmit,
  onSuccess,
  id,
  renderRevert = true,
  domain,
}: FormProps<MemberFormData> & {
  id: number;
  renderRevert?: boolean;
  domain: string;
}) => {
  const isNew = id < 0;
  const { register, handleSubmit, formState, reset, watch } =
    useForm<MemberFormData>({
      defaultValues,
      resolver: useYupResolver(memberFormSchema),
    });

  const { isDirty, errors } = formState;

  const showSuccessNotification = useDefaultSuccessHandler(
    'Участник успешно сохранен',
  );

  const { mutate, isLoading } = useMutation(
    (data: MemberFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: (response) => {
        showSuccessNotification();
        reset(defaultValues);

        onSuccess && onSuccess(response);
      },
      onError: useDefaultHandler(),
    },
  );

  const submit = (data: MemberFormData) => {
    mutate(data);
  };

  return (
    <>
      <Prompt
        when={isDirty}
        message="В форме участника остались несохраненные изменения. Вы точно хотите уйти?"
      />
      <form
        {...attrs.idAttribute(String(id))}
        className={classNames('item is-revealing', isNew && 'is-new')}
        onSubmit={handleSubmit(submit)}
      >
        <div className="cell">
          <UsernameCircle username={watch('username')} />
        </div>
        <div className="cell">
          <div className="field has-addons">
            <div className="control">
              <input
                className={classNames(
                  'input',
                  readonly?.username && 'is-static',
                )}
                type="text"
                placeholder="jonsnow"
                readOnly={readonly?.username}
                {...register('username')}
              />
              {!!errors.username && (
                <p className="help is-danger">{errors.username.message}</p>
              )}
            </div>
            <div className="control">
              <div className="button is-static">+{domain}</div>
            </div>
          </div>
        </div>
        <div className="cell">
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder="******"
                readOnly={readonly?.password}
                {...register('password')}
              />
              {!!errors.password && (
                <p className="help is-danger">{errors.password.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="cell">
          <div className="field is-w-100 is-grouped is-grouped-right">
            {renderRevert && (
              <div className="control is-concealed">
                <button
                  type="button"
                  className="button is-danger is-outlined "
                  {...attrs.undoAttr}
                >
                  <i className="fa fa-undo"></i>
                </button>
              </div>
            )}

            <div className="control is-concealed">
              <button
                type="submit"
                disabled={isLoading || !isDirty}
                className={classNames(
                  'button is-success is-outlined',
                  isLoading && 'is-loading',
                )}
              >
                <i className="fa fa-save"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
