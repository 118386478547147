import { useQuery } from 'react-query';
import { api } from '../../api';

const { readCurrentSubscription } = api;

const isSubscriptionExpired = (expirationDate: string | undefined) => {
  if (!expirationDate) return false;

  const now = Date.now();

  return new Date(expirationDate).valueOf() < now;
};

export const useSubscription = () => {
  const { data: subscription } = useQuery(readCurrentSubscription.id, () => {
    return readCurrentSubscription.request();
  });

  const isExpired = isSubscriptionExpired(subscription?.expiresAt);

  return {
    data: subscription,
    isExpired,
  };
};
