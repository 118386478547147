import React from 'react';
import { useWhoami } from '../../modules/Whoami/useWhoami';
import classNames from 'classnames';
import { Link, matchPath, useLocation, Switch } from 'react-router-dom';
import { composeRoute, getRoute, Redirect, Route } from '../../routing/routing';
import { Team } from './Team';
import { Subscriptions } from './Subscriptions/Subscriptions';
import { useSubscription } from '../../modules/Subscription/useSubscription';

const NoOwnerContent = () => {
  return (
    <article className="message is-info">
      <div className="message-body">
        Эта страница предназначена для администраторов команд
      </div>
    </article>
  );
};

const OwnerContent = () => {
  const { pathname } = useLocation();
  const { isExpired, data: subscription } = useSubscription();

  return (
    <>
      <h1 className="title">Доступ</h1>
      {isExpired && (
        <div className="notification is-warning">
          Ваша подписка истекла. Вам доступно только чтение. Вы не можете
          создавать и редактировать. Вся созданная вами информация будет удалена
          через 21 день после прекращения действия подписки.
        </div>
      )}
      {subscription?.isExceeded && !isExpired && (
        <div className="notification is-warning">
          Количество участников команды превышает оплаченное. Чтобы продолжить
          пользоваться сервисом, оплатите больше участников или удалите
          существующих.
        </div>
      )}
      <div className="tabs">
        <ul>
          <li
            className={classNames(
              matchPath(pathname, {
                path: getRoute('/account/team'),
              }) !== null && 'is-active',
            )}
          >
            <Link to={composeRoute('/account/team')}>Команда</Link>
          </li>

          <li
            className={classNames(
              matchPath(pathname, {
                path: getRoute('/account/subscriptions'),
              }) !== null && 'is-active',
            )}
          >
            <Link to={composeRoute('/account/subscriptions')}>Подписки</Link>
          </li>
        </ul>
      </div>
      <Switch>
        <Redirect from={'/account'} to={'/account/team'} strict exact />

        <Route path={'/account/team'}>
          <Team />
        </Route>

        <Route path={'/account/subscriptions'}>
          <Subscriptions />
        </Route>
      </Switch>
    </>
  );
};

export const Me = () => {
  const { data: whoami } = useWhoami();

  return whoami ? (
    <>
      <div className="section">
        <div className="container">
          {whoami.ownerOf === null && <NoOwnerContent />}
          {whoami.ownerOf !== null && (
            <div className="columns is-centered">
              <div className="column is-two-thirds">
                <OwnerContent />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  ) : null;
};
