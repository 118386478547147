import classNames from 'classnames';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { NotifierContext } from './NotifierContext';
import styles from './Notifier.sass';

export const Notifier = () => {
  const { messages, remove } = useContext(NotifierContext);
  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    const isRightClick = event.button === 2;

    if (!isRightClick) return;

    const { id } = event.currentTarget.dataset;

    remove(id as string);
  };
  const handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    const { id } = event.currentTarget.dataset;

    remove(id as string);
  };

  return ReactDOM.createPortal(
    <div className={styles.container}>
      {messages.map(({ content: message, type, id }) => (
        <div
          className={classNames(
            'notification',
            {
              'is-success': type === 'success',
              'is-info': type === 'info',
              'is-danger': type === 'error',
            },
            styles.message,
          )}
          key={id}
          data-id={id}
          onContextMenu={handleClick}
        >
          <button
            className="delete"
            type="button"
            data-id={id}
            onClick={handleDeleteClick}
          />
          {message}
        </div>
      ))}
    </div>,
    document.getElementById('notifications') as HTMLElement,
  );
};
