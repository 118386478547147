import React from 'react';
import { useForm } from 'react-hook-form';
import { FormProps } from '../../../utils/formProps';
import {
  SubscriptionFormData,
  subscriptionFormSchema,
  toSubscriptionDTO,
} from './SubscriptionForm.misc';
import { api } from '../../../api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useYupResolver } from '../../../utils/useYupResolver';
import { toDateInputValue } from '../../../utils/toDatetimeInputValue';
import { useHistory } from 'react-router-dom';
import { composeRoute } from '../../../routing/routing';
import { useDefaultSuccessHandler } from '../../../api/useDefaultApiHandler';
import classNames from 'classnames';

const { createSubscription, getSubscriptionPrice, readSubscriptions } = api;

const SubscriptionForm = ({
  defaultValues,
  onSubmit,
  onSuccess,
}: FormProps<SubscriptionFormData>) => {
  const showSuccessNotification = useDefaultSuccessHandler(
    'Подписка успешно добавлена и ожидает оплаты',
  );
  const { register, handleSubmit, watch, formState } =
    useForm<SubscriptionFormData>({
      defaultValues,
      resolver: useYupResolver(subscriptionFormSchema),
    });

  const { errors } = formState;

  const { from, to, users } = watch();

  const { data: price } = useQuery(
    [getSubscriptionPrice.id, from, to, users],
    () => {
      return getSubscriptionPrice.request({
        time: new Date(to).valueOf() - new Date(from).valueOf(),
        users: Number(users),
      });
    },
    {
      enabled: !!from && !!to,
    },
  );

  const { mutate, isLoading } = useMutation(
    (data: SubscriptionFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: (response) => {
        onSuccess && onSuccess(response);
        showSuccessNotification();
      },
    },
  );

  const submit = (data: SubscriptionFormData) => {
    return mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">Действует с</label>
            <div className="control">
              <input className="input" type="date" {...register('from')} />
            </div>
            {!!errors.from && (
              <p className="help is-danger">{errors.from.message ?? ''}</p>
            )}
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label">Действует до</label>
            <div className="control">
              <input className="input" type="date" {...register('to')} />
            </div>
            {!!errors.to && (
              <p className="help is-danger">{errors.to.message ?? ''}</p>
            )}
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label">Участники</label>
            <div className="control">
              <input className="input" type="number" {...register('users')} />
            </div>
          </div>
        </div>
      </div>
      <div className="field is-grouped is-grouped-right">
        <div className="control">
          {price && (
            <input
              className="input is-static has-text-right has-text-weight-semibold"
              readOnly
              type="text"
              value={`Итого: ${price.price} ₽`}
            />
          )}
        </div>
        <div className="control">
          <button
            type="submit"
            className={classNames('button is-link', isLoading && 'is-loading')}
          >
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-check"></i>
              </span>
              <span>Оформить</span>
            </span>
          </button>
        </div>
      </div>
    </form>
  );
};

export const CreateSubscription = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const onSubmit = (data: SubscriptionFormData) => {
    const body = toSubscriptionDTO(data);

    return createSubscription.request(body);
  };

  const onSuccess = () => {
    queryClient.invalidateQueries([readSubscriptions.id]);
    history.replace(composeRoute('/account/subscriptions'));
  };

  const defaultValues: SubscriptionFormData = {
    from: toDateInputValue(new Date(Date.now())),
    to: toDateInputValue(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)),
    users: '1',
  };

  return (
    <SubscriptionForm
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    />
  );
};
