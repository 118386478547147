import React from 'react';
import classNames from 'classnames';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navbar } from '../Navbar/Navbar';
import { Redirect, Route } from '../../routing/routing';
import { BuildingsPage } from '../../pages/Buildings/BuildingsPage';
import { SingleBuildingPage } from '../../pages/Building/SingleBuildingPage';
import { CompaniesPage } from '../../pages/Companies/CompaniesPage';
import { SingleCompanyPage } from '../../pages/Company/SingleCompanyPage';
import { MaterialsPage } from '../../pages/Materials/MaterialsPage';
import { SinglePiecePage } from '../../pages/Piece/SinglePiecePage';
import { SingleWorkPage } from '../../pages/Work/SingleWorkPage';
import { Me } from '../../pages/Me/Me';
import { NotifierProvider } from '../Notifier/NotifierProvider';
import { Notifier } from '../Notifier/Notifier';
import { Wall } from '../Wall/Wall';
import { Whoami } from '../Whoami/Whoami';
import { Ping } from '../Ping/Ping';
import { Sidebar } from '../Sidebar/Sidebar';
import styles from './App.sass';

const queryClient = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Whoami>
        <Wall>
          <NotifierProvider>
            <Router>
              <div>
                <div
                  className={classNames(styles.sidebar, 'has-background-light')}
                >
                  <Sidebar />
                </div>
                <div className={styles.topbar}>
                  <Navbar />
                </div>
                <div className={styles.body}>
                  <Switch>
                    <Redirect from="/" to="/buildings" exact />
                    <Route path="/buildings/:buildingId/pieces/:pieceId">
                      <SinglePiecePage />
                    </Route>
                    <Route path="/buildings/:buildingId">
                      <SingleBuildingPage />
                    </Route>
                    <Route path="/buildings">
                      <BuildingsPage />
                    </Route>
                    <Route path="/companies/:companyId">
                      <SingleCompanyPage />
                    </Route>
                    <Route path="/companies">
                      <CompaniesPage />
                    </Route>
                    <Route path="/materials">
                      <MaterialsPage />
                    </Route>
                    <Route path="/pieces/:pieceId/works/:workId">
                      <SingleWorkPage />
                    </Route>
                    <Route path="/account">
                      <Me />
                    </Route>
                  </Switch>
                </div>
              </div>

              <Notifier />
            </Router>
          </NotifierProvider>
          <Ping />
        </Wall>
      </Whoami>
    </QueryClientProvider>
  );
};
