import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { ReadPaymentDTO, SubscriptionDTO } from '../../../api/_out';
import { composeRoute, Route } from '../../../routing/routing';
import { CreateSubscription } from './CreateSubscription';
import { DeleteSubscription } from './DeleteSubscription';

const { readSubscriptions } = api;

const Payment = ({
  data,
  subscriptionId,
}: {
  data: ReadPaymentDTO;
  subscriptionId: number;
}) => {
  const { completedAt, money, code } = data;
  const date = completedAt && new Date(completedAt).toLocaleString();

  return (
    <>
      <div className="is-flex">
        <div>{date ? <>Дата платежа: {date}</> : <b>Ожидает оплаты</b>}</div>
        <div className="ml-auto">
          <b>{money} ₽</b>
        </div>
      </div>
      {!date && (
        <div className="field is-grouped is-grouped-right mt-2">
          <div className="control">
            <DeleteSubscription id={subscriptionId} />
          </div>
          <div className="control">
            <a
              href={`${process.env.YOO_KASSA_API}?orderId=${code}`}
              target="_blank"
              className="button is-primary is-outlined is-small"
            >
              <i className="fas fa-shopping-cart"></i>
              <span className="ml-2">Оплатить</span>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

const SubscriptionStatus = ({ data }: { data: SubscriptionDTO }) => {
  const { isActivated, startsAt, expiresAt } = data;
  const from = new Date(startsAt).valueOf();
  const to = new Date(expiresAt).valueOf();
  const now = Date.now();
  const isCurrent = now >= from && now <= to;
  const isActive = isCurrent && isActivated;
  const isAwaitingPayment = !isActivated;
  const isExpired = now > to;
  const isFuture = now < from;

  // активна
  if (isActive)
    return <span className={'tag is-light is-success'}>Активна</span>;

  // истекла
  if (isExpired) return <span className={'tag is-light'}>Истекла</span>;

  // не истекла и не оплачена
  if (isAwaitingPayment)
    return <span className={'tag is-light is-warning'}>Ожидает оплаты</span>;

  // оплачена и заработает в будущем
  if (isFuture)
    return (
      <span className="tag is-light">
        Активируется {new Date(startsAt).toLocaleString()}
      </span>
    );

  return null;
};

const Subscription = ({ data }: { data: SubscriptionDTO }) => {
  const { startsAt, expiresAt, users, payment, id } = data;
  const from = useMemo(() => {
    return new Date(startsAt).toLocaleDateString();
  }, [startsAt]);
  const to = useMemo(() => {
    return new Date(expiresAt).toLocaleDateString();
  }, [expiresAt]);
  const [shouldShowPayment, setShouldShowPayment] = useState<boolean>(false);

  const togglePaymentVisibility = useCallback(() => {
    setShouldShowPayment((value) => !value);
  }, [setShouldShowPayment]);

  return (
    <div className="box">
      <div className="is-flex is-align-items-center">
        <div>
          <span className="tag is-light">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-users"></i>
              </span>
              <span>{users}</span>
            </span>
          </span>
        </div>
        <div className="ml-2">
          {from} — {to}
        </div>
        <div className="ml-auto">
          <SubscriptionStatus data={data} />
        </div>
        <div className="ml-2 mr-2">
          <span className="has-text-grey-light">|</span>
        </div>
        {!!payment && (
          <div>
            <button
              className="button is-small is-light"
              onClick={togglePaymentVisibility}
            >
              {shouldShowPayment ? (
                <i className="fas fa-angle-up"></i>
              ) : (
                <i className="fas fa-angle-down"></i>
              )}
            </button>
          </div>
        )}
      </div>
      {!!payment && shouldShowPayment && (
        <div className="has-background-light p-4 mt-3 is-rotund">
          <Payment data={payment} subscriptionId={id} />
        </div>
      )}
    </div>
  );
};

export const Subscriptions = () => {
  const history = useHistory();

  const { data: subscriptions } = useQuery([readSubscriptions.id], () => {
    return readSubscriptions.request();
  });

  const hideModal = useCallback(() => {
    history.replace(composeRoute('/account/subscriptions'));
  }, [history]);

  return (
    <>
      <Route path={'/account/subscriptions/new'}>
        <div className="modal is-active">
          <div className="modal-background" onClick={hideModal}>
            <button
              className="modal-close is-large"
              aria-label="close"
            ></button>
          </div>
          <div className="modal-content">
            <div className="box">
              <CreateSubscription />
            </div>
          </div>
        </div>
      </Route>
      <div className="level">
        <div className="level-left"></div>
        <div className="level-right">
          <div className="level-item">
            <div className="buttons">
              <Link
                to={composeRoute('/account/subscriptions/new')}
                className="button is-primary"
              >
                <span className="icon-text">
                  <span className="icon">
                    <i className="fas fa-plus"></i>
                  </span>
                  <span>Новая подписка</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {subscriptions?.subscriptions.map((subscription) => {
        return <Subscription key={subscription.id} data={subscription} />;
      })}
    </>
  );
};
