import * as yup from 'yup';
import { BuildingPieceDTO, ReadBuildingPieceDTO } from '../../../api/_out';

export type PieceData = ReadBuildingPieceDTO;
export type PieceBody = BuildingPieceDTO;

export const toPieceFormData = (encoded: PieceData): PieceFormData => {
  const { cipher, comment, name, signatoryName, signatoryPosition, standard } =
    encoded;

  return {
    cipher,
    comment,
    name,
    signatoryName,
    signatoryPosition,
    standard,
  };
};

export const toPieceBody = (formData: PieceFormData): PieceBody => {
  const { cipher, comment, name, signatoryName, signatoryPosition, standard } =
    formData;

  return {
    cipher,
    comment,
    name,
    signatoryName,
    signatoryPosition,
    standard,
  };
};

export const pieceFormSchema = yup.object({
  cipher: yup.string(),
  comment: yup.string(),
  name: yup.string().required(),
  signatoryName: yup.string(),
  signatoryPosition: yup.string(),
  standard: yup.string(),
});

export type PieceFormData = ExludeNullValues<
  yup.InferType<typeof pieceFormSchema>
>;
