import React from 'react';
import uniqueId from 'lodash-es/uniqueId';
import { useDict } from './useDict';

const actionAttributes = {
  add: 'add',
  edit: 'edit',
  undo: 'undo',
};

const idAttribute = 'data-id';
const actionAttribute = 'data-action';

export const useTable = () => {
  const { add: addItemToDict, dict: added, drop: deleteItem } = useDict();
  const { add: startEditing, dict: edited, drop: stopEditing } = useDict();
  const addItem = () => addItemToDict(uniqueId('-'));
  const handleClickCapture = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const closestWithAction = target.closest(`[${actionAttribute}]`) as
      | HTMLElement
      | undefined;

    if (!closestWithAction) {
      return;
    }

    const action = closestWithAction.dataset.action;

    if (action === actionAttributes.edit) {
      const parentWithId = target.closest(`[${idAttribute}]`) as HTMLElement;

      if (!parentWithId || !parentWithId.dataset.id) return;

      const id = parentWithId.dataset.id;

      startEditing(id);
    }

    if (action === actionAttributes.add) {
      addItem();
    }

    if (action === actionAttributes.undo) {
      const parentWithId = target.closest(`[${idAttribute}]`) as HTMLElement;

      if (!parentWithId || !parentWithId.dataset.id) return;

      const id = parentWithId.dataset.id;

      if (added[id]) deleteItem(id);
      if (edited[id]) stopEditing(id);
    }
  };

  return {
    addItem,
    added: Object.keys(added),
    edited,
    deleteItem,
    handleClickCapture,
    startEditing,
    stopEditing,
  };
};

export const attrs = {
  addAttr: { [actionAttribute]: actionAttributes.add },
  editAttr: { [actionAttribute]: actionAttributes.edit },
  undoAttr: { [actionAttribute]: actionAttributes.undo },
  idAttribute: (id: string) => ({ [idAttribute]: id }),
};
