import React from 'react';
import classNames from 'classnames';
import {
  matchPath,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { api } from '../../api';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../api/useDefaultApiHandler';
import { Link } from 'react-router-dom';
import { composeRoute, getRoute, Route } from '../../routing/routing';
import { BuildingPieces } from './BuildingPieces/BuildingPieces';
import { BuildingInformationForm as InformationForm } from './BuildingForm/BuildingInformationForm';
import { useNumericParams } from '../../utils/useNumericParams';
import {
  BuildingBody,
  BuildingData,
  BuildingFormData,
  toBuildingDTO,
  toBuildingFormData,
} from './BuildingForm/buildingInformationForm.misc';
import { BuildingOrders } from './BuildingOrders/BuildingOrders';

const { createBuilding, readBuilding, updateBuilding, deleteBuilding } = api;

const DeleteButton = ({ id }: { id: number }) => {
  const history = useHistory();
  const showSuccessNotification = useDefaultSuccessHandler(
    'Объект успешно удален',
  );
  const { mutate: suicide } = useMutation(
    () => {
      return deleteBuilding.request(id);
    },
    {
      onSuccess: () => {
        showSuccessNotification();
        history.push(composeRoute('/'));
      },
      onError: useDefaultHandler(),
    },
  );

  const handleClick = () => {
    const isConfirmed = window.confirm(
      'Удалить объект? Вместе с ним каскадно удалятся все принадлежащие ему разделы.',
    );

    if (isConfirmed) {
      suicide();
    }
  };

  return (
    <button
      className="button is-danger is-outlined"
      type="button"
      onClick={handleClick}
    >
      Удалить объект
    </button>
  );
};

export const SingleBuildingPage = () => {
  const history = useHistory();
  const { buildingId } = useNumericParams<{ buildingId: string }>();
  const { data } = useQuery([readBuilding.id, buildingId], () => {
    return isNew
      ? Promise.resolve({
          builderId: null,
          contractorId: null,
          developerId: null,
          id: -1,
          name: '',
          projecterId: null,
          updatedAt: new Date().toISOString(),
        })
      : (readBuilding.request(buildingId) as Promise<BuildingData>);
  });
  const isNew = buildingId === -1;

  const request = (data: BuildingFormData) => {
    const body = toBuildingDTO(data);

    return isNew
      ? createBuilding.request(body as ExludeNullValues<BuildingBody>)
      : updateBuilding.request(
          body as ExludeNullValues<BuildingBody>,
          buildingId,
        );
  };

  const { pathname } = useLocation();
  const localRouteParams = { buildingId: Number(buildingId) };
  const handleFormSuccess = isNew
    ? (response: { id: number }) => {
        history.push(
          composeRoute('/buildings/:buildingId/information', {
            buildingId: response.id,
          }),
        );
      }
    : undefined;

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {isNew ? 'Новый объект' : data?.name || <span>&nbsp;</span>}
                </h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <div className="buttons">
                  {!isNew && <DeleteButton id={Number(buildingId)} />}
                </div>
              </div>
            </div>
          </div>

          <div className="tabs">
            <ul>
              {!isNew && (
                <li
                  className={classNames(
                    matchPath(pathname, {
                      path: getRoute('/buildings/:buildingId/pieces'),
                    }) !== null && 'is-active',
                  )}
                >
                  <Link
                    to={composeRoute(
                      '/buildings/:buildingId/pieces',
                      localRouteParams,
                    )}
                  >
                    Разделы
                  </Link>
                </li>
              )}

              <li
                className={classNames(
                  matchPath(pathname, {
                    path: getRoute('/buildings/:buildingId/information'),
                  }) !== null && 'is-active',
                )}
              >
                <Link
                  to={composeRoute(
                    '/buildings/:buildingId/information',
                    localRouteParams,
                  )}
                >
                  Информация
                </Link>
              </li>

              {!isNew && (
                <li
                  className={classNames(
                    matchPath(pathname, {
                      path: getRoute('/buildings/:buildingId/orders'),
                    }) !== null && 'is-active',
                  )}
                >
                  <Link
                    to={composeRoute(
                      '/buildings/:buildingId/orders',
                      localRouteParams,
                    )}
                  >
                    Приказы
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <Switch>
            <Redirect
              from={composeRoute('/buildings/:buildingId', {
                buildingId: Number(buildingId),
              })}
              to={
                isNew
                  ? composeRoute(
                      '/buildings/:buildingId/information',
                      localRouteParams,
                    )
                  : composeRoute(
                      '/buildings/:buildingId/pieces',
                      localRouteParams,
                    )
              }
              exact
              strict
            />
            <Route path="/buildings/:buildingId/information">
              <div className="box">
                {data && (
                  <InformationForm
                    defaultValues={toBuildingFormData(data)}
                    onSubmit={request}
                    onSuccess={handleFormSuccess}
                  ></InformationForm>
                )}
              </div>
            </Route>
            <Route path="/buildings/:buildingId/orders">
              <BuildingOrders id={buildingId} />
            </Route>
            <Route path="/buildings/:buildingId/pieces">
              <BuildingPieces {...localRouteParams} />
            </Route>
          </Switch>
        </div>
      </section>
    </>
  );
};
