import { useStoredBoolean } from '../../utils/useStoredBoolean';

export const useHelp = (key: string) => {
  const [isVisible, setIsVisible] = useStoredBoolean(`${key}-help`);
  const toggle = () => setIsVisible((prevValue) => !prevValue);
  const close = () => setIsVisible(false);

  return {
    isVisible,
    close,
    toggle,
  };
};
