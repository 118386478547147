import React, { createContext, useContext } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '../../../api';
import { toDateInputValue } from '../../../utils/toDatetimeInputValue';
import { FormProps } from '../../../utils/formProps';
import { download } from '../../../utils/download';
import { attrs, useTable } from '../../../utils/useTable';
import { useYupResolver } from '../../../utils/useYupResolver';
import {
  useDefaultHandler,
  useDefaultSuccessHandler,
} from '../../../api/useDefaultApiHandler';
import {
  LabData,
  LabFormData,
  labFormSchema,
  toLabDTO,
  toLabFormData,
} from './workLabs.misc';
import styles from '../WorkTable.sass';
import { Prompt } from 'react-router-dom';

type ContextType = {
  workId: number;
};

const Context = createContext<ContextType>({ workId: -1 });

const {
  createLab,
  readLabs,
  updateLab,
  readLabPdf,
  uploadLabPdf,
  deleteLabPdf,
  deleteLab,
} = api;

const defaultFormValues = {
  id: '-1',
  index: '',
  date: toDateInputValue(new Date(Date.now())),
  name: '',
  pdf: undefined,
};

const Editable = ({
  form: { onSubmit, defaultValues },
  pdfName,
}: {
  form: FormProps<LabFormData>;
  pdfName: string | null;
}) => {
  const { register, handleSubmit, watch, setValue, formState } =
    useForm<LabFormData>({
      defaultValues,
      resolver: useYupResolver(labFormSchema),
    });
  const { isDirty, errors } = formState;

  const { mutate } = useMutation(
    (data: LabFormData) => {
      return onSubmit(data);
    },
    {
      onSuccess: useDefaultSuccessHandler(
        'Лабораторная работа успешно сохранена',
      ),
    },
  );

  const submit = (data: LabFormData) => {
    mutate(data);
  };

  const canBeDeleted = pdfName || (watch('pdf')?.length ?? 0) > 0;
  const pdfLabel =
    watch('pdf') === null ? (
      pdfName ? (
        <s>{pdfName}</s>
      ) : (
        '...'
      )
    ) : (
      <span>{watch('pdf')?.item(0)?.name || pdfName || '...'}</span>
    );
  const clearFile = pdfName
    ? () => setValue('pdf', null)
    : () => setValue('pdf', undefined);

  return (
    <>
      <Prompt
        when={isDirty}
        message="В одной или нескольких строках есть несохраненные данные. Вы точно хотите уйти?"
      />
      <form
        className={classNames(styles.item, 'is-revealing')}
        onSubmit={handleSubmit(submit)}
        {...attrs.idAttribute(defaultValues?.id as string)}
      >
        <div className={styles.cell}>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Протокол испытаний"
                {...register('name')}
              />
            </div>
            {!!errors.name && (
              <p className="help is-danger">{errors.name.message}</p>
            )}
          </div>
        </div>

        <div className={styles.cell}>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="1212"
                {...register('index')}
              />
            </div>
            {!!errors.index && (
              <p className="help is-danger">{errors.index.message}</p>
            )}
          </div>
        </div>

        <div className={styles.cell}>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="date"
                placeholder="123456"
                {...register('date')}
              />
            </div>
            {!!errors.date && (
              <p className="help is-danger">{errors.date.message}</p>
            )}
          </div>
        </div>
        <div className={styles.cell}>
          <div className="file has-name">
            <label className="file-label">
              <input
                className="file-input"
                accept=".pdf"
                type="file"
                {...register('pdf')}
              />
              <span className="file-cta">
                <span className="file-icon mr-0">
                  <i className="fas fa-file-pdf"></i>
                </span>
              </span>
              <span className="file-name">{pdfLabel}</span>
            </label>
            {canBeDeleted && (
              <button
                type="button"
                className="button is-white ml-1"
                onClick={clearFile}
              >
                <i className="fa fa-close"></i>
              </button>
            )}
          </div>
        </div>

        <div className={styles.cell}>
          <div className="field">
            <div className="control">
              <div className="buttons is-pulled-right">
                <button
                  type="button"
                  className="button is-danger is-outlined is-concealed"
                  {...attrs.undoAttr}
                >
                  <i className="fa fa-undo"></i>
                </button>
                <button
                  className="button is-success is-outlined is-concealed"
                  type="submit"
                >
                  <i className="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

const Readonly = ({ data }: { data: LabData }) => {
  const queryClient = useQueryClient();
  const { workId } = useContext(Context);
  const { id, pdf } = data;
  const hasPdf = !!pdf;
  const { data: pdfUrl } = useQuery(
    [readLabPdf.id, id],
    () => {
      return readLabPdf.request(id);
    },
    {
      enabled: hasPdf,
    },
  );
  const showSuccessNotification = useDefaultSuccessHandler(
    'Лабораторная работа успешно удалена',
  );
  const { mutate: suicide } = useMutation(
    () => {
      return deleteLab.request(data.id);
    },
    {
      onSuccess: () => {
        showSuccessNotification();
        queryClient.invalidateQueries([readLabs.id, workId]);
      },
    },
  );

  return (
    <div
      className={classNames(styles.item, styles.readonly, 'is-revealing')}
      {...attrs.idAttribute(String(id))}
    >
      <div className={styles.cell}>{data.name}</div>
      <div className={styles.cell}>{data.index}</div>
      <div className={styles.cell}>
        {new Date(data.date).toLocaleDateString()}
      </div>
      <div className={styles.cell}>
        {!!pdfUrl && (
          <a
            href={pdfUrl.url}
            target="_blank"
            type="button"
            className="button is-white"
          >
            {pdf}
          </a>
        )}
      </div>
      <div className={styles.cell}>
        <div className="field">
          <div className="control">
            <div className="buttons is-pulled-right">
              <button
                type="button"
                className="button is-info is-outlined is-concealed"
                {...attrs.editAttr}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                type="button"
                className="button is-danger is-outlined is-concealed"
                onClick={() => suicide()}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const upadtePdf = (id: number, pdfFileList: FileList | null | undefined) => {
  if (pdfFileList === null) {
    return deleteLabPdf.request(id);
  }

  const pdf = pdfFileList?.item(0);

  if (!pdf) {
    return;
  }

  const formData = new FormData();

  formData.append('file', pdf as File);

  return uploadLabPdf.request(id, { body: formData }).then(
    (response) => {
      return response;
    },
    (reason) => {
      return reason.json().then((json: any) => {
        throw json;
      });
    },
  );
};

export const WorkLabs = ({ workId }: { workId: number }) => {
  const queryClient = useQueryClient();
  const handleApiError = useDefaultHandler();

  const { data: items } = useQuery([readLabs.id, workId], () => {
    return readLabs.request(workId).then((labs) => {
      return labs.reverse();
    });
  });

  const { added, edited, deleteItem, handleClickCapture, stopEditing } =
    useTable();

  const handleCreate = (data: LabFormData) => {
    const body = toLabDTO(data);

    return createLab
      .request(body, workId)
      .then((response) => {
        const { id } = response as { id: number };
        const { pdf: pdfFileList } = data;

        return upadtePdf(id, pdfFileList);
      })
      .then(
        () => {
          added;
          deleteItem(data.id);
          queryClient.invalidateQueries([readLabs.id, workId]);
        },
        (reason) => {
          handleApiError(reason);
        },
      );
  };

  const handleUpdate = (data: LabFormData) => {
    const id = Number(data.id);
    const body = toLabDTO(data);

    return updateLab
      .request(body, id)
      .then(() => {
        const { pdf: pdfFileList } = data;

        return upadtePdf(id, pdfFileList);
      })
      .then(
        () => {
          stopEditing(data.id);
          queryClient.invalidateQueries([readLabs.id, workId]);
        },
        (reason) => {
          handleApiError(reason);
        },
      );
  };

  return (
    <Context.Provider value={{ workId }}>
      <div className="" onClickCapture={handleClickCapture}>
        <div className="level">
          <div className="level-left">
            <div className="level-item"></div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="buttons">
                <button className="button is-primary" {...attrs.addAttr}>
                  Новая лабораторная работа
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="box">
          <div
            className={styles.items}
            style={{ gridTemplateColumns: 'repeat(5, auto)' }}
          >
            <div className={styles.head}>
              <div className={styles.cell}>Наименование</div>
              <div className={styles.cell}>Номер</div>
              <div className={styles.cell}>Дата</div>
              <div className={styles.cell}>Документ</div>
              <div className={styles.cell}></div>
            </div>
            <div className={styles.body}>
              {added.map((lab) => (
                <Editable
                  key={lab}
                  form={{
                    defaultValues: { ...defaultFormValues, id: lab },
                    onSubmit: handleCreate,
                  }}
                  pdfName={''}
                />
              ))}
              {items?.map((lab) => {
                return edited[String(lab.id)] ? (
                  <Editable
                    key={lab.id}
                    form={{
                      defaultValues: toLabFormData(lab),
                      onSubmit: handleUpdate,
                    }}
                    pdfName={lab.pdf}
                  />
                ) : (
                  <Readonly key={lab.id} data={lab} />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Context.Provider>
  );
};
