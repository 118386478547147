import React from 'react';
import classNames from 'classnames';
import { Link, matchPath, useLocation } from 'react-router-dom';

type Tab = {
  to: string;
  path: string;
  content: React.ReactNode;
};

type Props = {
  tabs: Tab[];
};

export const NavTabs = ({ tabs }: Props) => {
  const { pathname } = useLocation();

  return (
    <div className="tabs">
      <ul>
        {tabs.map((tab) => {
          const { to, path, content } = tab;

          return (
            <li
              key={to}
              className={classNames(
                matchPath(pathname, {
                  path,
                }) !== null && 'is-active',
              )}
            >
              <Link to={to}>{content}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
