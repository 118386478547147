import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { api } from '../../api';
import { useWhoami } from '../Whoami/useWhoami';

const { keepAlive, replaceSession } = api;

type ConflictData = {
  userAgent: string;
  ip: string;
};

export const usePing = () => {
  const [conflict, setConflict] = useState<string | null>(null);
  const { logout } = useWhoami();
  const { mutate: relog } = useMutation(
    () => {
      return replaceSession.request();
    },
    {
      onSuccess: () => {
        setConflict(null);
      },
    },
  );

  useQuery(
    'keepAlive',
    () => {
      return keepAlive.request();
    },
    {
      onError: async (response: Response) => {
        const { status } = response;

        if (status === 401) {
          logout();
        }

        if (status === 409) {
          const body: { data: ConflictData; statusCode: number } =
            await response.json();

          setConflict(body.data.userAgent);
        }
      },
      refetchInterval: 15000,
      retry: 0,
    },
  );

  return {
    logout,
    conflict,
    relog,
  };
};
