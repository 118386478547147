import { useCallback } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import { composeRoute } from '../../../../routing/routing';
import { useNumericParams } from '../../../../utils/useNumericParams';
import {
  OrderBody,
  OrderData,
  OrderFormData,
  toOrderDTO,
  toOrderFormData,
} from './order.misc';
import { OrderFormModal } from './OrderForm';
import { api } from '../../../../api/';
import styles from './Order.sass';
import classNames from 'classnames';

const { createOrder } = api;

type Props = {
  orderGroupId: number;
  hasPersonIdField: boolean;
};

const getNewOrderData = (): OrderData => {
  return {
    alias: 'Приказ',
    companyId: -1,
    date: new Date(Date.now()).toISOString(),
    id: -1,
    index: '',
    name: '',
    position: '',
    personId: '',
  };
};

const defaultFormValues = toOrderFormData(getNewOrderData());

export const NewOrder = ({ orderGroupId, hasPersonIdField }: Props) => {
  const history = useHistory();
  const { buildingId } = useNumericParams<{ buildingId: string }>();

  const onSubmit = (formData: OrderFormData) => {
    const body = toOrderDTO(formData) as ExludeNullValues<OrderBody>;

    return createOrder.request(body, orderGroupId);
  };

  const goToAllOrders = useCallback(() => {
    history.push(
      composeRoute('/buildings/:buildingId/orders', {
        buildingId,
      }),
    );
  }, [history]);

  return (
    <>
      <Link
        to={composeRoute(
          '/buildings/:buildingId/orders/:orderGroupId/:orderId',
          {
            buildingId,
            orderGroupId,
            orderId: -1,
          },
        )}
        className="is-block is-h-100"
      >
        <div
          className={classNames(
            'p-4 is-flex is-justify-content-center is-align-items-center is-h-100',
            styles.new,
          )}
        >
          <i className="fas fa-plus"></i>
        </div>
      </Link>

      <Route
        path={composeRoute(
          '/buildings/:buildingId/orders/:orderGroupId/:orderId',
          {
            buildingId,
            orderGroupId,
            orderId: -1,
          },
        )}
      >
        <OrderFormModal
          defaultValues={defaultFormValues}
          hasPersonIdField={hasPersonIdField}
          onSubmit={onSubmit}
          orderGroupId={orderGroupId}
          onSuccess={goToAllOrders}
          onCancel={goToAllOrders}
          id={-1}
        />
      </Route>
    </>
  );
};
