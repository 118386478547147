import * as yup from 'yup';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { noop } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { useQueryClient, useMutation } from 'react-query';
import { api } from '../../../../api';

const { createOrderGroup, readPartnership } = api;

const colors = [
  '#e6194B',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#42d4f4',
  '#f032e6',
  '#bfef45',
  '#fabed4',
  '#469990',
  '#dcbeff',
  '#9A6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#a9a9a9',
  '#ffffff',
  '#000000',
];

const orderGroupFormSchema = yup.object({
  color: yup.string().required(),
  label: yup.string().required(),
});

type OrderGroupFormData = yup.InferType<typeof orderGroupFormSchema>;
type Props = {
  partnershipId: number;
  colorsInUse: Set<string>;
};

const CreateOrderGroupModal = ({
  colorsInUse,
  onClickOutside = noop,
  partnershipId,
}: Props & {
  onClickOutside: () => void;
}) => {
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm<OrderGroupFormData>({
    defaultValues: {
      color: colors.find((color) => {
        return !colorsInUse.has(color);
      }),
      label: '',
    },
  });

  const { mutate } = useMutation(
    (data: OrderGroupFormData) => {
      const { color, label } = data;

      return createOrderGroup.request({ color, label }, partnershipId);
    },
    {
      onSuccess: () => {
        // TODO: absurd
        onClickOutside();
        queryClient.invalidateQueries([readPartnership.id, partnershipId]);
      },
    },
  );

  const submit = (data: OrderGroupFormData) => {
    return mutate(data);
  };

  const onSubmit = handleSubmit(submit);

  return ReactDOM.createPortal(
    <div className="modal is-active">
      <div className="modal-background" onClick={onClickOutside}></div>
      <div className="modal-content">
        <div className="box">
          <form onSubmit={onSubmit}>
            <div className="field">
              <label className="label">Ярлык</label>
              <div className="control">
                <input type="text" className="input" {...register('label')} />
              </div>
            </div>
            <div className="field is-grouped is-grouped-right">
              <div className="control">
                <button className="button" type="submit">
                  Создать
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>,
    document.getElementById('modal') as HTMLDivElement,
  );
};

export const CreateOrderGroup = (props: Props) => {
  const [shouldShowModal, setModalVisibility] = useState<boolean>(false);
  const showModal = () => setModalVisibility(true);
  const hideModal = () => setModalVisibility(false);

  return (
    <>
      <button className="button is-primary" onClick={showModal}>
        <i className="fas fa-plus"></i>
        <span className="ml-2">Новая группа приказов</span>
      </button>
      {shouldShowModal && (
        <CreateOrderGroupModal {...props} onClickOutside={hideModal} />
      )}
    </>
  );
};
