import * as yup from 'yup';
import { LabDTO, ReadLabDTO } from '../../../api/_out/api';
import { toDateInputValue } from '../../../utils/toDatetimeInputValue';

export type LabData = ReadLabDTO;
export type LabBody = LabDTO;

// TODO: reuse
const getRequiredMessage = (label: string) =>
  `Поле ${label} не может быть пустым`;

export const labFormSchema = yup.object({
  id: yup.string().required(),
  index: yup.string().min(0).required(getRequiredMessage('Номер')),
  name: yup
    .string()
    .required(getRequiredMessage('Наименование'))
    .min(1)
    .max(48),
  date: yup
    .string()
    .required(getRequiredMessage('Дата'))
    .matches(/(\d{4})-(\d{2})-(\d{2})/, {
      message: 'Неправильный формат даты',
    }),
  pdf: yup.mixed(),
});

export type LabFormData = yup.InferType<typeof labFormSchema>;

export const toLabFormData = (data: LabData): LabFormData => {
  const { index, name, date, id } = data;

  return {
    id: String(id),
    index,
    name,
    date: toDateInputValue(new Date(date)),
    pdf: undefined,
  };
};

export const toLabDTO = (data: LabFormData): LabDTO => {
  const { index, name, date } = data;

  return {
    index,
    name,
    date: new Date(date).toISOString(),
  };
};
