import * as yup from 'yup';
import {
  CreateOrderDTO,
  OrderDTO,
  ReadOrderDTO,
} from '../../../../api/_out/api';
import { toDateInputValue } from '../../../../utils/toDatetimeInputValue';

type NullableFields = 'personId';

export type OrderData = WithNulls<ReadOrderDTO, NullableFields>;
export type OrderBody = WithNulls<OrderDTO, NullableFields>;
export type OrderCreateBody = WithNulls<CreateOrderDTO, NullableFields>;
export type OrderRelation = string;

const getRequiredMessage = (label: string) =>
  `Поле "${label}" не может быть пустым`;

const baseShape = {
  alias: yup.string(),
  companyId: yup.string().required(getRequiredMessage('Организация')),
  index: yup.string().required(getRequiredMessage('Номер')),
  date: yup
    .string()
    .required(getRequiredMessage('Дата'))
    .matches(/\d{4}-\d{2}-\d{2}/, { message: 'Дата в формате дд.мм.гггг' }),
  name: yup.string().required(getRequiredMessage('Фамилия И. О.')),
  personId: yup.string().required(getRequiredMessage('Номер в реестре')),
  position: yup.string().required(getRequiredMessage('Должность')),
};

export const noPersonIdFormSchema = yup.object(baseShape);

export const orderFormSchema = yup.object({
  ...baseShape,
  personId: yup.string().required(getRequiredMessage('Номер в реестре')),
});

export type OrderFormData = ExludeNullValues<
  yup.InferType<typeof orderFormSchema>
>;

export const toOrderFormData = (order: OrderData): OrderFormData => {
  const { index, companyId, date, name, personId, position, alias } = order;

  return {
    alias,
    companyId: companyId === -1 ? '' : String(companyId),
    index,
    date: toDateInputValue(new Date(date)),
    name,
    personId: personId === null ? '' : personId,
    position,
  };
};

export const toOrderDTO = (data: OrderFormData): OrderBody => {
  const { index, companyId, date, name, personId, position, alias } = data;

  return {
    alias: alias || 'Приказ',
    companyId: Number(companyId),
    index,
    date: new Date(date).toISOString(),
    name,
    personId: personId === '' ? null : personId,
    position,
  };
};
